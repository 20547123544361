import React, { useEffect, useState } from 'react'
import './MatchWithBot.css'
import { NavLink } from 'react-bootstrap'
import player1 from '../../Assets/Screen Icons/-xhdpi/profile.png'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useNavigate } from 'react-router-dom';
import HomeBtn from '../../Assets/Screen Icons/Screen Icons/Home.png'
import NextBtn from '../../Assets/Screen Icons/Screen Icons/Next box.png'

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

function MatchWithBot() {
    const navigate = useNavigate()

    // Abort Popup
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Result Popup
    const [openResult, setOpenResult] = React.useState(false);
    const handleOpenResult = () => setOpenResult(true);
    const handleCloseResult = () => setOpenResult(false);

    // Count Down
    const [timeLeft, setTimeLeft] = useState(60); // 5 minutes in seconds

    useEffect(() => {
        if (timeLeft === 0) {
            setOpenResult(true)  
            return;
        }

        const timer = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearTimeout(timer);
    }, [timeLeft]);

    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;

    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');


    return (
        <>
            <div className='free-match-with-bot'>
                <div className='top-bar'>
                    <h2>Range: 0 to 100</h2>
                    <NavLink className="play-sound" to="#" onClick={(e) => handleOpen(e)} >Abort</NavLink>
                </div>
                <div className='game-block'>
                    <div className='player player-top'>
                        <img src={player1} alt="" />
                        <h2>Bot</h2>
                    </div>
                    <div className='timer'>
                        <div className='countdown'>
                            {formattedMinutes}:{formattedSeconds}
                        </div>
                    </div>
                    <div className='player player-bottom'>
                        <div className='yout-guess'>
                            <input type="number" name="snumber" id="" placeholder='Bet Amount' />
                            <div className='lets-play-free'>
                                <button className='letsplay-freegm-btn-blnssht'>Ok</button>
                            </div>
                        </div>
                        <h2>RaC4q6n</h2>
                        <img src={player1} alt="" />
                    </div>
                </div>
                <div className='bottom-bar'>
                    <h2>Attempts: 0</h2>
                </div>
            </div>

            {/* Abort Popup */}
            <div className="result popup">
                <Modal open={openResult} onClose={handleCloseResult} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
                    <Box className="result data-popup" sx={style}>
                        <div className='playes'>
                            <div className='player winner'>
                                <img src={player1} alt="" />
                                <h2>RaC4q6n</h2>
                            </div>
                            <div className='vs-text'>VS</div>
                            <div className='player loser'>
                                <img src={player1} alt="" />
                                <h2>Bot</h2>
                            </div>
                        </div>
                        <div className='game-result'>
                            <div>
                                <p>24</p>
                                <p>10</p>
                                <p>05:00</p>
                            </div>
                            <div>
                                <p>Secret number</p>
                                <p>Attempts</p>
                                <p>Duration</p>
                            </div>
                            <div>
                                <p>87</p>
                                <p>27</p>
                                <p>04:23</p>
                            </div>
                        </div>
                        <div className='game-result check-balance'>
                            <div className='balance before'>
                                <h2>XOF 290</h2>
                                <p>Balance Before</p>
                            </div>
                            <div className='sign'>
                                <p>-</p>
                            </div>
                            <div className='balance win-loose'>
                                <h2>XOF 110</h2>
                                <p>Win/Loose</p>
                            </div>
                            <div className='sign'>
                                <p>=</p>
                            </div>
                            <div className='balance after'>
                                <h2>XOF 180.00</h2>
                                <p>New Balance</p>
                            </div>
                        </div>
                        <div className='actin-btns'>
                            <div className='btn-block'>
                                <NavLink onClick={() => navigate("/")} className='play-sound btn'><img src={HomeBtn} alt="" /></NavLink>
                                <p>Home</p>
                            </div>
                            <div className='btn-block'>
                                <button className='btn' onClick={() => navigate("/free-match")}><img src={NextBtn} alt="" /></button>
                                <p>Replay</p>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>

            {/* Abort Popup */}
            <div className="abort popup">
                <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
                    <Box className="abort data-popup" sx={style}>
                        <div className='free-match-banner-section free-match-bg-avb-desh'>
                            <h2>Exit</h2>
                        </div>
                        <Typography className="model-text" id="modal-modal-description" sx={{ mt: 2 }} >
                            <h2> Are You Sure Want to Abort?</h2>
                        </Typography>
                        <Typography className="model-button">
                            <button className='letsplay-freegm-btn-blnssht green' onClick={handleClose} >Cancle</button>
                            <button className='letsplay-freegm-btn-blnssht red' onClick={() => navigate('/')} > Abort</button>
                        </Typography>
                    </Box>
                </Modal>
            </div>
        </>
    )
}

export default MatchWithBot