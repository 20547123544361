import React, { useCallback, useEffect, useState } from 'react'
import './Login.css'
import Logo from '../../Assets/Screen Icons/logo.png'
import { Button } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../Action/UserActions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import Musicon from '../../Assets/Screen Icons/music-onn.png';
import MusicOf from '../../Assets/Screen Icons/music-off.png';
import loader from '../../Assets/Screen Icons/loading.gif';
import { v4 as uuidv4 } from 'uuid';
import Footer from '../../Components/Footer/Footer';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import axios from 'axios';
import { getGoogleLogin } from '../../API';
import { validateRequiredFields } from '../../Helper/Validation';
import FacebookLogin from 'react-facebook-login';
import { useGameSocket } from '../../Helper/useGameSocket';

const toastStyle = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
};

function Login({ handleMusicToggle, isPlaying, handleSoundToggle, isSoundEnabled }) {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userLoginAction } = bindActionCreators(actions, dispatch);
    const { changeCount, reinitializeSocket } = useGameSocket();

    const [loginData, setLoginData] = useState({
        login_type: 1,
        email: '',
        password: '',
        device_id: ''
    });
    const [showerr, setShowerr] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLoginData({
            ...loginData,
            [name]: value,
        });
    };

    useEffect(() => {
        if (loginData.device_id === "") {
            setLoginData({
                ...loginData,
                device_id: uuidv4()
            });
        }
    }, [loginData.device_id]);

    // Validations
    const [errors, setErrors] = useState({});
    const [showErrors, setShowErrors] = useState(false);

    useEffect(() => {
        const allErrors = validateRequiredFields(loginData, ["email", "password"], t)
        setErrors(allErrors)
    }, [loginData])

    const handleLogin = async (e) => {
        e.preventDefault();
        setShowErrors(true)
        if (Object.keys(errors).length === 0) {
            setIsLoading(true)
            try {
                const response = await userLoginAction(loginData);
                localStorage.setItem("UserToken", response.token)
                toast.success(response.message, toastStyle);
                setIsLoading(false)
                changeCount();
                reinitializeSocket();
                localStorage.setItem('deviceId', loginData.device_id)
                setTimeout(() => {
                    navigate("/home")
                    // window.location.href = '/home';
                    setShowErrors(false)
                }, 100)
            } catch (err) {
                toast.error(err?.response?.data?.message, toastStyle);
                setIsLoading(false)
                setShowerr(err.response?.data)
            }
        }
    };

    // Login With Faceook
    async function FacebookAuthButtonClicked() {
        const user = await FacebookAuth();
    }



    return (
        <div className='login'>
            <div className='main'>
                <div className='music-btn'>
                    <div className='element-music-btns'>
                        <img src={!isPlaying ? MusicOf : Musicon} alt="" onClick={handleMusicToggle} />
                    </div>
                </div>
                <div className='logo'>
                    <img src={Logo} alt="" />
                </div>
                <div className='login-block'>
                    <h2>{t('log in')}</h2>
                    <form className="login-form">
                        <div className='From-groups'>
                            <label htmlFor="Name">{t('userid')}</label>
                            <div>
                                <input type="text" name="email" onChange={(e) => handleChange(e)} placeholder={t('email/userid')} className='From-inputs' />
                                {showerr ? <p className='error-msg'>{showerr.email?.message}</p> : ""}
                                {showErrors ? <p className='error-msg'>{errors?.email?.message}</p> : ""}
                            </div>
                        </div>

                        <div className='From-groups'>
                            <label htmlFor="Email">{t('password')}</label>
                            <div>
                                <input type="password" name="password" placeholder={t('enter password')} onChange={(e) => handleChange(e)} className='From-inputs' />
                                {showErrors ? <p className='error-msg'>{errors?.password?.message}</p> : ""}
                            </div>
                        </div>

                        <div className='Form-submit-btn'>
                            <Button type='submit' className='update-pfl-btn' onClick={(e) => handleLogin(e)} disabled={isLoading}>{t('LOGIN')}</Button>
                        </div>
                        <br />

                        <div className='login-with'>

                            {/* <Button onClick={FacebookAuthButtonClicked}>Login With FaceBook</Button> */}


                            <GoogleOAuthProvider clientId="54201937071-sn67ng0fvra41t6upn41pnkp06bd6k5q.apps.googleusercontent.com">
                                <GoogleLogin
                                    onSuccess={(credentialResponse) => {
                                        axios.post(getGoogleLogin, { sSocialToken: credentialResponse.credential, type: "L", device: loginData.device_id })
                                            .then((response) => {
                                                localStorage.setItem("UserToken", response.data.token);
                                                toast.success(response.message, toastStyle);
                                                setIsLoading(false);
                                                navigate("/home");
                                                localStorage.setItem('deviceId', loginData.device_id);
                                            })
                                            .catch((err) => {
                                                toast.warn(err?.response?.data?.message, toastStyle);
                                            });
                                    }}
                                    onError={() => {
                                        toast.warn('Login Failed', toastStyle);
                                    }}
                                />
                            </GoogleOAuthProvider>
                        </div>

                        <div className='forgot-pass'>
                            <NavLink className="play-sound" to="/forgot-password">{t('forgot password ?')}</NavLink>
                        </div>

                        <div className='forgot-pass'>
                            <p>{t("Don't have an account yet ?")} <NavLink className="play-sound" to="/signup">{t('sign up')}</NavLink></p>
                        </div>
                    </form>
                </div>
                <div>
                    <Footer />
                </div>
            </div>
            {
                isLoading ?
                    <div className='api-loader'>
                        <img src={loader} alt="loader" />
                    </div> : ""
            }
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export default Login