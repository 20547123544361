import React from 'react'
import './Footer.css';
import APAYMLogo from '../../Assets/Screen Icons/APAYM.png';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';

function Footer() {
  const { t } = useTranslation();
  return (
    <div className='footer'>
      <div className='bottom-section more-page'>
        <div className='bottom-btn-elements'>
          <NavLink className="play-sound" to="/more" >
            <h4>{t('Useful Links')}</h4>
          </NavLink>
        </div>
        <div className='bottom-btn-elements'>
          <Link className="play-sound" to="https://pay.apaym.com/chenggong" target='_blank' >
            <h4>{t('Donate')} <img src={APAYMLogo} alt="" /></h4>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Footer