// Login Type
export const LOGINTYPE = "LOGINTYPE";

// Token Type
export const SET_TOKEN = "SET_TOKEN";

// Get User Details Type
export const USER_DETAILS = "USER_DETAILS";

// Get Bat Details
export const BET_DETAILS = "BET_DETAILS";

// Get Wallet History
export const WALLET_HISTORY = "WALLET_HISTORY";

// Get Current Game
export const CURRENT_GAME = "CURRENT_GAME";

// Get Socket Data
export const UPDATE_SOCKET_DATA = "UPDATE_SOCKET_DATA";

// Set Socket Data
export const SET_SOCKET = "SET_SOCKET";