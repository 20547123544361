import i18n from 'i18next';
import { useEffect } from 'react';
import { initReactI18next } from 'react-i18next';

export const initializeI18n = () => {
  const savedLang = localStorage.getItem('selectedLang') || 'fr';

  i18n
    .use(initReactI18next)
    .init({
      resources: {
        en: { translation: require('./locales/en/translation.json') },
        zh: { translation: require('./locales/zh/translation.json') },
        fr: { translation: require('./locales/fr/translation.json') },
        ru: { translation: require('./locales/ru/translation.json') },
      },
      lng: savedLang,
      fallbackLng: savedLang,
      interpolation: { escapeValue: false },
    });
}

initializeI18n();

export default i18n;