import { useTranslation } from "react-i18next";

export const batAmountValidation = (amount, min, max) => {
    const { t } = useTranslation();

    const amountValue = parseInt(amount, 10);
    let error = ""

    if (isNaN(amountValue)) {
        error = `${t("Amount must be a number")}`;
    } else if (amountValue < min) {
        error = `${t("Amount must be at least")} ${min}`;
    } else if (amountValue >= max) {
        error = `${t("Amount must be less than")} ${max}`;
    } else {
        error = "";
    }

    return error
};

export const handleSecreatNumberValidation = (number, range) => {
    const { t } = useTranslation();
    const [min, max] = range.split(',');
    const inputNumber = Number(number);

    if (isNaN(inputNumber)) {
        return t("Please enter a valid number");
    }

    if (inputNumber < Number(min) || inputNumber > Number(max)) {
        return t("Please enter a number between") + ` ${min} ` + t("and") + ` ${max}`;
    }

    return "";
};

export const gameDataValidation = (data, balance, t) => {
    const amountValue = parseInt(data.amount);

    const minAmount = 100;
    const maxAmount = balance;

    if (isNaN(amountValue)) {
        return t("Amount must be a number");
    }

    if (amountValue < minAmount) {
        return t("Amount must be at least") + ` ${minAmount}`;
    }

    if (amountValue >= maxAmount) {
        return t("Amount must be less than") + ` ${maxAmount}`;
    }

    if (!data.number_range || data.number_range === "") {
        return t("Please Select Secret Number Range");
    }

    if (!data.time || data.time === "") {
        return t("Please Choose The Duration");
    }

    const [min, max] = data.number_range.split(',');
    const inputNumber = Number(data.second_number == undefined || data.second_number == "" ? data.first_number : data.second_number);

    if (inputNumber === "") {
        return t("Please enter Your Secret Number");
    }

    if (isNaN(inputNumber)) {
        return t("Please enter a valid number");
    }

    if (inputNumber < Number(min) || inputNumber > Number(max)) {
        return t("Please enter a number between") + ` ${min} ` + t("and") + ` ${max}`;
    }

    return "";
};

export const gameDataValidationCounter = (data, balance, t) => {
    const amountValue = parseInt(data.amount);

    const minAmount = 100;
    const maxAmount = balance;

    if (isNaN(amountValue)) {
        return t("Amount must be a number");
    }

    if (amountValue < minAmount) {
        return t("Amount must be at least") + ` ${minAmount}`;
    }

    if (amountValue >= maxAmount) {
        return t("Amount must be less than") + ` ${maxAmount}`;
    }

    if (!data.number_range || data.number_range === "") {
        return t("Please Select Secret Number Range");
    }

    if (!data.time || data.time === "") {
        return t("Please Choose The Duration");
    }

    const [min, max] = data.number_range.split(',');
    const inputNumber = Number(data.number);

    if (inputNumber === "") {
        return t("Please enter Your Secret Number");
    }

    if (isNaN(inputNumber)) {
        return t("Please enter a valid number");
    }

    if (inputNumber < Number(min) || inputNumber > Number(max)) {
        return t("Please enter a number between") + ` ${min} ` + t("and") + ` ${max}`;
    }

    return "";
};


export const handleAmountValidation = (amount, t, maxAmount) => {
    const inputAmount = Number(amount);
    const max = Number(maxAmount);

    if (isNaN(inputAmount)) {
        return `${t("Please enter a valid number")}`;
    }

    if (inputAmount <= 0) {
        return `${t("Please enter a positive amount")}`;
    }

    if (inputAmount > max) {
        return `${t("Please enter an amount less than or equal to")} ${max}`;
    }

    return "";
};

export const generateTransactionId = () => {
    const timestamp = Date.now();
    const random = Math.floor(Math.random() * 10000);
    return `${timestamp}-${random}`;
};

export const validateRequiredFields = (data, requiredFields, t) => {
    const validationErrors = {};

    requiredFields.forEach((field) => {
        if (!data[field]) {
            validationErrors[field] = {
                message: `${t(`${field.replace("_", " ")}`)} ${t("field is mandatory.")}`,
                rule: "required",
            };
        } else if (field === "amount") {
            const amountValidationResult = handleAmountValidation(data[field], t);
            if (amountValidationResult !== "valid") {
                validationErrors[field] = {
                    message: amountValidationResult,
                    rule: "amount",
                };
            }

        }
    });

    return validationErrors;
};

export const getInvoiceTypeDescription = (invoice_type) => {
    switch (invoice_type) {
        case 1:
            return "Bonus";
        case 2:
            return "Deposit";
        case 3:
            return "Win";
        case 4:
            return "Lose";
        case 5:
            return "Withdraw";
        default:
            return "";
    }
}


export const handleStopFractionalNumber = (e) => {
    const charCode = e.which || e.keyCode;

    if (charCode < 48 || charCode > 57) {
        e.preventDefault();
    }
};


export function validateFractionalNumber(input, maxDecimalPlaces = 2) {
    const parts = input.split('.');
    if (parts.length > 1) {
        const integerPart = parts[0];
        let decimalPart = parts[1] || '';
        decimalPart = decimalPart.slice(0, maxDecimalPlaces);

        return `${integerPart}.${decimalPart}`;
    }

    return input;
}