import React from 'react'
import './Help.css'
import Goback from '../../Assets/Screen Icons/Screen Icons/Back arrow.png'
import ReactPlayer from 'react-player';
import { NavLink } from 'react-router-dom';
import VIDEO_PATH from '../../Assets/GameVideo.mp4'
import { useTranslation } from 'react-i18next';

function Help() {
  const { t } = useTranslation();
  return (
    <div className='Help-screen'>
      <div className='help-element-row'>
        <div className='help-element-banner'>
          <div className='go-back-to-main-screen'>
            <NavLink className="play-sound" to="/home">
              <img src={Goback} alt="" />
            </NavLink>
          </div>
          <div className='banner-title'>
            <h1>{t("help")}</h1>
          </div>
        </div>
        <div className='help-main-body'>
          <div className='auto-ply-video'>
            <div className='video-container-rct'>
              {/* <ReactPlayer url={VIDEO_PATH} controls={false} loop={true} autoplay={true} playing={true} light={true} width="100%" height="300px" /> */}
              <video src={VIDEO_PATH} autoPlay loop style={{ width: "100%" }}></video>
            </div>

          </div>
          <div className='textitul-cnt'>
            <h2>{t('game title')}</h2>
            <p>{t('Help_Description')}</p>
          </div>
        </div>
        <div className='bottom-section help-footer-elements'>
          <div className="match-mking-btn gm-dif-modes">
            <NavLink className="play-sound" to="/game-rules">
              <button className='help-match-md'>{t("Game Rules")}</button>
            </NavLink>
          </div>
          <div className="gm-dif-modes">
          <NavLink className="play-sound" to="/game-modes">
            <button className='help-match-md'>{t("Game Modes")}</button>
          </NavLink>
          </div>
          <div className="gm-dif-modes">
            <NavLink className="play-sound" to="/user-profile">
            <button className='help-match-md'>{t("User Profile")}</button>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Help