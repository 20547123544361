import React from 'react'
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom'

function Balance({restBalance}) {
    
    const { t } = useTranslation();
    return (
        <div className='Available-balance-wid-histy'>
            <div className='wrapper-row-clst'>
                <div className='titel-bnt-avb'>
                    <h1>{t('available balance')}</h1>
                </div>
                <div className="banner-bg-avb-desh">
                    <h2>XOF {restBalance}</h2>
                </div>
                <div className="btn-wd-hs-rows">
                    <div className='withdraw-my'>
                        <NavLink className="play-sound" to="/withdraw">
                            <button className='profile-btn-blnssht'>{t('withdraw')}</button>
                        </NavLink>
                    </div>
                    <div className='Deposit-my'>
                        <NavLink className="play-sound" to="/deposit-fund">
                            <button className='profile-btn-blnssht'>{t('deposite funds')}</button>
                        </NavLink>
                    </div>
                    <div className='History-my'>
                        <NavLink className="play-sound" to="/history/0">
                            <button className='profile-btn-blnssht'>{t("History")}</button>
                        </NavLink>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Balance