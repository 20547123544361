import { SET_SOCKET } from "../Type";

const initialState = {
    socket: null,
};

const webSocketReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SOCKET:
            return {
                ...state,
                socket: action.payload,
            };
        default:
            return state;
    }
};

export default webSocketReducer;
