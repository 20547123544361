import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import Goback from '../../Assets/Screen Icons/Screen Icons/Back arrow.png'
import './RandomMatch.css'
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Form from 'react-bootstrap/Form';
import * as GameActions from '../../Action/GameActions'
import * as UserActions from '../../Action/UserActions'
import Token from '../../Helper/Token';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { randomLiveMatch } from '../../API';
import { useGameSocket } from '../../Helper/useGameSocket';
import { useTranslation } from 'react-i18next';
import loader from '../../Assets/Screen Icons/loading.gif';
import PendingGame from '../../Components/PendingGame';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const toastStyle = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
};

function RandomMatch() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { socket, reinitializeSocket, rendomMatches } = useGameSocket();

    const [open, setOpen] = React.useState(false);
    const [validationError, setValidationError] = useState("");

    // Calling Game Actions
    const { AcceptDeclineGame } = bindActionCreators(GameActions, dispatch)
    const { getUserDetails } = bindActionCreators(UserActions, dispatch)
    const [acceptedData, setAcceptedData] = useState({
        flag: 1,
        number: "",
        amount: "",
        time: "",
        number_range: ""
    })
    const [isLoading, setIsLoading] = useState(true)

    // Get User Details
    const [userDetails, setUserDetails] = useState({});

    useEffect(() => {
        Token()
        const deviceId = {
            device_id: localStorage.getItem('deviceId')
        }
        getUserDetails(deviceId)
            .then((res) => {
                setUserDetails(res.data.result);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    localStorage.clear();
                    sessionStorage.clear();
                    navigate("/login");
                }
            });

    }, [])

    // Get Random Match List
    const [randomdata, setrandomdata] = useState([])
    useEffect(() => {
        // if (socket) {
        //     socket.on(randomLiveMatch, (data) => {
        //         setIsLoading(false)
        //         let games = [];
        //         data.result.map((ele) => {
        //             const [start, end] = ele.number_range.split(',');
        //             ele.numberRange = `${start} ${t("To")} ${end}`;

        //             if (ele?.first_player_id !== userDetails?._id) {
        //                 games.push(ele)
        //             }
        //         })
        //         setrandomdata(games)
        //     });

        //     return () => {
        //         socket.off(randomLiveMatch);
        //     };
        // }
        if (rendomMatches) {
            setrandomdata(rendomMatches)
            setIsLoading(false)
        }
    }, [rendomMatches]);


    // Check Error
    const handleNumberChange = (value, min, max) => {
        const inputNumber = Number(value);

        if (inputNumber >= min && inputNumber <= max) {
            setValidationError('');
        } else {
            setValidationError(`${t("Number must be between")} ${min} ${t("and")} ${max}`);
        }
    };

    const handlechange = (e) => {
        const { name, value } = e.target;
        setAcceptedData({
            ...acceptedData,
            [name]: value
        });
        const [min, max] = acceptedData.number_range.split(',');
        handleNumberChange(value, min, max)
    };

    // Accept Game Api
    const [gameId, setGameId] = useState("")
    const [curruntRange, setCurruntRange] = useState("")
    const handlePlayGame = (data) => {
        setOpen(true);
        setGameId(data._id)
        setAcceptedData({
            ...acceptedData,
            amount: data.amount,
            time: data.time,
            number_range: data.number_range,
        })
        setCurruntRange(data.numberRange)
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleAccept = (e) => {
        if (validationError === "") {
            Token();
            AcceptDeclineGame(acceptedData, gameId)
                .then((res) => {
                    toast.success(res.message, toastStyle);
                    setTimeout(() => {
                        navigate("/play");
                        setOpen(false);
                    }, 2000)
                    setError()
                })
                .catch((err) => {
                    toast.error(err.response?.data.message, toastStyle);
                });
        }
    }

    return (
        <div className='RandomMatch-screen'>
            <div className='Random-element-row'>
                <div className='help-element-banner'>
                    <div className='go-back-to-main-screen'>
                        <NavLink className="play-sound" to="/home">
                            <img src={Goback} alt="" />
                        </NavLink>
                    </div>
                    <div className='banner-title'>
                        <h1>{t('avail bets')}</h1>
                    </div>
                </div>

                <PendingGame />

                <div className='random-inside-middle-section'>
                    <div className='sec-first-btn-for-createbet'>
                        <NavLink to="/create-bet" id="create-betgame" className="play-sound random-btn-blnssht">{t('create be')}</NavLink>
                    </div>
                </div>

                <div className='notification-block available-bet-cont'>
                    {
                        isLoading ?
                            <div className='loader'>
                                <img src={loader} alt="loader" />
                            </div> : randomdata?.length === 0 ? <h1>{t("No Games Found")}</h1> : ""
                    }
                    {randomdata?.map(data => {
                        return (
                            <div className='notification-item x-available-bet-bd' key={data._id}>
                                <div className='available-bets-notification-body'>
                                    <h2><span>{t('name')} : </span> {data.creater_id.name}</h2>
                                    <h2><span>{t('betamount_pop')} </span>{data.amount}</h2>
                                    <h2><span>{t('time _pop')} </span>{data.time}</h2>
                                    <h2><span>{t('guess num range_ pop')} </span>{data.numberRange}</h2>
                                </div>
                                <NavLink className="play-sound" onClick={() => handlePlayGame(data)}>{t('play')}</NavLink>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="popupbodyel qavailable-bet-popup">
                <div className='abort popup '>
                    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" className='model-avaolable-bet-ok' aria-describedby="modal-modal-description" >
                        <Box className="abort data-popup accept-avilable-offer" sx={style}>

                            <div className='popup-body-height-define-bet'>
                                <div className='popup-title-available-bet'>
                                    <h1>{t('Choose a Number for Other Player in Range')} : {curruntRange}</h1>
                                </div>

                                <div className='popup-body input-filed'>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Control type="number" placeholder={t('Enter Number')} name='number' onChange={(e) => handlechange(e)} />
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='btn-green-submit-available-bet'>
                                <button className='letsplay-freegm-btn-blnssht green' onClick={(e) => handleAccept(e)}>{t('submit')}</button>
                            </div>
                            {validationError && <p className="Trow-error-stil-missing-fd text-danger">{validationError}</p>}
                        </Box>
                    </Modal>
                </div>
            </div>
            {/* {
                isLoading ?
                    <div className='api-loader'>
                        <img src={loader} alt="loader" />
                    </div> : ""
            } */}
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export default RandomMatch