import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import Goback from '../../Assets/Screen Icons/Screen Icons/Back arrow.png'
import './DirectMatch.css'
import * as searchPlayers from '../../Action/SearchAction'
import Token from '../../Helper/Token';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import PendingGame from '../../Components/PendingGame';

const toastStyle = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
};

function DirectMatch() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const { searchPlayer } = bindActionCreators(searchPlayers, dispatch)

    const [search, setSearch] = useState({ user_key: "" })
    const [searchResult, setSearchResult] = useState([])
    const handleChange = (e) => {
        const newValue = e.target.value.replace(/\s/g, '');
        setSearch({
            ...search,
            user_key: newValue
        })
    }

    const handleSubmit = (e) => {
        Token();
        if (search.user_key !== "") {
            searchPlayer(search)
                .then((res) => {
                    setSearchResult(res.result)
                })
                .catch((err) => {
                    toast.error(err.response?.data.message, toastStyle);
                })
        } else {
            toast.error(`${t('The user key field is mandatory.')}`, toastStyle)
        }
    }

    return (
        <div className='Direct-Match-screen'>
            <div className='Direct-Match-element-row'>

                <div className='help-element-banner'>
                    <div className='go-back-to-main-screen'>
                        <NavLink className="play-sound" to="/home">
                            <img src={Goback} alt="" />
                        </NavLink>
                    </div>
                    <div className='banner-title'>
                        <h1>{t('Direct Match')}</h1>
                    </div>
                </div>

                <div className='Direct-inside-middle-section'>
                    <div className='direct-find-player-id'>
                        <div className="From-groups plush-direct-match">
                            <input type="text" name="user_key" onChange={(e) => handleChange(e)} className="From-inputs" placeholder={t('searchplayerID')} />
                            <button type="search" className="Direct-match-btn-blnssht" onClick={(e) => handleSubmit(e)}>{t('search')}</button>
                        </div>
                    </div>
                </div>

                <PendingGame />
                <div className='notification-block direct-match'>
                    {
                        searchResult.map((item) => {
                            return (
                                <div className="notification-item x-available-bet-bd direct-match" key={item._id}>
                                    <div className="available-bets-notification-body direct-match">
                                        <h2><span>{item.name}</span></h2>
                                        <h2>{item.user_key}</h2>
                                    </div>
                                    <NavLink aria-current="page" className="play-sound active" to={`/invite/${item._id}`}  >{t('Invite')}</NavLink>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export default DirectMatch