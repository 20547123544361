import React, { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { ApiBaseURL, randomLiveMatch } from "../API";
import * as userActions from "../Action/UserActions";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import Token from "./Token";
import { useTranslation } from "react-i18next";

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [socket, setSocket] = useState(null);
    const [user, setUserDetails] = useState({});
    const [shouldReinitializeSocket, setShouldReinitializeSocket] = useState(1);

    const { getUserDetails } = bindActionCreators(userActions, dispatch);
    const [rendomMatches, setrandomMatches] = useState([])

    async function fetchUserDetails() {
        const deviceId = {
            device_id: localStorage.getItem('deviceId')
        };
        try {
            Token();
            const response = await getUserDetails(deviceId);
            setUserDetails(response.data.result);
        } catch (error) {
            console.error("Error fetching user details:", error);
        }
    }

    useEffect(() => {
        fetchUserDetails();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (user._id !== undefined) {
                const newSocket = io(ApiBaseURL, {
                    transports: ["websocket"],
                    wsEngine: 'ws',
                    query: { user_id: user._id }
                });

                setSocket(newSocket);
                newSocket.on(randomLiveMatch, (data) => {
                    let games = [];
                    data.result.map((ele) => {
                        const [start, end] = ele.number_range.split(',');
                        ele.numberRange = `${start} ${t("To")} ${end}`;

                        if (ele?.first_player_id !== user?._id) {
                            games.push(ele)
                        }
                    })
                    setrandomMatches(games)
                });

                return () => {
                    newSocket.off(randomLiveMatch);
                };
            }
        }, 100)
    }, [user, shouldReinitializeSocket]);

    // Function to reinitialize the socket
    const reinitializeSocket = () => {
        setShouldReinitializeSocket(prevValue => prevValue + 1);
    };

    // Pending game
    const [count, setCount] = useState(1);
    const changeCount = () => {
        fetchUserDetails();
        setCount((prev) => {
            return prev + 1;
        });
    };

    useEffect(() => {
        changeCount();
        reinitializeSocket();
    }, [])

    return (
        <SocketContext.Provider value={{ socket, reinitializeSocket, rendomMatches, count, changeCount }}>
            {children}
        </SocketContext.Provider>
    );
};

export const useGameSocket = () => {
    return useContext(SocketContext);
};