import { combineReducers } from "redux"
import { LOGINTYPE } from "../Type"

const initialState = {
    productDetails: {
        docs: [],
        totalDocs: 0,
        limit: 0,
        page: 0,
        totalPages: 0,
        pagingCounter: 0,
        hasPrevPage: false,
        hasNextPage: false,
        prevPage: null,
        nextPage: null
    }
}

const ProductDetailsReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case LOGINTYPE:
            return { ...state, productDetails: payload }
        default:
            return state;
    }
}

export default ProductDetailsReducer