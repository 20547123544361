import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../Action/WalletAction'
import * as gameActions from '../../Action/GameActions'
import Token from '../../Helper/Token';
import { useTranslation } from 'react-i18next';
import prevImg from '../../Assets/Screen Icons/Screen Icons/Next box 1.png';
import nextImg from '../../Assets/Screen Icons/Screen Icons/Next box.png';
import loader from '../../Assets/Screen Icons/loading.gif';
import { getInvoiceTypeDescription } from '../../Helper/Validation';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseBtn from '../../Assets/Screen Icons/Close.png'


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};


function Both() {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const { GetWalletHistory } = bindActionCreators(actions, dispatch);
    const { CurrentGameResult } = bindActionCreators(gameActions, dispatch);
    const [walletHistory, setWalletHistory] = useState([]);
    const [pageState, setPageState] = useState({
        page: 1,
        limit: 10,
        prevPage: "",
        nextPage: "",
        totalPages: ""
    });
    const [isLoading, setIsLoading] = useState(true)

    const { userdetails } = useSelector((state) => state.user);

    const handlePrev = (newPage) => {
        setIsLoading(true);
        if (pageState.page > 1) {
            setPageState((prevState) => ({
                ...prevState,
                page: newPage,
                prevPage: newPage - 1,
            }));
        }
    };

    const handleNext = (newPage) => {
        setIsLoading(true);
        if (pageState.page < pageState.totalPages) {
            setPageState((prevState) => ({
                ...prevState,
                page: newPage,
                prevPage: newPage + 1,
            }));
        }
    };

    useEffect(() => {
        Token()
        GetWalletHistory(pageState.page, pageState.limit, "")
            .then((res) => {
                setIsLoading(false);
                setPageState({
                    ...pageState,
                    totalPages: res.result.totalPages,
                    prevPage: res.result.prevPage,
                    nextPage: res.result.nextPage,
                });
                setWalletHistory(res.result.docs);
            })
    }, [pageState.page, pageState.limit])

    // Result Popup
    const [openResult, setOpenResult] = React.useState(false);
    const closeResult = () => {
        setOpenResult(false);
    }
    const [isAborted1, setIsAborted1] = useState(false);
    const [isAborted2, setIsAborted2] = useState(false);
    const [gameResult, setGameResult] = useState({})
    const [isResLoading, setIsResLoading] = useState(false)

    const getGameResult = (id) => {
        setIsResLoading(true);

        CurrentGameResult(id)
            .then((res) => {
                if (res?.result?.game_abort) {
                    if (res?.result?.winner === 1) {
                        setIsAborted2(true);
                    } else if (res?.result?.winner === 2) {
                        setIsAborted1(true);
                    } else {
                        setIsAborted1(false);
                        setIsAborted2(false);
                    }
                }

                setGameResult(res);

                setTimeout(() => {
                    setIsResLoading(false);
                    setOpenResult(true);
                }, 500);
            })
            .catch((err) => {
                setIsResLoading(false);
            });
    };
    
    return (
        <div className='History-body'>
            <div className='iside-history-row'>
                {
                    isLoading ? <img className='isLoading' src={loader} alt="" /> :
                        walletHistory?.map((item) => {
                            return (
                                item.game_details ?
                                    <div className='all-history-col-wrapper'>
                                        <div className='hs-col-mid1'>
                                            <p>{item.createdAt}</p>
                                        </div>
                                        <div className={item.invoice_type === 3 ? 'hs-col-mid-twise win' : item.invoice_type === 4 ? 'hs-col-mid-twise loss' : 'hs-col-mid-twise'}>
                                            <div className='hs-win-player-id'>
                                                <h3>{t(`${getInvoiceTypeDescription(item.invoice_type)}`)}</h3>
                                                <p>{userdetails?.result._id === item.game_details.second_player_data?._id ? item.game_details.first_player_data?.user_key : item.game_details.second_player_data?.user_key}</p>
                                            </div>
                                            <div className='hs-amout-x-player-cos-invite'>
                                                <div className='amnt-code-hs-pl'>
                                                    <h5>{item.amount}</h5>
                                                    <p>XOF</p>
                                                </div>
                                                <div className='hs-invite-btns'>
                                                    <NavLink className="play-sound" to={`/invite/${userdetails?.result._id === item.game_details.second_player_data?._id ? item.game_details.first_player_data?._id : item.game_details.second_player_data?._id}`}>
                                                        <button className='history-invite-buttons'>{t('Invite')}</button>
                                                    </NavLink>
                                                    <NavLink className="play-sound" onClick={() => getGameResult(item?.game_details?._id)}>
                                                        <button className='history-invite-buttons'>{t('Result')}</button>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <div className='all-history-col-wrapper funds'>
                                        <div className='hs-col-mid1'>
                                            <p>{item.createdAt}</p>
                                        </div>
                                        <div className="hs-col-mid-twise win">
                                            <div className='hs-win-player-id'>
                                                <h3>{t(`${getInvoiceTypeDescription(item.invoice_type)}`)}</h3>
                                                {
                                                    item.invoice_type === 5 ? (
                                                        <>
                                                            {item.accept_flag === 1 ? (
                                                                <p className='success'>{t("Processed")}</p>
                                                            ) : item.accept_flag === 2 ? (
                                                                <p className='error'>{t("Declined")}</p>
                                                            ) : item.accept_flag === 3 ? (
                                                                <p className='error'>{t("Canceled")}</p>
                                                            ) : <p className='error'>{t("Processing")}</p>}
                                                        </>
                                                    ) : null
                                                }
                                            </div>
                                            <div className='hs-amout-x-player-cos-invite'>
                                                <div className='amnt-code-hs-pl'>
                                                    <h5>{item.amount}</h5>
                                                    <p>XOF</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            )
                        })
                }
            </div>
            {
                pageState.totalPages > 1 ?
                    <div className='navigation-btn'>
                        <button className='history-invite-buttons' onClick={() => handlePrev(pageState.prevPage)} disabled={!pageState.prevPage}><img src={prevImg} alt="prev" /></button>
                        <button className='history-invite-buttons' onClick={() => handleNext(pageState.nextPage)} disabled={!pageState.nextPage}><img src={nextImg} alt="next" /></button>
                    </div> : ""
            }

            {
                isResLoading ?
                    <div className='api-loader'>
                        <img src={loader} alt="loader" />
                    </div> : ""
            }

            {/* Result Popup */}
            <div className="result popup">
                <Modal open={openResult} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
                    <Box className="result data-popup resulyPopup" sx={style}>
                        <div className='playes'>
                            <div className={
                                isAborted1 ? 'player aborted' :
                                    gameResult?.result?.winner === 1 ? 'player winner' :
                                        gameResult?.result?.winner === 2 ? 'player loser' :
                                            'player draw'
                            }
                                dataResult={
                                    isAborted1 ? `${t('aborted')}` :
                                        gameResult?.result?.winner === 1 ? `${t('winner')}` :
                                            gameResult?.result?.winner === 2 ? `${t('loser')}` :
                                                `${t('draw')}`
                                }>
                                <img src={gameResult?.result?.first_player_data?.profilePic} alt="" />
                                <h2>{gameResult?.result?.first_player_data?.name}</h2>
                                <h4>{gameResult?.result?.first_player_data?.user_key}</h4>
                            </div>
                            <div className='vs-text'>VS</div>
                            <div className={
                                isAborted2 ? 'player aborted' :
                                    gameResult?.result?.winner === 2 ? 'player winner' :
                                        gameResult?.result?.winner === 1 ? 'player loser' :
                                            'player draw'
                            }
                                dataResult={
                                    isAborted2 ? `${t('aborted')}` :
                                        gameResult?.result?.winner === 2 ? `${t('winner')}` :
                                            gameResult?.result?.winner === 1 ? `${t('loser')}` :
                                                `${t('draw')}`
                                }>
                                <img src={gameResult?.result?.second_player_data?.profilePic} alt="" />
                                <h2>{gameResult?.result?.second_player_data?.name}</h2>
                                <h4>{gameResult?.result?.second_player_data?.user_key}</h4>
                            </div>
                        </div>
                        <div className='game-result'>
                            <div>
                                <p>{gameResult?.result?.first_number}</p>
                                <p>{gameResult?.result?.first_player_attempt}</p>
                                <p>{gameResult?.result?.first_player_time}</p>
                            </div>
                            <div>
                                <p>{t('Secret Number')}</p>
                                <p>{t('Attempts')}</p>
                                <p>{t('Duration')}</p>
                            </div>
                            <div>
                                <p>{gameResult?.result?.second_number}</p>
                                <p>{gameResult?.result?.second_player_attempt}</p>
                                <p>{gameResult?.result?.second_player_time}</p>
                            </div>
                        </div>
                        <div className='game-result justifyCenter check-balance'>
                            <div className='balance win-loose'>
                                <h2>XOF {Number(gameResult?.win_lose_amount)}</h2>
                                <p>{gameResult?.last_amount > gameResult.updated_amount ? `${t("Lose")}` : `${t("Win")}`}</p>
                            </div>
                        </div>
                        <div className='actin-btns'>
                            <div className='btn-block'>
                                <button className='btn' onClick={() => closeResult()}><img src={CloseBtn} alt="" /></button>
                                <p>{t('Close')}</p>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>
        </div>
    )
}

export default Both