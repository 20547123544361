import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useGameSocket } from '../Helper/useGameSocket';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import * as UserAction from '../Action/UserActions'
import Token from '../Helper/Token';
import { listenReconnect, previousGame } from '../API';
import { useTranslation } from 'react-i18next';
import loader from '../Assets/Screen Icons/loading.gif';
import * as actions from '../Action/GameActions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastStyle = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
};

function PendingGame() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const { getUserDetails } = bindActionCreators(UserAction, dispatch);
    const { AcceptDeclineGame } = bindActionCreators(actions, dispatch);

    const { socket, count } = useGameSocket();
    const localDeviceId = localStorage.getItem('deviceId')
    const { userdetails } = useSelector((state) => state.user);
    const [pendingGame, setPendingGame] = useState()

    useEffect(() => {
        Token()
        const deviceId = {
            device_id: localDeviceId
        }
        getUserDetails(deviceId)
    }, [localDeviceId])

    useEffect(() => {
        if (socket) {
            socket.emit(listenReconnect, {
                user_id: userdetails?.result._id
            });

            const handlePreviousGame = (data) => {
                if (userdetails?.result._id === data?.data?.first_player_id) {
                    if (data.data?.play_status === 0 || data.data?.play_status === 1) {
                        setPendingGame(data.data);
                        localStorage.setItem('newGameData', JSON.stringify(data.data))
                    } else {
                        localStorage.removeItem('newGameData')
                    }
                } else if (userdetails?.result._id === data?.data?.second_player_id) {
                    if (data.data?.play_status === 1) {
                        setPendingGame(data.data);
                        localStorage.setItem('newGameData', JSON.stringify(data.data))
                    } else {
                        localStorage.removeItem('newGameData')
                    }
                }
            };
            socket.on(previousGame, handlePreviousGame);

            return () => {
                socket.off(previousGame, handlePreviousGame);
            };
        }
    }, [socket, userdetails, count]);


    // Loading 
    const [isLoading, setIsLoading] = useState(false)

    // Cancel Game
    const handleAbort = (data) => {
        const currentGame = JSON.parse(localStorage.getItem('newGameData'))
        const declineData = {
            flag: 2, // 1 = accept, 2 = decline, 3 = counter offer
            number: "",
            amount: currentGame.amount,
            time: currentGame.time,
            number_range: currentGame.number_range
        };

        Token();
        setIsLoading(true)
        AcceptDeclineGame(declineData, data)
            .then((res) => {
                toast.success(res.message, toastStyle);
                localStorage.removeItem('newGameData');
                setPendingGame('')
            })
            .catch((err) => {
                toast.error(t(err.response.data.message), toastStyle);
                localStorage.removeItem('newGameData');
                setPendingGame('')
            });
    };


    return (
        <>
            {
                pendingGame &&
                <>
                    {pendingGame?.play_status === 0 || pendingGame?.play_status === 1 ?
                        <div className='pendingGamepopup'>
                            <div className='popupBlock'>
                                <div className='popup-body-height-define-bet x-waiting-screen'>
                                    <div className='popup-title-available-bet x-waiting-screen'>
                                        <h1>{t("You have a pending game,")} <br /> {t("You want to Continue ?")}</h1>
                                    </div>
                                </div>

                                <div className='btn-green-submit-available-bet'>
                                    {
                                        pendingGame?.play_status === 0 &&
                                        <button className='letsplay-freegm-btn-blnssht green' onClick={() => handleAbort(pendingGame?._id)} disabled={isLoading}>{t("Cancel")}</button>
                                    }
                                    <button className='letsplay-freegm-btn-blnssht green' onClick={() => navigate(pendingGame?.play_status === 0 ? `/find-match/${pendingGame._id}` : `/play`)} >{t("Continue")}</button>
                                </div>
                            </div>
                        </div> : ""}
                </>
            }
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>
    )
}

export default PendingGame