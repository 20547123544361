import { UPDATE_SOCKET_DATA } from "../Type";

const initialState = {
    socketData: {}
};

const gameSocketReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case UPDATE_SOCKET_DATA:
            return {
                ...state,
                socketData: {
                    ...state.socketData,
                    [payload.socketName]: payload.data,
                },
            };
        default:
            return state;
    }
};


export default gameSocketReducer