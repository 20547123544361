import React, { useState, useEffect } from 'react'
import './GlobleCss/Globle.css'
import { GrDown } from 'react-icons/gr'
import { FaCheck } from 'react-icons/fa'


function TimeSelect({ handleChange }) {
    const data = [
        {
            id: 0, label: "05:00",
        },
        {
            id: 1, label: "10:00"
        },
        {
            id: 3, label: "15:00"
        },
        {
            id: 4, label: "20:00"
        },
        {
            id: 5, label: "25:00"
        },
        {
            id: 6, label: "30:00"
        }
    ];
    const [isOpen, setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(0);

    const toggleDropdown = () => setOpen(!isOpen);

    const handleItemClick = (id) => {
        selectedItem == id ? setSelectedItem(id) : setSelectedItem(id);
        setOpen(!isOpen)
    }

    useEffect(() => {
        const time = data.find((ele) => {
            return ele.id == selectedItem
        })
        setTimeout(() => {
            handleChange(time, "Time")
        },500)
    }, [selectedItem])

    return (
        <div className='dropdown'>
            <div className='dropdown-header' onClick={toggleDropdown}>
                {selectedItem !== null ? data.find(item => item.id == selectedItem).label : "Select Time"}
                <i className={`fa fa-chevron-right icon ${isOpen && "open"}`}></i><GrDown />
            </div>
            <div className={`dropdown-body ${isOpen && 'open'}`}>
                {data.map(item => (
                    <div className="dropdown-item" onClick={e => handleItemClick(e.target.id)} id={item.id} key={item.id}>
                        <span className={`dropdown-item-dot ${item.id == selectedItem && 'selected'}`}><FaCheck /></span>
                        <p>{item.label}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default TimeSelect