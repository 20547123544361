import axios from "axios";
import { UserLogin, UserSignup, editUserProfile, GetUserDetails, UserForgotPassword, UserResetPassword, UserCheckVariftEmail, UserResendVarificationMail, UserSendOtp, UserOtpVerification } from "../API";
import { SET_TOKEN, USER_DETAILS } from "../Type";

export const addNewUser = (userData) => async (dispatch) => {
  try {
    const adduserdetails = await axios.post(UserSignup, userData);
    if (adduserdetails) {
      dispatch({ type: SET_TOKEN, payload: adduserdetails.data.token });
    }
    return adduserdetails.data;
  } catch (error) {
    throw error;
  }
}

export const userLoginAction = (userData) => async (dispatch) => {
  try {
    const adduserdetails = await axios.post(UserLogin, userData);
    return adduserdetails.data;
  } catch (error) {
    throw error;
  }
}

export const EditUserProfile = (userData) => async (dispatch) => {
  try {
    const editprofile = await axios.put(editUserProfile, userData)
    return editprofile.data;
  } catch (error) {
    throw error;
  }
}

export const getUserDetails = (userData) => async (dispatch) => {
  try {
    const userprofileDetails = await axios.post(GetUserDetails, userData)
    dispatch({
      type: USER_DETAILS,
      payload: userprofileDetails.data
    });
    return userprofileDetails;
  } catch (error) {
    throw error;
  }
}

export const userForgotPasswordAction = (userData) => async (dispatch) => {
  try {
    const adduserdetails = await axios.post(UserForgotPassword, userData);
    return adduserdetails.data;
  } catch (error) {
    throw error;
  }
}

export const userSendOtpAction = (userData) => async (dispatch) => {
  try {
    const adduserdetails = await axios.post(UserSendOtp, userData);
    return adduserdetails.data;
  } catch (error) {
    throw error;
  }
}

export const userOtpVerificationAction = (userData) => async (dispatch) => {
  try {
    const adduserdetails = await axios.post(UserOtpVerification, userData);
    return adduserdetails.data;
  } catch (error) {
    throw error;
  }
}

export const userResetPasswordAction = (userData) => async (dispatch) => {
  try {
    const adduserdetails = await axios.put(UserResetPassword, userData);
    return adduserdetails.data;
  } catch (error) {
    throw error;
  }
}

export const userResendVariftEmailAction = (userData) => async (dispatch) => {
  try {
    const adduserdetails = await axios.get(UserResendVarificationMail, userData);
    return adduserdetails.data;
  } catch (error) {
    throw error;
  }
}

export const userCheckVariftEmailAction = (userData) => async (dispatch) => {
  try {
    const adduserdetails = await axios.post(UserCheckVariftEmail, userData);
    return adduserdetails.data;
  } catch (error) {
    throw error;
  }
}
