import './App.css';
import Help from './Appbar/Help/Help';
import Home from './Appbar/HomeScreen/Home';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import HomeLayout from './Layout/HomeLayout';
import LoginLayout from './Layout/LoginLayout';
import GameLayout from './Layout/GameLayout';
import FooterLayout from './Layout/FooterLayout';

import Setting from './Appbar/Setting/Setting';
import Profile from './Appbar/Profile/Profile';
import Freematch from './Appbar/FreeMatch/Freematch';
import RandomMatch from './Appbar/RandomMatch/RandomMatch';
import DirectMatch from './Appbar/DirectMatch/DirectMatch';
import GameRules from './Appbar/GameRules/GameRules';
import Login from './Appbar/Login/Login';
import Signup from './Appbar/Signup/Signup';
import FordotPassword from './Appbar/FordotPassword/FordotPassword';
import CreateBet from './Appbar/CreateBet/CreateBet';
import History from './Appbar/History/History';
import DepositFund from './Appbar/DepositFund/DepositFund';
import GameModes from './Appbar/GameModes/GameModes';
import MatchWithBot from './Appbar/MatchWithBot/MatchWithBot';
import WinMatch from './Appbar/WinMatch/WinMatch';
import Withdraw from './Appbar/Withdraw/Withdraw';
import UserProfile from './Appbar/GameUserProfile/UserProfile';
import Both from './Appbar/History/Both';
import SecretNumber from './Appbar/SecretNumber/SecretNumber';
import CounterOffer from './Appbar/CounterOffer/CounterOffer';
import InviteOffer from './Appbar/InviteOffer/InviteOffer';
import Invite from './Appbar/Invite/Invite';
import Notifications from './Appbar/Notifications/Notifications';
import backgroundMusic from './Assets/Audiosss/Peeknum-theme.mp3';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import AudioFile from './Assets/Audiosss/Button-Click.mp3';
import BeforePlay from './Appbar/BeforePlay/BeforePlay';
import { useDispatch } from 'react-redux';
import GameScreen from './Appbar/GameScreen/GameScreen';
import WaitingScreen from './Appbar/CreateBet/WaitingScreen';
import PrivacyPolicy from './Components/Footer/PrivacyPolicy';
import ContactUs from './Components/Footer/ContactUs';
import Donate from './Components/Footer/TermsAndConditions';
import More from './Components/Footer/More';
import TermsAndConditions from './Components/Footer/TermsAndConditions';
import { SocketProvider } from './Helper/useGameSocket';

function playSound() {
  const audioElement = new Audio(AudioFile);
  audioElement.play()
}

function App() {
  const dispatch = useDispatch()
  const [isSoundEnabled, setIsSoundEnabled] = useState(true);
  const [isMusicPlaying, setIsMusicPlaying] = useState(true);
  const audioRef = useRef(null);

  useEffect(() => {
    const settingsData = JSON.parse(localStorage.getItem("settings"));

    if (settingsData !== null && Object.keys(settingsData).length > 0) {
      setIsSoundEnabled(settingsData.sfx);
      setIsMusicPlaying(settingsData.music);
    }
  }, []);

  useEffect(() => {
    const settings = {
      music: isMusicPlaying,
      sfx: isSoundEnabled
    };
    localStorage.setItem("settings", JSON.stringify(settings));
  }, [isMusicPlaying, isSoundEnabled]);

  const handleMusicPlay = () => {
    if (!isMusicPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
  };

  const handleMusicToggle = () => {
    if (!isMusicPlaying) {
      audioRef.current.play()
    } else {
      audioRef.current.pause();
    }

    setIsMusicPlaying(!isMusicPlaying);
  };

  useEffect(() => {
    const handleWindowClick = () => {
      window.removeEventListener('click', handleWindowClick);

      const { target } = event;
      if (
        (target.classList.contains('beforeplay') !== 'page')
      ) {
        playSound();
      }
    };

    window.addEventListener('click', handleWindowClick);

    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  }, []);

  useEffect(() => {
    const handleClick = (event) => {
      const { target } = event;
      if (
        (target.tagName === 'BUTTON') ||
        (target.tagName === 'A' && target.tagName === 'a') ||
        (target.tagName === 'A' && target.classList.contains('play-sound nav-link ') && target.getAttribute('aria-current'))
      ) {
        if (isSoundEnabled) {
          playSound();
        }
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [isSoundEnabled]);

  useEffect(() => {
    if (localStorage.getItem('UserToken')) {
      const token = localStorage.getItem('UserToken')
      dispatch({
        type: 'SET_TOKEN',
        payload: token
      })
    }
  }, [])

  const handleSoundToggle = () => {
    setIsSoundEnabled(!isSoundEnabled);
  };

  return (
    <SocketProvider>
      <div className="App">
        <audio ref={audioRef} src={backgroundMusic} loop />
        <div className='unnes-section'>
          <div className='container'>
            <Router>
              <Routes>
                <Route path="/" element={<BeforePlay handleMusicPlay={handleMusicPlay} />} />
                <Route path="/home" element={<HomeLayout page={<Home />} />} />
                <Route path="/help" element={<HomeLayout page={<Help />} />} />
                <Route path="/user-profile" element={<HomeLayout page={<UserProfile />} />} />
                <Route path="/setting" element={<HomeLayout page={<Setting handleMusicToggle={handleMusicToggle} handleSoundToggle={handleSoundToggle} isSoundEnabled={isSoundEnabled} isPlaying={isMusicPlaying} />} />} />
                <Route path="/my-profile" element={<HomeLayout page={<Profile />} />} />
                <Route path="/free-match" element={<HomeLayout page={<Freematch />} />} />
                <Route path="/create-bet" element={<HomeLayout page={<CreateBet />} />} />
                <Route path="/random-match" element={<HomeLayout page={<RandomMatch />} />} />
                <Route path="/direct-match" element={<HomeLayout page={<DirectMatch />} />} />
                <Route path="/game-rules" element={<HomeLayout page={<GameRules />} />} />
                <Route path="/history/:type" element={<HomeLayout page={<History />} />} />
                <Route path="/history" element={<Navigate to='/history/0' />} />
                {/* <Route path="/both" element={<HomeLayout page={<Both />} />} /> */}
                <Route path="/deposit-fund" element={<HomeLayout page={<DepositFund />} />} />
                <Route path="/game-modes" element={<HomeLayout page={<GameModes />} />} />
                <Route path="/match-with-bot" element={<GameLayout page={<MatchWithBot />} />} />
                <Route path="/play" element={<GameLayout page={<GameScreen />} />} />
                {/* <Route path="/win-match" element={<HomeLayout page={<WinMatch />} />} /> */}
                <Route path="/withdraw" element={<HomeLayout page={<Withdraw />} />} />
                <Route path="/login" element={<LoginLayout page={<Login handleMusicToggle={handleMusicToggle} handleSoundToggle={handleSoundToggle} isSoundEnabled={isSoundEnabled} isPlaying={isMusicPlaying} />} />} />
                <Route path="/signup" element={<LoginLayout page={<Signup />} />} />
                <Route path="/forgot-password" element={<LoginLayout page={<FordotPassword />} />} />
                <Route path="/find-match/:id" element={<HomeLayout page={<WaitingScreen />} />} />
                <Route path="/secret-number/:id" element={<HomeLayout page={<SecretNumber />} />} />
                <Route path="/counter-offer/:id" element={<HomeLayout page={<CounterOffer />} />} />
                <Route path="/invite/:id" element={<HomeLayout page={<Invite />} />} />
                <Route path="/invite-offer/:id" element={<HomeLayout page={<InviteOffer />} />} />
                <Route path="/notifications" element={<HomeLayout page={<Notifications />} />} />

                <Route path="/privacy-policy" element={<FooterLayout page={<PrivacyPolicy />} />} />
                {/* <Route path="/contact-us" element={<FooterLayout page={<ContactUs />} />} /> */}
                <Route path="/terms-and-conditions" element={<FooterLayout page={<TermsAndConditions />} />} />
                <Route path="/more" element={<FooterLayout page={<More />} />} />
              </Routes>
            </Router>
          </div>
        </div>
      </div>
    </SocketProvider>
  );
}

export default App;
