import React, { useState, useEffect } from 'react';
import './Profile.css';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import * as UserAction from '../../Action/UserActions'
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import Token from '../../Helper/Token';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ApiBaseURL, emailVerified } from '../../API';
import { useGameSocket } from '../../Helper/useGameSocket';
import { useTranslation } from 'react-i18next';
import loader from '../../Assets/Screen Icons/loading.gif';
import { validateRequiredFields } from '../../Helper/Validation';

const toastStyle = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
};

function Profile({ userdetails }) {
    const dispatch = useDispatch();
    const { socket } = useGameSocket();
    const { t } = useTranslation();

    const { getUserDetails, EditUserProfile, userResendVariftEmailAction } = bindActionCreators(UserAction, dispatch);
    const [isImgChanged, setIsImgChanged] = useState(false);

    const uploadedImage = React.useRef(null);
    const imageUploader = React.useRef(null);
    const [featuredProductImage, setFeaturedProductImage] = useState({});
    const [productImgChanged, setProductImgChanged] = useState(false);

    const handleImageUpload = (e) => {
        const [file] = e.target.files;
        setIsImgChanged(true);
        if (file) {
            const reader = new FileReader();
            setProductImgChanged(true);

            reader.onload = (event) => {
                const dataURL = event.target.result;
                uploadedImage.current.src = dataURL;
                setFeaturedProductImage(dataURL);
            };

            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        const handleClick = () => {
            const uploadInput = document.querySelector('#profile-image-upload');
            if (uploadInput) {
                uploadInput.click();
            }
        };

        const profileImage = document.querySelector('#profile-image1');
        if (profileImage) {
            profileImage.addEventListener('click', handleClick);
        }

        return () => {
            if (profileImage) {
                profileImage.removeEventListener('click', handleClick);
            }
        };
    }, []);

    // const [text, setText] = useState("");
    const [isCopied, setIsCopied] = useState(false);

    const onCopyText = () => {
        setIsCopied(true);
        toast.success(`${t('Player ID copied successfully')}`, toastStyle)
        setTimeout(() => {
            setIsCopied(false);
        }, 1000);
    };

    // Get Email Verified
    useEffect(() => {
        if (socket) {
            socket.on(emailVerified, (data) => {
                if (data.result._id === userdetails._id) {
                    setUserDetails({
                        ...userDetails,
                        email_verified: data.result.email_verified
                    })
                }
            });

            return () => {
                socket.off(emailVerified);
            }
        }
    }, [socket]);

    // edit profile
    const [editMode, setEditMode] = useState(false);
    const [userDetails, setUserDetails] = useState({
        name: '',
        gender: '',
        email: '',
        profilePic: '',
        password: ''
    });

    useEffect(() => {
        if (userdetails) {
            setUserDetails({
                name: userdetails?.result.name,
                gender: userdetails?.result.gender,
                email: userdetails?.result.email,
                profilePic: userdetails?.result.profilePic,
                email_verified: userdetails?.result.email_verified
            })
        }
    }, [userdetails])

    const [isPassChange, setIsPassChange] = useState(false)
    const handleChange = (e, data) => {
        setUserDetails({
            ...userDetails,
            gender: e.target.value
        })
        if (data === "Password") {
            setIsPassChange(true)
            setUserDetails({
                ...userDetails,
                password: e.target.value
            })
        }
    }

    const handleResendEmail = (e) => {
        e.preventDefault()
        Token();
        userResendVariftEmailAction()
            .then((res) => {
                toast.success(res.message, toastStyle);
            })
            .catch((error) => {
                toast.error(error.response?.data.message, toastStyle);
            });
    }

    const handleToggle = () => {
        if (editMode) {
            setEditMode(false);
        } else {
            setEditMode(true);
        }
    }

    // Loading 
    const [isLoading, setIsLoading] = useState(false)

    // Validations
    const [errors, setErrors] = useState({});
    const [showErrors, setShowErrors] = useState(false);

    useEffect(() => {
        const allErrors = validateRequiredFields(userDetails, ["name", "gender", "email", "profilePic"], t)
        setErrors(allErrors)
    }, [userDetails])

    const handleEditProfile = (e) => {
        e.preventDefault()
        const editData = new FormData()
        editData.append("name", userDetails.name)
        editData.append("email", userDetails.email)
        editData.append("gender", userDetails.gender)
        if (isImgChanged) {
            editData.append("profile_pic", featuredProductImage)
        }
        if (isPassChange) {
            editData.append("password", userDetails.password)
        }

        if (editMode) {
            setShowErrors(true)
            if (Object.keys(errors).length === 0) {
                setIsLoading(true)
                EditUserProfile(editData)
                    .then((res) => {
                        toast.success(res.message, toastStyle);
                        const deviceId = {
                            device_id: localStorage.getItem('deviceId')
                        }
                        getUserDetails(deviceId)
                            .catch((error) => {
                                if (error.response && error.response.status === 401) {
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    navigate("/login");
                                }
                            });
                        setEditMode(false);
                        setIsImgChanged(false)
                        setIsLoading(false)
                    })
                    .catch((error) => {
                        toast.error(error.response?.data.message, toastStyle);
                        setIsImgChanged(false)
                        setIsLoading(false)
                    });
            }
        } else {
            setEditMode(true);
            setShowErrors(false)
            setUserDetails(userDetails);
        }
    };


    return (
        <>
            <div className='editprofile-preview'>
                <EditOutlinedIcon
                    onClick={handleToggle}
                />
                <Form>
                    <div className={editMode ? "profile-pic editMode" : "profile-pic"}>
                        <div className='ChangeProfileCard'   >
                            <input type="file" accept="image/jpeg, image/png" onChange={(e) => handleImageUpload(e, "product")} ref={imageUploader} style={{ display: "none" }} />
                            <div className='UserProfileCard' onClick={() => imageUploader.current.click()} >
                                {
                                    !productImgChanged ? <img ref={uploadedImage} className='UserProfileImg' src={userDetails.profilePic} name='productImage' /> :
                                        <img ref={uploadedImage} className='UserProfileImg 1' src={uploadedImage ? uploadedImage : userDetails.profilePic} name='productImage' />
                                }
                            </div>
                        </div>
                        <div style={{ color: '#fff' }} >
                            <CopyToClipboard text={userdetails?.result.user_key} onCopy={onCopyText}>
                                <div className="copy-area">
                                    <Button className='copy-text-btn'>{userdetails?.result.user_key}</Button>
                                    <span className={`copy-feedback ${isCopied ? "active" : ""}`}>
                                        <ContentCopyIcon />
                                    </span>
                                </div>
                            </CopyToClipboard>
                        </div>
                    </div>
                    <div className="profile-files">
                        <div className='From-groups'>
                            <label htmlFor="Name">{t('name')}</label>
                            <div>
                                <input type="text" name="Name" className={editMode ? 'From-inputs editMode' : 'From-inputs'}
                                    value={userDetails?.name}
                                    onChange={(e) => setUserDetails({ ...userDetails, name: e.target.value })}
                                    disabled={!editMode}
                                />
                                {showErrors ? <p className='error-msg'>{errors?.name?.message}</p> : ""}
                            </div>
                        </div>

                        <div className='From-groups'>
                            <label htmlFor="Email">{t('email')}</label>
                            <div>
                                <input type="email" name="Email" className={editMode ? 'From-inputs editMode' : 'From-inputs'}
                                    value={userDetails?.email}
                                    onChange={(e) => setUserDetails({ ...userDetails, email: e.target.value })}
                                    disabled={!editMode}
                                />
                                {showErrors ? <p className='error-msg'>{errors?.email?.message}</p> : ""}
                            </div>
                        </div>


                        <div className='From-groups'>
                            <label htmlFor="Password">{t('password')}</label>
                            <input type="password" name="Password" className={editMode ? 'From-inputs editMode' : 'From-inputs'} value={userDetails?.password} onChange={(e) => handleChange(e, "Password")} disabled={!editMode} />
                        </div>

                        <div className='From-groups'>
                            <label>{t('gender')}</label>
                            <div>
                                <div className='grouped-radios'>
                                    <div>
                                        <span className={userDetails.gender == "1" ? 'radio active' : "radio"}></span>
                                        <Form.Check type="radio" name="UserGender" value={1} id='male' label={t('male')} checked={userDetails.gender === 1} onClick={(e) => handleChange(e)} disabled={!editMode} />
                                    </div>
                                    <div>
                                        <span className={userDetails.gender == "2" ? 'radio active' : "radio"}></span>
                                        <Form.Check type="radio" name="UserGender" value={2} id='female' label={t('female')} checked={userDetails.gender === 2} onClick={(e) => handleChange(e)} disabled={!editMode} />
                                    </div>
                                </div>
                                {showErrors && <p className='error-msg'>{errors?.gender?.message}</p>}
                            </div>
                        </div>
                        <div>
                            {
                                userDetails?.email_verified === 0 ?
                                    <div className='resend-email'>
                                        <p>{t('email not verified')} <a href="#" onClick={(e) => handleResendEmail(e)}>{t('resend mail')}</a></p>
                                    </div> : ""
                            }
                        </div>
                        <div className={editMode ? 'Form-submit-btn editMode' : 'Form-submit-btn'}>
                            <Button type='submit'
                                className={`update-pfl-btn ${editMode ? '' : 'disabled'}`}
                                onClick={(e) => {
                                    handleEditProfile(e);
                                }}
                                disabled={!editMode || isLoading}
                            >{t('update profile')}</Button>
                        </div>

                    </div>
                </Form>
            </div>

            {
                isLoading ?
                    <div className='api-loader'>
                        <img src={loader} alt="loader" />
                    </div> : ""
            }

            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>

    );
}

export default Profile;
