import axios from "axios";
import { withdrawalRequest } from "../API";

export const makeWithdrawalRequest = (withdrawalData) => async () => {
    try {
        const adduserdetails = await axios.post(withdrawalRequest, withdrawalData);
        return adduserdetails.data;
    } catch (error) {
        throw error;
    }
}