import axios from "axios";

const Token = () => {
  if (localStorage.getItem("UserToken")) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("UserToken");
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};


export default Token;