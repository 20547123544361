import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useGameSocket } from '../Helper/useGameSocket';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import * as UserAction from '../Action/UserActions'
import Token from '../Helper/Token';


function HomeLayout({ page }) {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("UserToken") == null && sessionStorage.getItem("UserToken") == null) {
      navigate("/login")
    }
  }, [])


  const dispatch = useDispatch();
  const { getUserDetails } = bindActionCreators(UserAction, dispatch);
  const { userdetails } = useSelector((state) => state.user);

  const { socket } = useGameSocket();
  const localDeviceId = localStorage.getItem('deviceId')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    Token()
    const deviceId = {
      device_id: localDeviceId
    }
    getUserDetails(deviceId)
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/login");
        }
      });
  }, [localDeviceId])

  useEffect(() => {
    if (socket) {
      const handleLoginUser = (data) => {
        if (userdetails?.result._id === data.user_id) {
          if (data.device_id !== localDeviceId) {
            localStorage.clear();
            setIsLoading(true)
            setTimeout(() => {
              navigate('/login');
            }, 2500)
          }
        }
      };

      socket.on('loginUser', handleLoginUser);

      return () => {
        socket.off('loginUser', handleLoginUser);
      };
    }
  }, [socket, localDeviceId, navigate]);


  return (
    <div className='home-element-bg'>
      {page}
      {
        isLoading ?
          <div className='api-loader logout'>
            <h2>You have been logged into some other device!</h2>
          </div> : ""
      }
    </div>
  )
}

export default HomeLayout