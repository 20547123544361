import React, { useEffect, useState } from 'react';
import './Withdraw.css'
import Goback from '../../Assets/Screen Icons/Screen Icons/Back arrow.png'
import { NavLink, useNavigate } from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import PrefixSlect from '../../Components/PrefixSlect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UserAction from '../../Action/UserActions';
import * as withdrawAction from '../../Action/paymentAction';
import Token from '../../Helper/Token';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleAmountValidation } from '../../Helper/Validation';
import loader from '../../Assets/Screen Icons/loading.gif';

const toastStyle = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
};

function Withdraw() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const { getUserDetails } = bindActionCreators(UserAction, dispatch);
  const { makeWithdrawalRequest } = bindActionCreators(withdrawAction, dispatch)
  const { userdetails } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Token();
    const deviceId = {
      device_id: localStorage.getItem('deviceId')
    }
    getUserDetails(deviceId)
      .then((res) => {
        setIsLoading(false)
        setWithdrawData({
          ...withdrawData,
          email: res?.data?.result?.email,
        })
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/login");
        }
      });

  }, [])


  const [withdrawData, setWithdrawData] = useState({
    phone: "",
    prefix: "",
    surname: "",
    name: "",
    email: "",
    amount: ""
  })

  const [payment_method, setpayment_method] = useState('MOBILE')

  const handleChange = (e, data) => {
    if (data === "data") {
      setWithdrawData({
        ...withdrawData,
        [e.target.name]: e.target.value,
      })
    }

    if (data === "prefix") {
      setWithdrawData({
        ...withdrawData,
        prefix: e?.value,
      })
    }

    if (data === "method") {
      setWithdrawData({
        ...withdrawData,
        payment_method: e.target?.value
      })
      setpayment_method(e.target?.value)
    }
  }

  const [showerr, setShowerr] = useState("")
  const [error, setError] = useState('');
  const [errorShow, setErrorShow] = useState(false);
  useEffect(() => {
    setError(handleAmountValidation(withdrawData.amount, t, userdetails?.rest_amount))
  }, [withdrawData, userdetails])
  
  const [isLoadingWith, setIsLoadingWith] = useState(false)
  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorShow(true)
    const data = {
      ...withdrawData,
      email: userdetails?.result?.email
    }
    if (Number(withdrawData.amount) < 500) {
      toast.error(`${t('Minimum Withdraw amount:')} 500XOF`, toastStyle);
    } else if (Number(userdetails?.rest_amount) < Number(withdrawData.amount)) {
      toast.error(`${t("You don't have sufficient balance to request for money")}`, toastStyle);
    } else {
      setIsLoadingWith(true)
      makeWithdrawalRequest(data)
        .then((res) => {
          toast.success(t(res.message), toastStyle);
          navigate('/history/1')
          setIsLoadingWith(false)
        })
        .catch((err) => {
          setIsLoadingWith(false)
          toast.error(t(err?.response?.data.message), toastStyle);
          setShowerr(err.response?.data)
        })
    }
  }

  return (
    <div className='Withdraw-money-screen'>
      <div className='Withdraw-money-element-row'>

        <div className='help-element-banner'>
          <div className='go-back-to-main-screen'>
            <NavLink className="play-sound" onClick={() => navigate(-1)}>
              <img src={Goback} alt="" />
            </NavLink>
          </div>
          <div className='banner-title'>
            <h1>{t('withdraw money')}</h1>
          </div>
        </div>

        <div className='profile-update-col Profile-main-body'>
          <div className='Withdraw-preview'>
            <Form onSubmit={(e) => handleSubmit(e, "data")}>

              <div className="profile-files withdraw-money">
                <div className='From-groups withdraw-money'>
                  <label htmlFor="Name">{t('amount')} :</label>
                  <div>
                    <input type="number" name="amount" onChange={(e) => handleChange(e, "data")} className='From-inputs withdraw-money' placeholder='Enter amount' autoComplete='false' />
                    {showerr ? <p className='error-msg'>{showerr.amount?.message}</p> : ""}
                    {errorShow ? <p className='error-msg'>{error}</p> : ""}
                  </div>
                </div>

                <div className='From-groups withdraw-money'>
                  <label htmlFor="Phone"><span>{t('phone no')}</span> :</label>
                  <div>
                    <input type="number" name="phone" onChange={(e) => handleChange(e, "data")} className='From-inputs withdraw-money' placeholder='Enter phone number' />
                    {showerr ? <p className='error-msg'>{showerr.phone?.message}</p> : ""}
                  </div>
                </div>

                <div className='From-groups withdraw-money'>
                  <label htmlFor="Prefix">{t('prefix')} :</label>
                  <div>
                    <PrefixSlect handleChange={handleChange} />
                    {showerr ? <p className='error-msg'>{showerr.prefix?.message}</p> : ""}
                  </div>
                </div>

                <div className='From-groups withdraw-money'>
                  <label htmlFor="Name">{t('name')} :</label>
                  <div>
                    <input type="text" name="name" onChange={(e) => handleChange(e, "data")} className='From-inputs withdraw-money' placeholder='Enter name' />
                    {showerr ? <p className='error-msg'>{showerr.name?.message}</p> : ""}
                  </div>
                </div>

                <div className='From-groups withdraw-money'>
                  <label htmlFor="Surname">{t('Surname')} :</label>
                  <div>
                    <input type="text" name="surname" onChange={(e) => handleChange(e, "data")} className='From-inputs withdraw-money' placeholder='Enter Surname' />
                    {showerr ? <p className='error-msg'>{showerr.surname?.message}</p> : ""}
                  </div>
                </div>

                <div className='From-groups withdraw-money payment-method'>
                  <label htmlFor="Surname">{t('Payment Method')}</label>
                  <div className='radio'>
                    <input type="radio" name="paymentMethod" value="MOBILE" onChange={(e) => handleChange(e, "method")} className='From-inputs' id="MOBILE" checked={payment_method == "MOBILE" ? true : false} />
                    <label htmlFor="MOBILE">Mobile Money</label>
                    <input type="radio" name="paymentMethod" value='WAVECI' onChange={(e) => handleChange(e, "method")} className='From-inputs' id="WAVECI" checked={payment_method == "WAVECI" ? true : false} />
                    <label htmlFor="WAVECI">WAVE</label>
                  </div>
                </div>

                <div className='Form-submit-btn withdraw-money'>
                  <Button type='submit' className='update-pfl-btn withdraw' disabled={isLoadingWith}>{t('submit')}</Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      {
        isLoading ?
          <div className='api-loader'>
            <img src={loader} alt="loader" />
          </div> : ""
      }
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  )
}

export default Withdraw