import React from 'react'

function FranchPrivacy() {
    return (
        <div className='game-rule-inside-body-section'>
            <div className='first-section-gm-rule'>
                <div className='firt-gm-rule'>
                    <h2>POLITIQUE DE CONFIDENTIALITÉ de PeekNum</h2>
                    <h3>Introduction</h3>
                    <p>A PeekNum, nous respectons la vie privée de nos utilisateurs et nous nous engageons à protéger toute vie privée des utilisateurs qui accèdent, visitent ou interagissent avec notre site Web, disponible https://peeknum.com ainsi que tout autre site Web appartenant à la Société et lié à cette politique de confidentialité (collectivement : les « Sites Web »). Cette politique de confidentialité vous aidera à comprendre quels types d'informations nous collectons via les sites Web, comment la Société les utilise et quels choix vous avez. Nous vous encourageons à lire attentivement cette politique de confidentialité et à l'utiliser pour prendre des décisions éclairées.</p>
                    <h3>COLLECTE ET STOCKAGE DE VOS INFORMATIONS PERSONNELLES</h3>
                    <p>Nous collectons et utilisons les types d’informations suivants vous concernant :</p>
                    <h3>Informations personnelles</h3>
                    <p>Les renseignements personnels sont des informations qui identifient un individu ou qui peuvent, moyennant un effort raisonnable, identifier un individu. Nous recueillons les types d’informations personnelles suivants:</p>
                    <h3>Information:</h3>
                    <p>Nom, adresse e-mail et autres informations de contact : nous demandons et, à votre discrétion, collectons vos données de contact (telles que votre nom et votre adresse e-mail) lorsque vous soumettez des formulaires Web sur nos sites Web, y compris les opportunités de vous inscrire et d'accepter. Pour recevoir des communications par courrier électronique de notre part. Nous pouvons également vous demander de soumettre ces informations personnelles si vous choisissez d'utiliser les fonctionnalités interactives des sites Web, notamment en demandant une assistance client ou en communiquant avec nous. Nous enverrons ces communications conformément à la loi applicable.</p>
                    <h3>Informations volontaires :</h3>
                    <p>Nous collectons les informations personnelles que les utilisateurs nous fournissent volontairement. Par exemple, lorsqu'ils répondent à des communications de notre part ou lorsqu'ils partagent des informations supplémentaires via leur utilisation des sites Web. </p>
                    <p>Informations sur l'appareil : nous collectons auprès de nos utilisateurs des types spécifiques de détails de connexion et d'informations concernant leur appareil, logiciel ou matériel qui peuvent les identifier, tels que des identifiants en ligne, des identifiants uniques de l'appareil (par exemple UDID, adresse MAC), l'adresse IP et la géolocalisation.</p>
                    <h3>Données analytiques :</h3>
                    <p>Nos serveurs enregistrent automatiquement certaines informations concernant l'utilisation de nos sites Web, à l'aide d'analyses tierces. Ces données peuvent inclure des informations telles que le site Web que vous visitiez avant d'accéder à nos sites Web et d'autres statistiques liées à nos sites Web. Nous effectuons également des analyses statistiques sur toutes les données et métadonnées collectées via nos sites Web. Nous utilisons ces informations pour administrer les sites Web et analyser ces informations afin d'améliorer et d'améliorer les sites Web en élargissant leurs caractéristiques et fonctionnalités. Ces données sont également utilisées pour résoudre les erreurs et les bugs ainsi qu'à des fins de recherche et d'analyse concernant votre utilisation des sites Web.</p>
                    <h3>INFORMATIONS NON PERSONNELLES</h3>
                    <p>Les informations non personnelles sont des informations non identifiables qui, prises seules, ne peuvent pas être utilisées pour vous identifier. En tant que tel, nous ne connaissons pas l’identité de l’utilisateur auprès duquel les informations non personnelles ont été collectées. Nous pouvons anonymiser ou anonymiser les informations collectées par les sites Web ou par d'autres moyens afin que les informations ne puissent pas, à elles seules, vous identifier personnellement. Nous collectons également des données utilisateurs globaux concernant votre utilisation et votre activité sur nos sites Web. Notre utilisation et notre divulgation de ces informations agrégées ou anonymisées ne sont soumises à aucune restriction en vertu de la présente politique de confidentialité, et nous pouvons les divulguer à des tiers sans limitation et à quelque fin que ce soit. Pour éviter tout doute, si nous combinons des informations personnelles avec des informations non personnelles (par exemple des données analytiques), les informations combinées seront traitées comme des informations personnelles tant qu'elles restent combinées.</p>
                    <p><strong>Comment nous utilisons les informations personnelles En plus des fins énumérées ici, les informations personnelles que nous collectons sont utilisées aux fins suivantes :</strong></p>
                    <div className='ul-li-for-rules'>
                        <ul>
                            <li>Fournir, exploiter, maintenir, améliorer et promouvoir les sites Web ;</li>
                            <li>Vous permettre d'accéder et d'utiliser les sites Web ;</li>
                            <li>Envoyer des messages transactionnels, y compris des réponses à vos commentaires, questions et demandes ; fournir un service et une assistance client, et vous envoyer des notifications techniques, des mises à jour, des alertes de sécurité ainsi que des messages d'assistance et administratifs ;</li>
                            <li>Envoyer des communications commerciales, conformément à vos préférences de communication, telles que vous fournir des informations sur les produits et services, les fonctionnalités, les enquêtes, les newsletters, les offres, les promotions, les concours et les événements nous concernant et ceux de nos partenaires ; et envoyer d'autres nouvelles ou informations sur nous et nos partenaires. Pour en savoir plus, consultez la rubrique « Marketing » ;</li>
                            <li>Se conformer aux obligations légales ainsi qu'enquêter et prévenir les transactions frauduleuses, l'accès non autorisé aux sites Web et autres activités illégales ;</li>
                            <li>Personnaliser les sites Web, notamment en fournissant des fonctionnalités ou du contenu qui correspondent à vos intérêts et préférences ; et</li>
                            <li>Traitement à d'autres fins pour lesquelles nous obtenons votre consentement.</li>
                        </ul>
                    </div>
                    <h3>Avec qui partageons-nous les informations personnelles que nous collectons ?</h3>
                    <p>Nous travaillons avec des employés qui nous aident à gérer notre entreprise (« Prestataires de services »). Ces sociétés fournissent des services pour nous aider à stocker des informations (comme les sociétés de stockage cloud), à fournir une assistance client, à surveiller et analyser les performances de nos sites Web, ainsi qu'à générer des prospects, à fournir un support marketing et à exploiter et améliorer nos sites Web. Ces fournisseurs de services ne peuvent traiter les informations personnelles que conformément à nos instructions et en conformité avec la présente politique de confidentialité et les autres mesures et réglementations applicables en matière de confidentialité et de sécurité. Plus précisément, nous n'autorisons pas nos fournisseurs de services à utiliser les informations personnelles que nous partageons avec eux à leurs propres fins de marketing ou à toute autre fin que celle liée aux services qu'ils nous fournissent.</p>
                    <p>En plus du partage avec les fournisseurs de services comme décrit ci-dessus, nous pouvons également partager vos informations personnelles avec d'autres dans les circonstances suivantes :</p>
                    <div className='ul-li-for-rules'>
                        <ul>
                            <li>Avec d'autres filiales ou sociétés affiliées ;</li>
                            <li>Avec nos revendeurs et autres partenaires commerciaux qui peuvent nous aider à distribuer les sites Web ; En cas de fusion, vente, changement de contrôle ou réorganisation de toutes nos activités ;</li>
                            <li>Lorsque nous sommes tenus de divulguer des informations personnelles pour répondre à des assignations à comparaître, à des ordonnances judiciaires ou à une procédure judiciaire, ou pour établir ou exercer nos droits légaux ou nous défendre contre des réclamations légales. Lorsque nous avons une conviction de bonne foi, le partage est nécessaire pour enquêter, prévenir ou prendre des mesures concernant des activités illégales, des fraudes présumées, des situations impliquant des menaces potentielles pour la sécurité physique de toute personne, des violations de nos politiques ou pour toute autre raison requise pour se conformer à nos obligations légales ; où</li>
                            <li>Les tiers avec lesquels nous pouvons partager vos informations personnelles seront tenus de respecter nos normes en matière de traitement des informations et de sécurité. Les informations personnelles que nous leur fournissons ne le seront que dans le cadre de l’exercice de leur fonction.</li>
                        </ul>
                    </div>
                    <h3>Pourquoi nous utilisons vos informations personnelles</h3>
                    <p>Veuillez noter que notre base juridique pour la collecte et l'utilisation de vos informations personnelles dépendra des informations personnelles collectées et du contexte spécifique dans lequel nous les collectons. Nous collectons normalement vos informations personnelles uniquement lorsque : (a) nous avons votre consentement pour le faire, (b) lorsque nous avons besoin de vos informations personnelles pour exécuter un contrat avec vous (par exemple pour fournir le service que vous avez demandé), (c) lorsque le traitement est dans notre intérêt légitime ; ou (d) lorsque nous sommes tenus de collecter, conserver ou partager ces informations personnelles en vertu des lois applicables. Dans certains cas, nous pouvons avoir besoin de vos informations personnelles pour protéger vos intérêts vitaux ou ceux d’une autre personne. Lorsque nous comptons sur votre consentement pour traiter vos informations personnelles, vous avez le droit de retirer ou de refuser votre consentement à tout moment. Lorsque nous nous appuyons sur nos intérêts légitimes pour traiter vos informations personnelles, vous avez le droit de vous y opposer. Si vous avez des questions ou avez besoin de plus amples informations concernant la base juridique sur laquelle nous collectons et utilisons vos informations personnelles, veuillez nous contacter via les coordonnées disponibles ci-dessous.</p>
                    <h3>Marketing</h3>
                    <p>Nous pouvons utiliser vos informations personnelles, telles que votre nom complet, votre adresse e-mail, etc., nous-mêmes ou en faisant appel à nos partenaires tiers dans le but de vous fournir du matériel promotionnel concernant nos sites Web.</p>
                    <p>À tout moment, vous pouvez demander à vous désabonner et à cesser de recevoir des offres marketing en nous envoyant un message vide contenant le mot stop. Veuillez noter que même si vous vous désabonnez de notre liste de diffusion marketing, nous pouvons continuer à vous envoyer des mises à jour et des notifications liées aux sites Web.]</p>
                    <h3>Vos droits</h3>
                    <p>Vous disposez de certains droits concernant vos informations personnelles :</p>
                    <div className='ul-li-for-rules'>
                        <ul>
                            <li>Pour recevoir la confirmation du traitement ou non des informations personnelles vous concernant et accéder à vos informations personnelles stockées, ainsi qu'à certaines informations supplémentaires.</li>
                            <li>Pour recevoir les informations personnelles que vous nous fournissez directement dans un format structuré, couramment utilisé et lisible par machine.</li>
                            <li>Pour demander la rectification de vos informations personnelles qui sont sous notre contrôle.</li>
                            <li>Pour demander l'effacement de vos informations personnelles. Pour vous opposer au traitement des informations personnelles par nos soins.</li>
                            <li>Pour demander la restriction du traitement de vos informations personnelles par nos soins.</li>
                        </ul>
                    </div>
                    <p>Veuillez toutefois noter que ces droits ne sont pas absolus et peuvent être soumis à nos propres intérêts légitimes et exigences réglementaires. Si vous souhaitez déposer une plainte sur la manière dont nous avons traité vos informations personnelles, adressez-vous à info@peeknum.com</p>
                    <p>Si vous n'êtes pas satisfait de notre réponse ou pensez que nous collectons ou traitons vos informations personnelles de manière non conforme aux lois, vous pouvez déposer une plainte auprès de l'autorité de protection des données compétente.</p>
                    <h3>Combien de temps conservons-nous les informations personnelles que nous collectons ?</h3>
                    <p>Nous conservons les informations personnelles que nous collectons auprès des utilisateurs aussi longtemps que nécessaire pour fournir notre service en relation avec les sites Web et pour nous conformer à nos obligations légales, résoudre les litiges et faire respecter nos accords (sauf instruction contraire de notre part). Les périodes de conservation seront déterminées en tenant compte du type d'informations personnelles collectées et de la finalité pour laquelle elles sont collectées, en gardant à l'esprit les exigences applicables à la situation et la nécessité de détruire les informations personnelles obsolètes et inutilisées dans les meilleurs délais raisonnables. Conformément aux réglementations applicables, nous conserverons des dossiers contenant vos informations personnelles, les documents d'ouverture de compte, vos communications et tout autre élément tel que l'exigent les lois et réglementations applicables.</p>
                    <h3>Comment protégeons-nous vos informations personnelles ?</h3>
                    <p>Nous prenons grand soin à mettre en œuvre et à maintenir la sécurité des sites Web et de vos informations personnelles. Nous employons des procédures standard de l'industrie pour garantir la confidentialité, l'intégrité et la disponibilité des données de nos utilisateurs, la sécurité de vos informations personnelles et empêcher toute utilisation non autorisée de ces informations.</p>
                    <p>Cependant, nous ne pouvons pas garantir que des pirates informatiques ou du personnel non autorisé n'auront pas accès aux informations personnelles malgré nos efforts. Vous devez noter qu'en utilisant nos sites Web, vos informations personnelles seront transférées via des infrastructures tierces qui ne sont pas sous notre contrôle. Aucune méthode de transmission sur Internet, ni aucune méthode de stockage électronique, n'est sécurisée à 100 %. Par conséquent, même si nous nous efforçons d’utiliser des moyens commercialement acceptables pour protéger vos informations personnelles, nous ne pouvons garantir leur sécurité absolue.</p>
                    <p>Si vous estimez que votre vie privée n'a pas été traitée conformément à cette politique de confidentialité, veuillez nous contacter directement au info@peeknum.com.</p>
                    <h3>Mineurs</h3>
                    <p>es sites Web ne sont pas destinés aux personnes de moins de 18 ans. Si vous avez moins de 18 ans, vous ne devez pas utiliser les sites Web ni nous fournir d'informations personnelles.</p>
                    <p>Nous nous réservons le droit d'accéder et de vérifier toutes les informations personnelles que nous collectons auprès de vous. Si nous apprenons qu'une personne de moins de 18 ans a partagé des informations personnelles, nous supprimerons ces informations.</p>
                    <h3>Modifications de la politique</h3>
                    <p>Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment, veuillez donc visiter cette page fréquemment. Nous vous informerons des modifications substantielles de cette politique de confidentialité via nos sites Web ou nous vous enverrons un e-mail concernant ces modifications à l'adresse e-mail que vous avez fourni (si vous l'avez fait volontairement). De tels changements substantiels entreront en vigueur sept (7) jours après que cet avis aura été fourni par l'une des méthodes mentionnées ci-dessus. Sinon, toutes les autres modifications apportées à cette politique entreront en vigueur à la date indiquée de la « dernière révision », et votre utilisation continue des sites Web après la date de la dernière révision constituera une acceptation et un accord d'être lié par ces modifications.</p>
                </div>
            </div>
        </div>
    )
}

export default FranchPrivacy