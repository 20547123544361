import { SET_TOKEN, USER_DETAILS, BET_DETAILS } from "../Type"

const initialState = {
    token: '', // Initialize the token as null
    isAuthenticated: false,
    docs: [],
    totalDocs: 0,
    limit: 0,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
    betDetails: [],
    betDetailsLoading: true
    // Other state properties, if any
};

const userReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case SET_TOKEN:
            return {
                ...state,
                token: payload,
                isAuthenticated: true
            };
        case USER_DETAILS:
            return {
                ...state,
                userdetails: payload,
            };
            case BET_DETAILS:
            return {
                ...state,
                betDetails: payload,
                betDetailsLoading: false
            };
        // Handle other actions, if any
        default:
            return state;
    }
};

export default userReducer;