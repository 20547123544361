const BaseURL = "https://api.peeknum.com:6060"
// const BaseURL = "http://43.204.243.212:6060"
// const BaseURL = "http://dev.saturncube.com:7465"

export const UserSignup = `${BaseURL}/api/user/register` //POST
export const UserLogin = `${BaseURL}/api/user/login` //POST
export const editUserProfile = `${BaseURL}/api/user/` //PUT
export const UserCheckVariftEmail = `${BaseURL}/api/user/check-verify-email` //POST
export const UserResendVarificationMail = `${BaseURL}/api/user/send-verification-mail` //GET
export const UserForgotPassword = `${BaseURL}/api/user/forget-password` //POST
export const UserSendOtp = `${BaseURL}/api/user/send-otp` //POST
export const UserOtpVerification = `${BaseURL}/api/user/otp-verification` //POST
export const UserResetPassword = `${BaseURL}/api/user/reset-password`  //PUT
export const GetUserDetails = `${BaseURL}/api/user`  //POST
export const UserSearch = `${BaseURL}/api/user/search`  //POST
export const UserNotification = `${BaseURL}/api/user/notification`  //GET
export const UserCurrentGame = `${BaseURL}/api/user/current-game`  //GET
export const CreateGame = `${BaseURL}/api/game`  //POST
export const GetGameDetails = `${BaseURL}/api/game`  //PATCH
export const GetCurrentGame = `${BaseURL}/api/user/current-game`  //GET
export const SearchUser = `${BaseURL}/api/user/search`  //POST
export const AcceptDecline = `${BaseURL}/api/game/accept/decline/`  //PUT
export const GameAbort = `${BaseURL}/api/game/abort`  //POST
export const GameResult = `${BaseURL}/api/game/result`  //PATCH
export const WalletHistory = `${BaseURL}/api/wallet/histroy`  //GET
export const withdrawalRequest = `${BaseURL}/api/wallet/withdrawal-request`  //POST
export const getGoogleLogin = `${BaseURL}/api/user/googleSignin`  //POST
export const getTransaction = `${BaseURL}/api/wallet/transaction-id`  //POST


// Sockets
export const ApiBaseURL = `${BaseURL}`
export const loginData = "loginData"
export const emailVerified = "emailVerified"
export const randomLiveMatch = "randomLiveMatch"
export const serverCurrentTime = "serverCurrentTime"
export const deletedRandomMatch = "deletedRandomMatch"
export const liveGames = "liveGames"
export const gameAccept = "gameAccept"
export const gameDecline = "gameDecline"
export const gamePlayTurn = "gamePlayTurn"
export const gameStatus = "gameStatus"
export const gameAborted = "gameAborted"
export const returnGameStatus = "returnGameStatus"
export const returnPlayData = "returnPlayData"
export const gameTimeOver = "gameTimeOver"
export const gameInvitation = "gameInvitation"
export const listenReconnect = "listenReconnect"
export const previousGame = "previousGame"