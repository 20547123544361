import React, { useEffect, useState } from 'react'
import './Profile.css'
import Logout from '../../Assets/Screen Icons/Log-out.svg'
import { NavLink, useNavigate } from 'react-router-dom';
import Goback from '../../Assets/Screen Icons/Screen Icons/Back arrow.png'
import ImagePreview from './EditProfile';
import Balance from './Balance';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import * as UserAction from '../../Action/UserActions'
import Token from '../../Helper/Token';
import { useTranslation } from 'react-i18next';
import loader from '../../Assets/Screen Icons/loading.gif';
import { Box, Modal } from '@mui/material';
import Typography from "@mui/material/Typography";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

function Profile() {

    const { t } = useTranslation();
    const navigate = useNavigate()
    const handleLogout = (e) => {
        localStorage.clear()
        navigate('/login')
    }
    const dispatch = useDispatch()
    const { getUserDetails } = bindActionCreators(UserAction, dispatch)
    const [restBalance, setRestBalance] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        Token()
        const deviceId = {
            device_id: localStorage.getItem('deviceId')
        }
        getUserDetails(deviceId)
            .then((res) => {
                setIsLoading(false)
                setRestBalance(res.data.rest_amount)
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    localStorage.clear();
                    sessionStorage.clear();
                    navigate("/login");
                }
            });
    }, [])
    const { userdetails } = useSelector((state) => state.user);


    // Abort Popup
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div className='Profile-screen'>
            <div className='Profile-element-row'>
                <div className='profile-element-banner'>
                    <div className='go-back-to-main-screen'>
                        <NavLink className="play-sound" to="/home">
                            <img src={Goback} alt="" />
                        </NavLink>
                    </div>
                    <div className='banner-title profile'>
                        <h1>{t('profile')}</h1>
                    </div>
                </div>
                <div className='Profile-inside-cnt-bd'>
                    <div className='profile-update-col Profile-main-body bg-add'>
                        <ImagePreview userdetails={userdetails} />
                    </div>
                    <div className='profile-update-col Profile-main-body'>
                        <Balance restBalance={restBalance} />
                    </div>
                </div>
                <div className='Profile-footer-logout'>
                    <div className='logout-main-screen'>
                        <NavLink className="play-sound" onClick={(e) => handleOpen(e)}>
                            <img src={Logout} alt="" />
                            <div className='lg-out-text'>
                                <h1>{t('logout')}</h1>
                            </div>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className="abort popup">
                <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
                    <Box className="abort data-popup" sx={style}>
                        <div className='free-match-banner-section free-match-bg-avb-desh'>
                            <h2>{t('exit text')}</h2>
                        </div>
                        <Typography className="model-text" id="modal-modal-description" sx={{ mt: 2 }} >
                            <h2>{t('Are you sure want to Logout?')}</h2>
                        </Typography>
                        <Typography className="model-button">
                            <button className='letsplay-freegm-btn-blnssht green' onClick={handleClose} >{t('cancel')}</button>
                            <button className='letsplay-freegm-btn-blnssht red' onClick={(e) => handleLogout(e)}> {t('logout')}</button>
                        </Typography>
                    </Box>
                </Modal>
            </div>
            {
                isLoading ?
                    <div className='api-loader'>
                        <img src={loader} alt="loader" />
                    </div> : ""
            }
        </div>
    )
}

export default Profile