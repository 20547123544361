import React from 'react'

function EnglisPrivacy() {
    return (
        <div className='game-rule-inside-body-section'>
            <div className='first-section-gm-rule'>
                <div className='firt-gm-rule'>
                    <h2>PeekNum PRIVACY POLICY</h2>
                    <h3>Introduction</h3>
                    <p>We, at PeekNum, respect the privacy of our users (each, "you" or "User") and are committed to protecting any privacy of Users who access, visit, or engage with our website, available https://peeknum.com/  as well as any other websites the Company owns and that link to this privacy policy (collectively: the "Websites"). This privacy policy will help you understand what types of Information we collect through the Websites, how the Company uses it, and what choices you have. We encourage you to read this privacy policy carefully and use it to make informed decisions.</p>
                    <h3>COLLECTION AND STORAGE OF YOUR PERSONAL INFORMATION</h3>
                    <p>We collect and use the following types of information about you:</p>
                    <h3>Personal Information</h3>
                    <p>Personal Information is information that identifies an individual or that may, with reasonable effort, identify an individual. We gather the following types of Personal Information:</p>
                    <h3>Information:</h3>
                    <p>Name, email address, and other contact information: We ask for and-at your option-collect your contact data (such as name and email address) from you when you submit web forms on our Websites, including opportunities to sign up for and agree to receive email communications from us. We also may ask you to submit such Personal Information if you choose to use interactive features of the Websites, including requesting customer support, or otherwise communicating with us. We will send such communications in accordance with applicable law.</p>
                    <h3>Voluntary information:</h3>
                    <p>We collect Personal Information that Users provide us voluntarily. For example, when they respond to communications from us, or when they share additional information through their use of the Websites.</p>
                    <p>Device information: We collect from our Users specific types of connection details and information with regard to their device, software or hardware that may identify them, such as online identifiers, device unique identifiers (e.g. UDID, MAC address), IP address and geolocation.</p>
                    <h3>Analytics data:</h3>
                    <p>r servers automatically record certain information regarding the use of our Websites, using third-party analytics. Such data may include information such as the website that you were visiting before accessing our Websites and other statistics related to our Websites. We also perform statistical analysis on all of the data and metadata which is collected through our Websites. We use this information to administer the Websites and analyze this information to improve and enhance the Websites by expanding their features and functionality. Such data is also used for troubleshooting errors and bugs as well as for research and analytics purposes about your use of the Websites.</p>
                    <h3>NON-PERSONAL INFORMATION</h3>
                    <p>Non-personal Information is non-identifiable information that, when taken alone, cannot be used to identify you. As such, we are not aware of the identity of the user from which the Non-personal Information was collected. We may anonymize or de-identify the information collected by the Websites or via other means so that the information cannot, on its own, personally identify you. We also collect aggregate user data regarding your use and activity on our Websites. Our use and disclosure of such aggregated or de-identified information is not subject to any restrictions under this privacy policy, and we may disclose it to others without limitation and for any purpose. For the avoidance of doubt, if we combine Personal Information with Non- personal Information (e.g. analytics data), the combined information will be treated as Personal Information as long as it remains combined.</p>
                    <p><strong>How We Use Personal Information In addition to the purposes listed herein, the Personal Information we collect is used for the following purposes:</strong></p>


                    <div className='ul-li-for-rules'>
                        <ul>
                            <li>Provide, operate, maintain, improve, and promote the Websites;</li>
                            <li>Enable you to access and use the Websites; </li>
                            <li>Send transactional messages, including responses to your comments, questions, and requests; provide customer service and support, and send you technical notices, updates, security alerts, and support and administrative messages;</li>
                            <li>Send commercial communications, in accordance with your communication preferences, such as providing you with information about products and services, features, surveys, newsletters, offers, promotions, contests, and events about us and our partners; and send other news or information about us and our partners. See more under "Marketing";</li>
                            <li>Comply with legal obligations as well as to investigate and prevent fraudulent transactions, unauthorized access to the Websites, and other illegal activities;</li>
                            <li>Personalize the Websites, including by providing features or content that match your interests and preferences; and</li>
                            <li>●Process for other purposes for which we obtain your consent.</li>
                        </ul>
                    </div>
                    <h3>With whom do we share the Personal Information we collect?</h3>
                    <p>We work with employees who help us run our business ("Service Providers"). These companies provide services to help us store information (such as cloud storage companies), deliver customer support, monitor and analyze the performance of our Websites, as well as sales leads, provide marketing support, and otherwise operate and improve our Websites. These Service Providers may only process Personal Information pursuant to our instructions and in compliance both with this privacy policy and other applicable confidentiality and security measures and regulations. Specifically, we do not permit our Service Providers to use any Personal Information we share with them for their own marketing purposes or for any other purpose than in connection with the services they provide to us.</p>
                    <p>In addition to sharing with Service Providers as described above, we may also share your Personal Information with others in the following circumstances:</p>
                    <div className='ul-li-for-rules'>
                        <ul>
                            <li>With other subsidiaries or affiliate companies;</li>
                            <li>With our resellers and other sales partners who may help us distribute the Websites; In the event of a merger, sale, change in control, or reorganization of all our part of our business;</li>
                            <li>When we are required to disclose Personal Information to respond to subpoenas, court orders, or legal process, or to establish or exercise our legal rights or defend against legal claims. Where we have a good-faith belief sharing is necessary to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our policies, or as otherwise required to comply with our legal obligations; or</li>
                            <li>Third-parties who we may share your Personal Information with will be required to meet our standards on processing information and security. The Personal Information we provide them will only be provided in connection with the performance of their function.</li>
                        </ul>
                    </div>
                    <h3>Why we use your Personal Information</h3>
                    <p>Please note that our legal basis for collecting and using your Personal Information will depend on the Personal Information collected and the specific context in which we collect it. We normally collect Personal Information from you only where: (a) we have your consent to do so, (b) where we need your Personal Information to perform a contract with you (e.g. to deliver the service you have requested), (c) where the processing is in our legitimate interests; or (d) where we are required to collect, retain or share such Personal Information under applicable laws. In some cases, we may need the Personal Information to protect your vital interests or those of another person. Where we rely on your consent to process your Personal Information, you have the right to withdraw or decline consent at any time. Where we rely on our legitimate interests to process your Personal Information, you have the right to object. If you have any questions about or need further information concerning the legal basis on which we collect and use your Personal Information, please contact us through the contact details available below.</p>
                    <h3>Marketing</h3>
                    <p>We may use your Personal Information, such as your full name, email address, etc., ourselves or by using our third- party partners for the purpose of providing you with promotional materials, concerning our Websites.</p>
                    <p>At any time, you may request to unsubscribe and discontinue receiving marketing offers by sending us a blank message with the word stop Please note that even if you unsubscribe from our marketing mailing list, we may continue to send you Websites-related updates and notifications.]</p>
                    <h3>Your Rights</h3>
                    <p>You have certain rights in relation to your Personal Information:</p>
                    <div className='ul-li-for-rules'>
                        <ul>
                            <li>To receive confirmation as to whether or not Personal Information concerning you is being processed, and access your stored Personal Information, together with certain supplementary information.</li>
                            <li>To receive Personal Information you directly volunteer to us in a structured, commonly used and machine- readable format.</li>
                            <li>To request rectification of your Personal Information that is in our control.</li>
                            <li>To request erasure of your Personal Information. To object to the processing of Personal Information by us.</li>
                            <li>To request to restrict processing of your Personal Information by us.</li>
                        </ul>
                    </div>
                    <p>However, please note that these rights are not absolute, and may be subject to our own legitimate interests and regulatory requirements. If you wish to raise a complaint on how we have handled Your Personal Information, you at info@peeknum.com.</p>
                    <p>If you are not satisfied with our response or believe we are collecting or processing your Personal Information not in accordance with the laws, you can file a complaint to the applicable data protection authority.</p>
                    <p>For how long do we retain the Personal Information that we collect?</p>
                    <p>We retain the Personal Information that we collect from Users for as long as needed to provide our service in relation to the Websites and to comply with our legal obligations, resolve disputes and enforce our agreements (unless we are instructed otherwise). Retention periods will be determined taking into account the type of the Personal Information that is collected and the purpose for which it is collected, bearing in mind the requirements applicable to the situation and the need to destroy outdated, unused Personal Information at the earliest reasonable time. Under applicable regulations, we will keep records containing your Personal Information, account opening documents, communications and anything else as required by applicable laws and regulations.</p>
                    <h3>How do we safeguard your Personal Information?</h3>
                    <p>We take great care in implementing and maintaining the security of the Websites and your Personal Information. We employ industry standard procedures to ensure the confidentiality, integrity, and availability of our Users' data, safety of your Personal Information, and prevent unauthorized use of any such information.</p>
                    <p>However, we cannot guarantee that hackers ог unauthorized personnel will not gain access to Personal Information despite our efforts. You should note that by using our Websites, your Personal Information will be transferred through third party infrastructures which are not under our control. No method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>
                    <p>If you feel that your privacy was not treated in accordance with this privacy policy, please contact us directly at info@peeknum.com.</p>
                    <h3>Minors</h3>
                    <p>The Websites are not designated to individuals under the age of 18. If you are under 18 you should not use the Websites nor provide any Personal Information to us.</p>
                    <p>We reserve the right to access and verify any Personal Information that is collected from you by us. In the event that we become aware that an individual under the age of 18 shared any Personal Information, we will discard such information.</p>
                    <h3>Changes to the policy</h3>
                    <p>We reserve the right to change this privacy policy at any time, so please re-visit this page frequently. We will provide notice of substantial changes of this privacy policy through our Websites or we will send you an e-mail regarding such changes to the e-mail address that you volunteered (if you have volunteered it). Such substantial changes will take effect seven (7) days after such notice was provided on any of the above mentioned methods. Otherwise, all other changes to this Policy are effective as of the stated "Last Revised" date, and your continued use of the Websites after the Last Revised date will constitute acceptance of, and agreement to be bound by, those changes.</p>
                </div>
            </div>
        </div>
    )
}

export default EnglisPrivacy