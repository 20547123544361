import React from 'react';
import Goback from '../../Assets/Screen Icons/Screen Icons/Back arrow.png';
import APAYMLogo from '../../Assets/Screen Icons/APAYM.png';
import mailImg from '../../Assets/Screen Icons/email-64.png';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useState } from 'react';

function More() {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const [isLogin, setIsLogin] = useState(false);

    useEffect(() => {
        const userToken = localStorage.getItem('UserToken');

        if (userToken && userToken.trim() !== "") {
            setIsLogin(true);
        }
    }, []);

    return (
        <div>
            <div className='help-element-banner'>
                <div className='go-back-to-main-screen'>
                    <NavLink className="play-sound" onClick={() => navigate(-1)}>
                        <img src={Goback} alt="" />
                    </NavLink>
                </div>
                <div className='banner-title'>
                    <h1>{t("Useful Links")}</h1>
                </div>
            </div>
            <div className='game-rule-inside-body-section usefulLinks'>
                <div className='first-section-gm-rule'>
                    <div className='more-page'>
                        <div className='bottom-btn-elements'>
                            <NavLink className="play-sound" to="/privacy-policy" >
                                <h4>{t('Privacy Policy')}</h4>
                            </NavLink>
                        </div>
                        <div className='bottom-btn-elements'>
                            <NavLink className="play-sound" to="/terms-and-conditions" >
                                <h4>{t('Terms and Conditions')}</h4>
                            </NavLink>
                        </div>
                        {/* <div className='bottom-btn-elements'>
                            <NavLink className="play-sound" to="/contact-us">
                                <h4>{t('Contact Us')}</h4>
                            </NavLink>
                        </div> */}
                        {
                            isLogin ?
                                <div className='bottom-btn-elements'>
                                    <Link className="play-sound" to="https://pay.apaym.com/chenggong" target='_blank' >
                                        <h4>{t('Donate')} <img src={APAYMLogo} alt="" /></h4>
                                    </Link>
                                </div> : ""
                        }
                    </div>

                    <div className='contactDetails'>
                        <h2>Contact Us</h2>
                        <Link to="mailto:info@peeknum.com"><img src={mailImg} alt="" />info@peeknum.com</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default More