import React, { useEffect, useState } from 'react'
import './DepositFund.css'
import Goback from '../../Assets/Screen Icons/Screen Icons/Back arrow.png'
import { NavLink, useNavigate } from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CountrySelect from '../../Components/CountrySelect';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import * as UserAction from '../../Action/UserActions';
import Token from '../../Helper/Token';
import { generateTransactionId, handleAmountValidation, validateRequiredFields } from '../../Helper/Validation';
import { ApiBaseURL, getTransaction } from '../../API';
import axios from 'axios';
import loader from '../../Assets/Screen Icons/loading.gif';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const toastStyle = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
};

function DepositFund() {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const [step, setStep] = useState(1)
  const [showerr, setShowerr] = useState("")
  const { getUserDetails } = bindActionCreators(UserAction, dispatch);
  const { userdetails } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    Token();
    const deviceId = {
      device_id: localStorage.getItem('deviceId')
    }
    getUserDetails(deviceId)
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/login");
        }
      });
  }, [])

  const [customerData, setCustomerData] = useState({
    apikey: "",
    site_id: "",
    transaction_id: "",
    amount: "",
    currency: "",
    alternative_currency: "",
    description: "",
    customer_id: "",
    notify_url: "",
    return_url: "",
    channels: "",
    lang: "",
  })

  const handleChange = (e, data) => {
    if (data === "data") {
      setCustomerData({
        ...customerData,
        [e.target.name]: e.target.value,
      })
    }

    if (data === "country") {
      setCustomerData({
        ...customerData,
        customer_country: e?.label,
      })
    }
  }

  useEffect(() => {
    setCustomerData({
      ...customerData,
      customer_id: userdetails?.result?._id,
      description: `Deposit amount of ${customerData?.amount} ${customerData?.currency} by ${customerData?.customer_name} ${customerData?.customer_surname} to Peeknum.`,
      notify_url: `${ApiBaseURL}/api/wallet/notify-url`,
      return_url: "https://peeknum.com/history",
      apikey: "1206633647618251ae84a875.76648249",
      site_id: "989642",
      currency: "XOF",
      channels: "ALL",
      lang: "FR",
    })
  }, [userdetails])

  const [amountErr, setAmountErr] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  useEffect(() => {
    setAmountErr(handleAmountValidation(customerData.amount, t));
  }, [customerData, userdetails])

  const handleCard = (e) => {
    e.preventDefault();
    setErrorShow(true)
    if (amountErr === "") {
      if (customerData.amount < 100) {
        toast.error("Minimum Diposit amount: 100XOF", toastStyle);
      } else {
        setStep(step + 1)
      }
    }
  }

  const handlePhoneWallet = (e) => {
    e.preventDefault();
    setErrorShow(true)
    if (amountErr === "") {
      if (customerData.amount < 100) {
        toast.error("Minimum Diposit amount: 100XOF", toastStyle);
      } else {
        handlePayment(e);
      }
    }
  }

  // Check errorrs
  const [errors, setErrors] = useState({});
  const [errShow, setErrShow] = useState(false);

  const [requiredFields, setrequiredFields] = useState([
    "customer_country",
    "customer_name",
    "customer_surname",
    "customer_phone_number",
    "customer_email",
    "customer_address",
    "customer_city",
    "customer_state",
    "customer_zip_code",
  ]);

  useEffect(() => {
    if (step === 2) {
      const validationErrors = validateRequiredFields(customerData, requiredFields, t);
      setErrors(validationErrors);
    }
  }, [customerData, requiredFields, step]);


  // Checkout 
  const handlePayment = (e) => {
    e.preventDefault();
    if (step === 2) {
      setErrShow(true);
    }
    
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      axios.get(getTransaction)
        .then((res) => {
          var data = JSON.stringify({
            ...customerData,
            transaction_id: res.data.result
          });

          var config = {
            method: 'post',
            url: 'https://api-checkout.cinetpay.com/v2/payment',
            headers: {
              'Content-Type': 'application/json'
            },
            data: data
          };
          axios(config)
            .then(function (response) {
              setIsLoading(false);
              const paymentUrl = response?.data?.data?.payment_url;
              const description = response?.data?.description;

              toast.success(description, toastStyle);
              setTimeout(() => {
                window.location.replace(paymentUrl)
              }, 1000)
            })
            .catch(function (error) {
              toast.error(error?.response?.data?.description);
            });
        })
    }
  };

  return (

    <div className='Deposit-money-screen'>
      <div className='Deposit-money-element-row'>

        <div className='help-element-banner'>
          <div className='go-back-to-main-screen'>
            <NavLink className="play-sound" onClick={() => navigate(-1)}>
              <img src={Goback} alt="" />
            </NavLink>
          </div>
          <div className='banner-title'>
            <h1>{t('payment method')}</h1>
          </div>
        </div>

        <div className='profile-update-col Profile-main-body'>
          <div className='Deposit-preview'>
            <Form>
              {
                step === 1 ?
                  <>
                    <div className="profile-files Deposite-money">
                      <div className='Deposite-money'>
                        <input type="number" name="amount" onChange={(e) => handleChange(e, "data")} className='From-inputs Deposit-money' placeholder='XOF' autoComplete='false' />
                        {errorShow && amountErr !== "" ? <p className='error-msg'>{amountErr}</p> : ""}
                      </div>
                    </div>

                    <div className='Form-submit-btn deposite-btns'>
                      <Button type='submit' onClick={(e) => handleCard(e)} className='update-pfl-btn withdraw'>{t('card')}</Button>
                      <Button type='submit' onClick={(e) => handlePhoneWallet(e)} className='update-pfl-btn withdraw' disabled={isLoading}>Phone/Wallet</Button>
                    </div>
                  </> :
                  step === 2 ?
                    <div className='profile-files withdraw-money'>
                      <div className='From-groups withdraw-money'>
                        <label htmlFor="Name">{t('name')} :</label>
                        <div>
                          <input type="text" name="customer_name" onChange={(e) => handleChange(e, "data")} className='From-inputs withdraw-money' placeholder='Enter name' autoComplete='false' />
                          {errShow ? <p className='error-msg'>{errors?.customer_name?.message}</p> : ""}
                        </div>
                      </div>

                      <div className='From-groups withdraw-money'>
                        <label htmlFor="Name">{t('Surname')} :</label>
                        <div>
                          <input type="text" name="customer_surname" onChange={(e) => handleChange(e, "data")} className='From-inputs withdraw-money' placeholder='Enter surname' autoComplete='false' />
                          {errShow ? <p className='error-msg'>{errors?.customer_surname?.message}</p> : ""}
                        </div>
                      </div>

                      <div className='From-groups withdraw-money'>
                        <label htmlFor="Name">{t('Pnone No')} :</label>
                        <div>
                          <input type="number" name="customer_phone_number" onChange={(e) => handleChange(e, "data")} className='From-inputs withdraw-money' placeholder='Enter name' autoComplete='false' />
                          {errShow ? <p className='error-msg'>{errors?.customer_phone_number?.message}</p> : ""}
                        </div>
                      </div>

                      <div className='From-groups withdraw-money'>
                        <label htmlFor="Name">{t('Email')} :</label>
                        <div>
                          <input type="email" name="customer_email" onChange={(e) => handleChange(e, "data")} className='From-inputs withdraw-money' placeholder='Enter email' autoComplete='false' />
                          {errShow ? <p className='error-msg'>{errors?.customer_email?.message}</p> : ""}
                        </div>
                      </div>

                      <div className='From-groups withdraw-money'>
                        <label htmlFor="Name">{t('Address')} :</label>
                        <div>
                          <input type="text" name="customer_address" onChange={(e) => handleChange(e, "data")} className='From-inputs withdraw-money' placeholder='Enter address.' autoComplete='false' />
                          {errShow ? <p className='error-msg'>{errors?.customer_address?.message}</p> : ""}
                        </div>
                      </div>

                      <div className='From-groups withdraw-money'>
                        <label htmlFor="Name">{t('City')} :</label>
                        <div>
                          <input type="text" name="customer_city" onChange={(e) => handleChange(e, "data")} className='From-inputs withdraw-money' placeholder='Enter city.' autoComplete='false' />
                          {errShow ? <p className='error-msg'>{errors?.customer_city?.message}</p> : ""}
                        </div>
                      </div>

                      <div className='From-groups withdraw-money'>
                        <label htmlFor="Prefix">{t('Country')} :</label>
                        <div>
                          <CountrySelect handleChange={handleChange} />
                          {errShow ? <p className='error-msg'>{errors?.customer_country?.message}</p> : ""}
                        </div>
                      </div>

                      <div className='From-groups withdraw-money'>
                        <label htmlFor="Name">{t('State')} :</label>
                        <div>
                          <input type="text" name="customer_state" onChange={(e) => handleChange(e, "data")} className='From-inputs withdraw-money' placeholder='Enter state.' autoComplete='false' />
                          {errShow ? <p className='error-msg'>{errors?.customer_state?.message}</p> : ""}
                        </div>
                      </div>

                      <div className='From-groups withdraw-money'>
                        <label htmlFor="Name">{t('Zip Code')} :</label>
                        <div>
                          <input type="number" name="customer_zip_code" onChange={(e) => handleChange(e, "data")} className='From-inputs withdraw-money' placeholder='Enter zipcode.' autoComplete='false' />
                          {errShow ? <p className='error-msg'>{errors?.customer_zip_code?.message}</p> : ""}
                        </div>
                      </div>


                      <div className='Form-submit-btn deposite-btns'>
                        <Button type='submit' onClick={(e) => handlePayment(e)} className='update-pfl-btn withdraw' disabled={isLoading}>Submit</Button>
                      </div>
                    </div> : ""
              }
            </Form>
          </div>
        </div>
      </div>
      {
        isLoading ?
          <div className='api-loader'>
            <img src={loader} alt="loader" />
          </div> : ""
      }
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  )
}

export default DepositFund