import React from 'react';

function FooterLayout({ page }) {
    
  return (
    <div className='home-element-bg'>
      {page}
    </div>
  )
}

export default FooterLayout