import React, { useState, useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom';
import Goback from '../../Assets/Screen Icons/Screen Icons/Back arrow.png'
import './Freematch.css'
import '../MatchWithBot/MatchWithBot.css'
import { GrDown } from 'react-icons/gr'
import { FaCheck } from 'react-icons/fa'
import TimeSelect from '../../Components/TimeSelect';
import NumberRange from '../../Components/NumberRange';
import player1 from '../../Assets/Screen Icons/-xhdpi/profile.png'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useNavigate } from 'react-router-dom';
import HomeBtn from '../../Assets/Screen Icons/Screen Icons/Home.png'
import NextBtn from '../../Assets/Screen Icons/Screen Icons/Next box.png'
import UpIcon from '../../Assets/Screen Icons/UpArrow.png'
import { useTranslation } from 'react-i18next';
import Token from '../../Helper/Token';
import * as userActions from '../../Action/UserActions'
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { handleStopFractionalNumber } from '../../Helper/Validation';

const generateRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Freematch() {
  const { t } = useTranslation();
  const navigate = useNavigate()

  const [guess, setGuess] = useState('');
  const [previousGuess, setPreviousGuess] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [message, setMessage] = useState('');
  const [attempts, setAttempts] = useState(0);
  const [randomNumber, setRandomNumber] = useState(generateRandomNumber(0, 100));
  const [range, setRange] = useState('0-100');
  const [timer, setTimer] = useState();
  const [BotTest, setBotTest] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [stoptimer, setStopTimer] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const [openWinPopup, setOpenWinPopup] = useState(false);
  const handleCloseWinpopup = () => setOpenWinPopup(false);
  const [timeLeft, setTimeLeft] = useState(60); // 5 minutes in seconds
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const [inputNumber, setInputNumber] = useState("");
  const [finaltime, SetfinalTime] = useState("");
  const [BotFinalTime, setBotFinalTIme] = useState("");
  const [isfetched, setisFetched] = useState(false)
  useEffect(() => {

  }, [])
  let BotFinalAttempts;
  // Dropdown Selection
  const [isOpenNum, setOpenNum] = useState(false);
  const [itemsNum, setItemNum] = useState([
    { id: 1, label: `0 ${t('To')} 100`, max: 100 },
    { id: 2, label: `0 ${t('To')} 1000`, max: 1000 },
    { id: 3, label: `0 ${t('To')} 10000`, max: 10000 },
    { id: 4, label: `0 ${t('To')} 100000`, max: 100000 },
    { id: 5, label: `0 ${t('To')} 1000000`, max: 1000000 },
  ]);
  const [selectedItemNum, setSelectedItemNum] = useState(null);

  // Time Selection
  const [isOpenTime, setOpenTime] = useState(false);
  const [itemsTime, setItemTime] = useState([
    { id: 1, label: '05:00', value: 300, exact: 5 },
    { id: 2, label: '10:00', value: 600, exact: 10 },
    { id: 3, label: '15:00', value: 900, exact: 15 },
    { id: 4, label: '20:00', value: 1200, exact: 20 },
    { id: 5, label: '25:00', value: 1500, exact: 25 },
    { id: 6, label: '30:00', value: 1800, exact: 30 },
  ]);
  const [selectedItemTime, setSelectedItemTime] = useState(null);

  const [curruntDetail, setCurruntDetail] = useState({})
  const [curruntRange, setCurruntRange] = useState({})
  const [randomTime, setRandomTime] = useState('')

  useEffect(() => {
    setCurruntRange(itemsNum.find((ele) => {
      return ele.id == selectedItemNum;
    }))
  }, [selectedItemNum, itemsNum])

  useEffect(() => {
    setCurruntDetail(itemsTime.find((ele) => {
      return ele.id == selectedItemTime;
    }))
  }, [selectedItemTime, itemsTime])

  const [error, setError] = useState(false)

  useEffect(() => {
    setRandomTime(getRandomTime(0, curruntDetail?.value))
    if (selectedItemNum === null || selectedItemTime === null || inputNumber === '') {
      setError(true);
    } else {
      setError(false)
    }
  }, [curruntDetail, selectedItemNum, selectedItemTime, inputNumber])


  const getRandomTime = (minTime, maxTime) => {
    const randomSeconds = Math.floor(Math.random() * (maxTime - minTime + 1) + minTime);
    const minutes = Math.floor(randomSeconds / 60);
    const seconds = randomSeconds % 60;
    const formattedTime = `${minutes}:${seconds.toString().padStart(2, '0')}`;
    return formattedTime;
  };
  //PopupScreen For Play Game 
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const toggleDropdownNum = () => {
    setOpenNum(!isOpenNum)
  };
  const toggleDropdownTime = () => {
    setOpenTime(!isOpenTime)
  };

  const handleItemClickNum = (id) => {
    selectedItemNum === id ? setSelectedItemNum(null) : setSelectedItemNum(id);
    setOpenNum(false)
    handleRangeSelection(id);
  };

  const handleItemClickTime = (id) => {
    selectedItemTime === id ? setSelectedItemTime(null) : setSelectedItemTime(id);
    setOpenTime(false)
  };

  const handleRangeSelection = (selectedRange) => {
    let rangeLabel;
    if (selectedRange === 1) {
      setRange(`0 ${t('To')} 100`);
      rangeLabel = `0 ${t('To')} 100`;
    } else if (selectedRange === 2) {
      setRange(`0 ${t('To')} 1000`);
      rangeLabel = `0 ${t('To')} 1000`;
    } else if (selectedRange === 3) {
      setRange(`0 ${t('To')} 10000`);
      rangeLabel = `0 ${t('To')} 10000`;
    } else if (selectedRange === 4) {
      setRange(`0 ${t('To')} 100000`);
      rangeLabel = `0 ${t('To')} 100000`;
    } else if (selectedRange === 5) {
      setRange(`0 ${t('To')} 1000000`);
      rangeLabel = `0 ${t('To')} 100000`;
    }

    setRandomNumber(generateRandomNumber(0, getRangeMax(rangeLabel)));
    setAttempts(0);
    setMessage('');
  };



  const handleInputChange = (e) => {
    setGuess(e.target.value);
  };

  const [guessStyle, setGuessStyle] = useState(3)
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const userGuess = parseInt(guess);
    setPreviousGuess(guess);

    if (isNaN(userGuess)) {
      setMessage(`${t("Please enter a valid number")}.`);
    }
    else if (userGuess < 0) {
      setMessage(`${t("You Can Not Enter Negative Number")}`)
    }
    else if (!selectedItemNum) {
      setMessage(`${t("Please Select Secret Number Range")}`);
    } else if (!selectedItemTime) {
      setMessage(`${t("Please Choose The Duration")}`);
    } else {
      setAttempts(attempts + 1);

      if (userGuess === randomNumber) {
        const botAttempts = botGuess();

        setBackgroundColor('green');
        let final = `${(curruntDetail?.exact - (Math.floor(timer / 60) + 1)) > 9 ? (curruntDetail?.exact - (Math.floor(timer / 60) + 1)) : `0${(curruntDetail?.exact - (Math.floor(timer / 60) + 1))}`} : ${(60 - (timer % 60)) > 9 ? 60 - (timer % 60) : `0${60 - (timer % 60)}`}`;
        SetfinalTime(final)
        const minutes = curruntDetail?.exact - (Math.floor(timer / 60) + 1);
        const seconds = 60 - (timer % 60);
        const resultInSeconds = (minutes * 60) + seconds;


        const random = Math.floor(Math.random() * (resultInSeconds - Math.floor((resultInSeconds / 100) * 70) + 1)) + Math.floor((resultInSeconds / 100) * 70);
        let test = random < 60 ? random < 10 ? `00 : 0${random < 5 ? 5 : random}` : `00: ${random}` : random % 60 < 10 ? `0${Math.floor(random / 60)} : 0${random % 60}` : `0${Math.floor(random / 60)} : ${random % 60}`
        setBotFinalTIme(test)
        setAttempts(attempts);
        setOpenWinPopup(true);
        setStopTimer(true)
        setGuessStyle(3)
      }
      else if (userGuess - randomNumber > -11 && userGuess - randomNumber < 11) {
        setMessage(userGuess - randomNumber > 0 ? `${t("Guess Lower")}` : `${t("Guess Higher")}`);
        setGuessStyle(userGuess - randomNumber > 0 ? 2 : 1)
        setBackgroundColor('green');
      } else if (userGuess < randomNumber) {
        setMessage(`${t("Guess Higher")}`);
        setGuessStyle(1)
        setBackgroundColor('red');
      } else {
        setMessage(`${t("Guess Lower")}`);
        setGuessStyle(2)
        setBackgroundColor('orange');
      }
      // event.target.elements.snumber.blur();
    }

    setGuess('');
  };

  useEffect(() => {
    if (selectedItemTime) {
      const selectedTime = itemsTime.find((item) => item.id === selectedItemTime);
      setTimer(selectedTime.value);
    }
  }, [selectedItemTime, itemsTime]);

  const [gameTimeout, setGameTimeout] = useState(false);

  useEffect(() => {
    let intervalId;

    if (timer > 0 && selectedItemTime && isPopupOpen && !stoptimer) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (timer === 0) {
      clearInterval(intervalId);
      botGuess();
      handleTimeout();
      setGameTimeout(true);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [timer, selectedItemTime, isPopupOpen, stoptimer]);

  const handleTimeout = () => {
    setMessage('Time is up!');
    setOpenWinPopup(true);
    setAttempts(attempts);

    let final = `${(curruntDetail?.exact - (Math.floor(timer / 60) + 1)) > 9 ? (curruntDetail?.exact - (Math.floor(timer / 60) + 1)) : `0${(curruntDetail?.exact - (Math.floor(timer / 60) + 1))}`} : ${(60 - (timer % 60)) > 9 ? 60 - (timer % 60) : `0${60 - (timer % 60)}`}`;
    SetfinalTime(final)
    // let calculation = (curruntDetail?.exact - (Math.floor(timer / 60) + 1) * 60 ) + (60 - (timer % 60))
    const minutes = curruntDetail?.exact - (Math.floor(timer / 60) + 1);
    const seconds = 60 - (timer % 60);
    const resultInSeconds = (minutes * 60) + seconds;


    const random = Math.floor(Math.random() * (resultInSeconds - Math.floor((resultInSeconds / 100) * 70) + 1)) + Math.floor((resultInSeconds / 100) * 70);
    let test = random < 60 ? random < 10 ? `00 : 0${random < 5 ? 5 : random}` : `00: ${random}` : random % 60 < 10 ? `0${Math.floor(random / 60)} : 0${random % 60}` : `0${Math.floor(random / 60)} : ${random % 60}`
    setBotFinalTIme(test)
    // setRandomNumber(generateRandomNumber(0, getRangeMax(range)));
  };

  const botGuess = () => {
    let botAttempt;
    const maxRange = getRangeMax(range);


    if (range === `0 ${t('To')} 100`) {
      botAttempt = Math.floor(Math.random() * (30 - 7 + 1)) + 7;
    } else if (range === `0 ${t('To')} 1000`) {
      botAttempt = Math.floor(Math.random() * (300 - 70 + 1)) + 70;
    } else if (range === `0 ${t('To')} 10000`) {
      botAttempt = Math.floor(Math.random() * (3000 - 700 + 1)) + 700;
    } else if (range === `0 ${t('To')} 100000`) {
      botAttempt = Math.floor(Math.random() * (30000 - 7000 + 1)) + 7000;
    } else {
      botAttempt = Math.floor(Math.random() * (300000 - 70000 + 1)) + 70000;
    }
    setBotTest(botAttempt)

    return botAttempt;
  };

  const getRangeMax = (selectedRange) => {
    return (
      selectedRange === `0 ${t('To')} 100`
        ? 100
        : selectedRange === `0 ${t('To')} 1000`
          ? 1000
          : selectedRange === `0 ${t('To')} 10000`
            ? 10000
            : selectedRange === `0 ${t('To')} 100000`
              ? 100000
              : 1000000
    );
  };


  const [errorMsg, setErrorMsg] = useState("")
  const handlePlayClick = () => {
    if (inputNumber < 0) {
      setErrorMsg(`${t("You Can Not Enter Negative Number")}`)
    }

    else if (inputNumber <= curruntRange?.max) {
      if (!error) {
        setIsPopupOpen(true);
        setErrorMsg()
      } else {
        setErrorMsg(`${t("Please Enter All Details")}`)
      }
    } else {
      setErrorMsg(`${t("Choose Number in selected Range")}`)
    }

  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    if (isPopupOpen) {
      const gameElementBg = document.getElementById('game-element-bg');
      const freeMatchWithBot = document.getElementById('free-match-with-bot');
      const calculateHeight = () => {
        if (gameElementBg && freeMatchWithBot) {
          const height = gameElementBg.offsetHeight;
          freeMatchWithBot.style.minHeight = `${height - 10}px`;
        }
      };
      calculateHeight();
      window.addEventListener('resize', calculateHeight);
    }

  }, [isPopupOpen]);

  // Redux actions
  const dispatch = useDispatch();
  const { getUserDetails } = bindActionCreators(userActions, dispatch);

  // Get User Details
  const [userDetails, setUserDetails] = useState({});
  useEffect(() => {
    Token()
    const deviceId = {
      device_id: localStorage.getItem('deviceId')
    }
    getUserDetails(deviceId)
      .then((res) => {
        setUserDetails(res.data.result);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/login");
        }
      });

  }, [])

  function adjustTime(timeString) {
    const [minutes, second] = timeString.split(':').map(Number);

    let adjustedMinutes = minutes;
    let adjustedsecond = second;

    if (second === 60) {
      adjustedMinutes += 1;
      adjustedsecond = 0;
    }
    return `${ adjustedMinutes }:${ adjustedsecond < 10 ? '0' : '' }${ adjustedsecond }`;
  }

  return (
    <>
      <div className='Freematch-screen'>
        <div className='Freematch-element-row'>

          <div className='help-element-banner'>
            <div className='go-back-to-main-screen'>
              <NavLink className="play-sound" to="/home">
                <img src={Goback} alt="" />
              </NavLink>
            </div>
            <div className='banner-title'>
              <h1>{t('FreeMatch_mp')}</h1>
            </div>
          </div>

          <div className='Freematch-inside-cnt-bd'>
            <div className='profile-update-col Freematch-main-body'>
              <div className='wrapper-first-col'>

                <div className='free-match-banner-section free-match-bg-avb-desh'>
                  <h2>{t('Choose Time limit')}</h2>
                </div>
                <div className='Select-Time'>
                  {/* <TimeSelect /> */}
                  <div className="dropdown">
                    <div className="dropdown-header" onClick={toggleDropdownTime}>
                      {selectedItemTime
                        ? itemsTime.find((item) => item.id === selectedItemTime).label
                        : `${t("Select Time")}`}
                      <i className={`fa fa-chevron-right icon ${isOpenTime && 'open'}`}></i><GrDown />
                    </div>
                    <div className={`dropdown-body ${isOpenTime && 'open'}`}>
                      {itemsTime.map((item) => (
                        <div
                          className="dropdown-item"
                          onClick={() => handleItemClickTime(item.id)}
                          key={item.id}
                        >
                          <span className={`dropdown-item-dot ${item.id === selectedItemTime && 'selected'}`}>
                            <FaCheck />
                          </span>
                          <p>{item.label}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* <TimeSelect /> */}
                </div>
                <div className='free-match-banner-section free-match-bg-avb-desh'>
                  <h2>{t('Secret Number Range')}</h2>
                </div>
                <div className='Select-Time'>
                  {/* <NumberRange /> */}
                  <div className="dropdown">
                    <div className="dropdown-header" onClick={toggleDropdownNum}>
                      {selectedItemNum
                        ? itemsNum.find((item) => item.id === selectedItemNum).label
                        : `${t("Select Range")}`}
                      <i className={`fa fa-chevron-right icon ${isOpenNum && 'open'}`}></i><GrDown />
                    </div>
                    <div className={`dropdown-body ${isOpenNum && 'open'}`}>
                      {itemsNum.map((item) => (
                        <div
                          className="dropdown-item"
                          onClick={() => handleItemClickNum(item.id)}
                          key={item.id}
                        >
                          <span className={`dropdown-item-dot ${item.id === selectedItemNum && 'selected'}`}>
                            <FaCheck />
                          </span>
                          <p>{item.label}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* <NumberRange /> */}
                </div>
              </div>
              <div className='wrapper-second-col'>
                <div className='free-match-banner-section free-match-bg-avb-desh'>
                  <h2>{t('Choose your secret number')}</h2>
                </div>
                <div className='Select-Time'>
                  <div className='slect-secret-number'>
                    <input type="number" name="number" id="" value={inputNumber} placeholder={t('Enter Number')} onChange={(e) => setInputNumber(e.target.value)} onKeyPress={handleStopFractionalNumber} />
                  </div>
                </div>
              </div>
              <div className='wrapper-thierd-col'>
                <div className='lets-play-free'>
                  <button onClick={handlePlayClick} className='letsplay-freegm-btn-blnssht'>{t("play")}</button>
                </div>
              </div>

            </div>
            {errorMsg ? <p className='Trow-error-stil-missing-fd'>{errorMsg}</p> : ""}
          </div>
        </div>
      </div>



      {/* Gamescreen */}
      {isPopupOpen && (
        <div className='popupdbody'>
          <div className="popup">
            <div className={`game-element-bg ${backgroundColor}`} id='game-element-bg'>
              <div className='free-match-with-bot' id='free-match-with-bot'>
                <div className='top-bar'>
                  <h2>Range: {selectedItemNum
                    ? itemsNum.find((item) => item.id === selectedItemNum).label
                    : `${t("Select Number")}`}</h2>
                  <NavLink to="#" className="play-sound nav-link" onClick={(e) => handleOpen(e)} >{t("abort")}</NavLink>
                </div>
                <div className='game-block'>
                  <div className='player player-top'>
                    <img src={player1} alt="" />
                    <h2>Bot</h2>
                  </div>
                  <div className='timer'>
                    <div className='countdown'>
                      {selectedItemTime && (
                        <>
                          {Math.floor(timer / 60)}:{timer % 60 < 10 ? '0' + (timer % 60) : timer % 60}
                        </>
                      )}
                      {/* {formattedMinutes}:{formattedSeconds} */}
                    </div>
                  </div>
                  {previousGuess && <p className='PreviousGuess-Text'>{previousGuess}</p>}
                  <div className='player player-bottom'>
                    {
                      message ?
                        <p className='ResultP'>{message}
                          {guessStyle === 3 ? "" : <img className={guessStyle === 1 ? "ResultArror UpIcon" : guessStyle === 2 ? "ResultArror DownIcon" : ""} src={UpIcon} alt="" />}
                        </p> : ""
                    }
                    <form onSubmit={handleFormSubmit}>
                      <div className='yout-guess'>
                        <input type="number" value={guess} name="snumber" onChange={handleInputChange} id="number-input" placeholder={t('Guess here')} />
                        <div className='lets-play-free'>
                          <button type='submit' className='letsplay-freegm-btn-blnssht'>Ok</button>
                        </div>
                      </div>
                    </form>
                    <h2>{userDetails.user_key}</h2>
                    <img src={userDetails.profilePic} alt="" />
                  </div>
                </div>
                <div className='bottom-bar'>
                  <h2>{t('Attempts')}: {attempts}</h2>
                </div>
              </div>
            </div>

          </div>
        </div>
      )}


      {/* Abort Popup */}
      <div className="popupbodyel">
        <div className='abort popup'>
          <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
            <Box className="abort data-popup" sx={style}>
              <div className='free-match-banner-section free-match-bg-avb-desh'>
                <h2>{t("exit text")}</h2>
              </div>
              <Typography className="model-text" id="modal-modal-description" sx={{ mt: 2 }} >
                <h2> {t("Are you sure want to Abort?")}</h2>
              </Typography>
              <Typography className="model-button">
                <button className='letsplay-freegm-btn-blnssht green' onClick={handleClose}>{t("Cancel")}</button>
                <button className='letsplay-freegm-btn-blnssht red' onClick={() => navigate('/home')} > {t("abort")}</button>
              </Typography>
            </Box>
          </Modal>
        </div>
      </div>

      {/* Win Match Screen */}
      <div className='popupbodyelwin'>
        <div className="result popup">
          <Modal open={openWinPopup} onClose={handleCloseWinpopup} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
            <Box className="result data-popup" sx={style}>
              {
                message === 'Time is up!' && <p className='my-own-texted'>{t("Sorry, your time is up! You couldn't guess the correct number.")}</p>
              }
              <div className='playes free-match'>
                <div
                  className={
                    message === 'Time is up!' ? "player loser" : attempts < BotTest && attempts != BotTest ? "player winner" :
                      attempts === BotTest && (BotTest * 2) > ((curruntDetail?.exact - (Math.floor(timer / 60) + 1)) * 60) + (60 - (timer % 60)) ? "player winner" : "player loser"}
                  dataresult={
                    message === 'Time is up!' ? `${t("loser")}` : attempts < BotTest && attempts != BotTest ? `${t("winner")}` :
                      attempts === BotTest && (BotTest * 2) > ((curruntDetail?.exact - (Math.floor(timer / 60) + 1)) * 60) + (60 - (timer % 60)) ? `${t("winner")}` : `${t("loser")}`
                  }
                >
                  <img src={userDetails.profilePic} alt="" />
                  <h2>{userDetails.user_key}</h2>
                </div>
                <div className='vs-text'>VS</div>
                <div className={
                  message === 'Time is up!' ? "player winner" : attempts > BotTest && attempts != BotTest ? "player winner" :
                    attempts === BotTest && (BotTest * 2) < ((curruntDetail?.exact - (Math.floor(timer / 60) + 1)) * 60) + (60 - (timer % 60)) ? "player winner" : "player loser"}
                  dataresult={
                    message === 'Time is up!' ? `${t("winner")}` : attempts > BotTest && attempts != BotTest ? `${t("winner")}` :
                      attempts === BotTest && (BotTest * 2) < ((curruntDetail?.exact - (Math.floor(timer / 60) + 1)) * 60) + (60 - (timer % 60)) ? `${t("winner")}` : `${t("loser")}`
                  }>
                  <img src={player1} alt="" />
                  <h2>Bot</h2>
                </div>
              </div>
              <div className='game-result'>
                <div>
                  <p>{inputNumber}</p>
                  <p>{!gameTimeout && attempts === 0 ? 1 : attempts}</p>
                  <p>{adjustTime(finaltime)}</p>
                </div>
                <div>
                  <p>{t('Secret Number')}</p>
                  <p>{t('Attempts')}</p>
                  <p>{t('Duration')}</p>
                </div>
                <div>
                  <p>{randomNumber}</p>
                  <p>{BotTest}</p>
                  <p>{adjustTime(BotFinalTime)}</p>
                </div>
              </div>
              <div className='actin-btns'>
                <div className='btn-block'>
                  <NavLink to="/home" className='play-sound btn'><img src={HomeBtn} alt="" /></NavLink>
                  <p>{t('home')}</p>
                </div>
                <div className='btn-block'>
                  <button className='btn' onClick={() => window.location.reload(false)}><img src={NextBtn} alt="" /></button>
                  <p>{t('Replay')}</p>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </div>

    </>

  )
}

export default Freematch


