import React, { useState, useEffect, useContext } from 'react';
import './Setting.css'
import Goback from '../../Assets/Screen Icons/Screen Icons/Back arrow.png'
import { NavLink } from 'react-router-dom';
import Musicon from '../../Assets/Screen Icons/music-onn.png'
import MusicOf from '../../Assets/Screen Icons/music-off.png'
import SfxOn from '../../Assets/Screen Icons/Sfx-on.png'
import SfxOff from '../../Assets/Screen Icons/sfx off.png'
import { initializeI18n } from '../../i18n';
import { useTranslation } from 'react-i18next';

function Setting({ handleMusicToggle, isPlaying, handleSoundToggle, isSoundEnabled }) {

    const { t } = useTranslation();

    const [currentImage, setCurrentImage] = useState(Musicon);
    const [currentImageTwo, setCurrentImageTwo] = useState(SfxOn);
    const [musicOn, setMusicOn] = useState(true);
    // const {  toggleMusic } = useContext(MusicContext);

    useEffect(() => {
    }, [currentImage, currentImageTwo]);

    const handleLanagauge = (e) => {
        e.preventDefault();
        localStorage.setItem('selectedLang' , e.target.value)
        initializeI18n()
    }

    return (
        <div className='Setting-screen'>
            <div className='Setting-element-row'>
                <div className='help-element-banner'>
                    <div className='go-back-to-main-screen'>
                        <NavLink className="play-sound" to="/home">
                            <img src={Goback} alt="" />
                        </NavLink>
                    </div>
                    <div className='banner-title'>
                        <h1>{t('setting')}</h1>
                    </div>
                </div>
                <div className='setting-inside-cnt-bd'>
                    <div className='Setting-main-body'>
                        <div className='soundaffect-bnts'>
                            <div className='element-music-btns'>
                                <h1>{t('music')}</h1>
                                <img src={!isPlaying ? MusicOf : Musicon} alt="" onClick={handleMusicToggle} />
                            </div>
                            <div className='element-music-btns'>
                                <h1>{t('sfx')}</h1>
                                <img src={!isSoundEnabled ? SfxOff : SfxOn} alt="" onClick={handleSoundToggle} />
                            </div>
                        </div>
                        <div className='language-changed'>
                            <div className='element-Language-btns'>
                                <button className='lg-btns-change' value="en" onClick={(e) => handleLanagauge(e)}>Language</button>
                            </div>
                            <div className='element-Language-btns'>
                                <button className='lg-btns-change' value="zh" onClick={(e) => handleLanagauge(e)}>语言</button>
                            </div>
                            <div className='element-Language-btns'>
                                <button className='lg-btns-change' value="fr" onClick={(e) => handleLanagauge(e)}>langue</button>
                            </div>
                            <div className='element-Language-btns'>
                                <button className='lg-btns-change' value="ru" onClick={(e) => handleLanagauge(e)}>язык</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Setting