import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../Action/WalletAction'
import Token from '../../Helper/Token';
import { useTranslation } from 'react-i18next';
import prevImg from '../../Assets/Screen Icons/Screen Icons/Next box 1.png';
import nextImg from '../../Assets/Screen Icons/Screen Icons/Next box.png';
import loader from '../../Assets/Screen Icons/loading.gif';
import { getInvoiceTypeDescription } from '../../Helper/Validation';


function Funds() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { GetWalletHistory } = bindActionCreators(actions, dispatch)
    const [walletHistory, setWalletHistory] = useState([]);
    const [pageState, setPageState] = useState({
        page: 1,
        limit: 10,
        prevPage: "",
        nextPage: "",
        totalPages: ""
    });
    const [isLoading, setIsLoading] = useState(true)

    const handlePrev = (newPage) => {
        setIsLoading(true);
        if (pageState.page > 1) {
            setPageState((prevState) => ({
                ...prevState,
                page: newPage,
                prevPage: newPage - 1,
            }));
        }
    };

    const handleNext = (newPage) => {
        setIsLoading(true);
        if (pageState.page < pageState.totalPages) {
            setPageState((prevState) => ({
                ...prevState,
                page: newPage,
                prevPage: newPage + 1,
            }));
        }
    };

    useEffect(() => {
        Token()
        GetWalletHistory(pageState.page, pageState.limit, 2)
            .then((res) => {
                setIsLoading(false)
                setPageState({
                    ...pageState,
                    totalPages: res.result.totalPages,
                    prevPage: res.result.prevPage,
                    nextPage: res.result.nextPage,
                });
                setWalletHistory(res.result.docs);
            })
    }, [pageState.page, pageState.limit])


    return (
        <div className='History-body'>
            <div className='iside-history-row'>
                {
                    isLoading ? <img className='isLoading' src={loader} alt="" /> :
                        walletHistory?.map((item) => {
                            return (
                                <div className='all-history-col-wrapper funds'>
                                    <div className='hs-col-mid1'>
                                        <p>{item.createdAt}</p>
                                    </div>
                                    <div className="hs-col-mid-twise win">
                                        <div className='hs-win-player-id'>
                                            <h3>{t(`${getInvoiceTypeDescription(item.invoice_type)}`)}</h3>
                                            {
                                                item.invoice_type === 5 ? (
                                                    <>
                                                        {item.accept_flag === 1 ? (
                                                            <p className='success'>{t("Processed")}</p>
                                                        ) : item.accept_flag === 2 ? (
                                                            <p className='error'>{t("Declined")}</p>
                                                        ) : item.accept_flag === 3 ? (
                                                            <p className='error'>{t("Canceled")}</p>
                                                        ) : <p className='error'>{t("Processing")}</p>}
                                                    </>
                                                ) : null
                                            }
                                        </div>
                                        <div className='hs-amout-x-player-cos-invite'>
                                            <div className='amnt-code-hs-pl'>
                                                <h5>{item.amount}</h5>
                                                <p>XOF</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                }
            </div>
            {
                pageState.totalPages > 1 ?
                    <div className='navigation-btn'>
                        <button className='history-invite-buttons' onClick={() => handlePrev(pageState.prevPage)} disabled={!pageState.prevPage}><img src={prevImg} alt="prev" /></button>
                        <button className='history-invite-buttons' onClick={() => handleNext(pageState.nextPage)} disabled={!pageState.nextPage}><img src={nextImg} alt="next" /></button>
                    </div> : ""
            }
        </div>
    )
}

export default Funds