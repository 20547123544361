import React, { useState } from 'react'
import './FordotPassword.css'
import Logo from '../../Assets/Screen Icons/logo.png'
import { Button } from 'react-bootstrap';
import BackBtn from "../../Assets/Screen Icons/Screen Icons/Back arrow.png"
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../Action/UserActions'
import loader from '../../Assets/Screen Icons/loading.gif';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

const toastStyle = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
};

function FordotPassword() {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [email, setEmail] = useState({ email: "" })
    const [otp, setOtp] = useState("")
    const [step, setStep] = useState(1)

    const dispatch = useDispatch();
    const { userForgotPasswordAction, userOtpVerificationAction, userResetPasswordAction } = bindActionCreators(actions, dispatch);

    // Loading 
    const [isLoading, setIsLoading] = useState(false)

    const handleForgot = async (e) => {
        e.preventDefault();

        if (!email.email) {
            toast.error(`${t('Email')} ${t('field is mandatory.')}`, toastStyle);
            return;
        }

        if (!isValidEmail(email.email)) {
            toast.error(`${t('Please enter a valid Email')}`, toastStyle);
            return;
        }

        setIsLoading(true);

        try {
            const response = await userForgotPasswordAction(email);
            setStep(step + 1);
            setIsLoading(false);
            toast.success(response.message, toastStyle);
        } catch (err) {
            toast.error(err.response?.data.message, toastStyle);
            setIsLoading(false);
        }
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };


    const handleVerifyOtp = async (e) => {
        e.preventDefault()
        if (otp !== "") {
            setIsLoading(true)
            try {
                const response = await userOtpVerificationAction({
                    email: email.email,
                    otp: otp
                });
                setStep(step + 1)
                setIsLoading(false)
                toast.success(response.message, toastStyle);
            } catch (err) {
                toast.error(err.response?.data.message, toastStyle);
                setIsLoading(false)
            }
        } else {
            toast.error(`${t('Otp')} ${t("field is mandatory.")}`, toastStyle)
        }
    }

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(false);

    const handlePassword = (e) => {
        const { name, value } = e.target;
        name === 'password' ? setPassword(value) : setConfirmPassword(value);
        if (name === 'password' && confirmPassword && value !== confirmPassword) {
            setError(true);
        } else if (name === 'confirmPassword' && password && value !== password) {
            setError(true);
        } else {
            setError(false);
        }
    }

    // Validations
    const [errors, setErrors] = useState({});
    const [showErrors, setShowErrors] = useState(false);

    const handleCHangePassword = async (e) => {
        e.preventDefault()
        const obj = {
            email: email.email,
            password: confirmPassword,
            otp: otp
        }
        if (password === confirmPassword || password !== "" || confirmPassword !== "") {
            setIsLoading(true);
            try {
                const response = await userResetPasswordAction(obj);
                toast.success(response.message, toastStyle);
                setTimeout(() => {
                    setIsLoading(false);
                    navigate("/login")
                }, 1000)
            } catch (err) {
                toast.error(t(err.response.data.message), toastStyle);
            }
        } else if (confirmPassword === "") {
            setErrors({
                ...errors,
                password: {
                    message: `${t('password')} ${t("field is mandatory.")}`,
                    rule: "password"
                }
            })
        }
    }

    return (
        <div className='login forgot-password signup'>
            <div className='main'>
                <div className='back-btn'>
                    <NavLink className="play-sound" to="/login">
                        <img src={BackBtn} alt="" />
                    </NavLink>
                </div>
                <div className='logo'>
                    <img src={Logo} alt="" />
                </div>
                {
                    step === 1 ?
                        <div className='forgot-block'>
                            <h2>{t('enter your email ')}</h2>
                            <form className="login-form">
                                <div className='From-groups'>
                                    <input type="email" name="email" onChange={(e) => setEmail({ [e.target.name]: e.target.value })} placeholder={t('email address')} className='From-inputs' />
                                </div>

                                <div className='Form-submit-btn'>
                                    <Button type='submit' className='update-pfl-btn' onClick={(e) => handleForgot(e)} disabled={isLoading}>{t('submit')}</Button>
                                </div>
                            </form>
                        </div> :
                        step === 2 ?
                            <div className='forgot-block'>
                                <h2>{t("Verify Your Email")}</h2>
                                <form className="login-form">
                                    <div className='From-groups'>
                                        <input type="number" name="otp" onChange={(e) => setOtp(parseInt(e.target.value))} placeholder='Email Otp...' className='From-inputs' />
                                    </div>

                                    <div className='Form-submit-btn'>
                                        <Button type='submit' className='update-pfl-btn' onClick={(e) => handleVerifyOtp(e)} disabled={isLoading}>{t('submit')}</Button>
                                    </div>
                                </form>
                            </div> :
                            step === 3 ?
                                <div className='forgot-block'>
                                    <h2>{t('enter new password')}</h2>
                                    <form className="login-form">
                                        <div className='From-groups'>
                                            <input type="password" name="password" value={password} onChange={(e) => handlePassword(e)} placeholder={t('password')} className='From-inputs' id="password" />
                                        </div>

                                        <div className='From-groups'>
                                            <input type="password" name="confirmPassword" value={confirmPassword} onChange={(e) => handlePassword(e)} placeholder={t('enter new password')} className='From-inputs' />
                                            {error ? <p className='error-msg'>Password Does Not Match</p> : ""}
                                            {errors ? <p className='error-msg'>{errors?.password?.message}</p> : ""}
                                        </div>

                                        <div className='Form-submit-btn'>
                                            <Button type='submit' className='update-pfl-btn' onClick={(e) => handleCHangePassword(e)} disabled={isLoading}>{t('submit')}</Button>
                                        </div>
                                    </form>
                                </div> : ""
                }
            </div>
            {
                isLoading ?
                    <div className='api-loader'>
                        <img src={loader} alt="loader" />
                    </div> : ""
            }
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export default FordotPassword