import React from 'react'
import './GameModes.css'
import GameScreen from "../../Assets/Screen Icons/-xhdpi/gamescreen.png"
import { NavLink } from 'react-router-dom';
import Goback from '../../Assets/Screen Icons/Screen Icons/Back arrow.png'
import SS from '../../Assets/ScreenShots/SS.jpg'
import SS5 from '../../Assets/ScreenShots/SS5.png'
import SS6 from '../../Assets/ScreenShots/SS6.png'
import SS7 from '../../Assets/ScreenShots/SS7.png'
import { useTranslation } from 'react-i18next';

function GameModes() {
  const { t } = useTranslation();

  return (
    <div className='Game-Mode-screen'>
      <div className='Game-Mode-element-row'>
        <div className='help-element-banner'>
          <div className='go-back-to-main-screen'>
            <NavLink className="play-sound" to="/help">
              <img src={Goback} alt="" />
            </NavLink>
          </div>
          <div className='banner-title'>
            <h1>{t("Game Modes")}</h1>
          </div>
        </div>

        <div className='game-mode-inside-bd-section'>
          <div className='game-mode-inside-bd-order-of-modes'>
            <ul className="list-inline">
              <a href="#free-match">
                <li className="list-inline-item">{t("FreeMatch_mp")}</li>
              </a>
              <a href="#direct-match">
                <li className="list-inline-item">{t("Direct Match")}</li>
                </a>
              <a href='#random-match'>
                <li className="list-inline-item">{t("random match")}</li>
              </a>
            </ul>
          </div>
        </div>

        <div className='game-mode-cnt-sect_wrapper'>
          <div className='game-discriprion-mode-on' id='free-match'>
            <div className='mode-title-bd'>
              <h2>{t("1.Free Match Mode")}</h2>
              <p>{t("Free match mode will help you to learn the basics of the game. You can play this game unlimited times with the bot and sharpen your skills to play against real players, with real money.")}</p>
            </div>
            <div className='mode-image-bd'>
              <img src={SS} alt="" />
            </div>
            <div className='mode-disc-bd'>
              <div className="ul-li-for-rules">
                <ul>
                  <li>{t("Choose Game Duration from the following options:")}</li>

                  <ul className="inside">
                    <li>{t("05:00, 10:00, 15:00, 20:00, 25:00 and 30:00")}</li>
                  </ul>
                  <li>{t("Choose Guess Number Range from the following ranges:")}</li>
                  <ul className="inside">
                    <li>{t("0-100, 0-1000, 0-10000, 0-100000 and 0-1000000")}</li>
                  </ul>
                  <li>{t("Now, in the Select Number for Other User option, set the number you want other player to guess. To start the game, press the Play")}</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='game-discriprion-mode-on' id='direct-match'>
            <div className='mode-title-bd'>
              <h2>{t("2.Direct Match Mode")}</h2>
              <p>{t("Play & Connect With Other Players Direct match game mode is a great opportunity to play with known players with real money $$. You can search any player using User id or name and invite them to play a game with you.")}</p>
              <p>{t("You can find your user id on profile page Steps to create a game:")}</p>
            </div>
            <div className='mode-image-bd'>
              <img src={SS6} alt="" />
            </div>
            <div className='mode-disc-bd'>
              <div className="ul-li-for-rules">
                <ul>
                  <li>{t("Search for user")}</li>
                  <li>{t("Invite and create a new game.")}</li>
                  <li>{t("Choose Game Duration from the following options:")}</li>
                  <ul className="inside">
                    <li>{t("05:00, 10:00, 15:00, 20:00, 25:00 and 30:00")}</li>
                  </ul>
                  <li>{t("Choose Guess Number Range from the following ranges:")}</li>
                  <ul className="inside">
                    <li>{t("0-100, 0-1000, 0-10000, 0-100000 and 0-1000000")}</li>
                  </ul>
                  <li>{t("Now, in the Select Number for Other User - option, set the number you want other player to guess. To start the game, press the Play button.")}</li>
                  <li>{t("Steps to accept the invite:")}</li>
                </ul>
              </div>
            </div>
            <div className='mode-image-bd'>
              <img src={SS5} alt="" />
            </div>
            <div className='mode-disc-bd'>
              <div className="ul-li-for-rules">
                <ul>
                  <li>{t("Accept")}</li>
                  <ul className="inside">
                    <li>{t("Need to input your secret number")}</li>
                  </ul>
                  <li>{t("Counter")}</li>
                  <ul className="inside">
                    <li>{t("You can Change duration of the game")}</li>
                    <li>{t("Bet amount")}</li>
                    <li>{t("And secret number range")}</li>
                    <li>{t("And send them a counter offer")}</li>
                  </ul>
                  <li>{t("Decline")}</li>
                  <ul className="inside">
                    <li>{t("You can decline the invitation by pressing decline")}</li>
                  </ul>
                  <li>{t("Notes: The invite request will last till 5 mins.")}</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='game-discriprion-mode-on' id='random-match'>
            <div className='mode-title-bd'>
              <h2>{t("3.Random Match Mode")}</h2>
            </div>
            <div className='mode-image-bd'>
              <img src={SS7} alt="" />
            </div>
            <div className='mode-disc-bd'>
              <p>{t("You can play with any member, from the available bet list. Or you can create your own game and wait for any other member to join")}</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default GameModes