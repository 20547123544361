import { combineReducers } from "redux"
import ProductDetailsReducer from "./ProductDetailsReducer"
import userReducer from "./userReducer";
import walletReducer from "./walletReducer";
import gameReducer from "./gameReducer";
import gameSocketReducer from "./gameSocketReducer";
import webSocketReducer from "./webSocketReducer";

export default combineReducers({
    product: ProductDetailsReducer,
    user: userReducer,
    walletHistory: walletReducer,
    curruntGame: gameReducer,
    socketData: gameSocketReducer,
    webSocket: webSocketReducer
});