import React, { useState, useEffect } from "react";
import { useGameSocket } from "./useGameSocket";
import { serverCurrentTime } from "../API";

const CountdownTimer = ({ endTimeISO }) => {
    const { socket } = useGameSocket();
    const [currentTime, setCurrentTime] = useState("");
    
    useEffect(() => {
        const handleServerTime = (data) => {
            setCurrentTime(new Date(data.result));
        };

        if (socket) {
            socket.on(serverCurrentTime, handleServerTime);

            return () => {
                socket.off(serverCurrentTime, handleServerTime);
            };
        }
    }, [socket]);

    const endTime = endTimeISO ? new Date(endTimeISO) : currentTime;
    
    if (!endTime || isNaN(endTime.getTime())) {
        return "00:00";
    }

    const timeDifference = Math.max(0, endTime - currentTime);

    // Check if the calculated time difference is negative
    if (timeDifference <= 0) {
        return "00:00";
    }
    const calculatedMinutes = Math.floor(timeDifference / 60000);
    const calculatedSeconds = Math.floor((timeDifference % 60000) / 1000);

    const formattedTime = `${calculatedMinutes.toString().padStart(2, "0")}:${calculatedSeconds.toString().padStart(2, "0")}`;
    
    return formattedTime;
};

export default CountdownTimer;
