import React from 'react'
import Goback from '../../Assets/Screen Icons/Screen Icons/Back arrow.png'
import GameScreen from "../../Assets/Screen Icons/-xhdpi/gamescreen.png"
import { NavLink } from 'react-router-dom'
import './GameRules.css'
import SS1 from '../../Assets/ScreenShots/SS1.jpg'
import SS2 from '../../Assets/ScreenShots/SS2.jpg'
import SS3 from '../../Assets/ScreenShots/SS3.png'
import { useTranslation } from 'react-i18next'

function GameRules() {
  
  const { t } = useTranslation();

  return (
    <div className='Game-Rules-screen'>
      <div className='Game-Rules-element-row'>

        <div className='help-element-banner'>
          <div className='go-back-to-main-screen'>
            <NavLink className="play-sound" to="/help">
              <img src={Goback} alt="" />
            </NavLink>
          </div>
          <div className='banner-title'>
            <h1>Game Rules</h1>
          </div>
        </div>

        <div className='game-rule-inside-body-section'>
          <div className='first-section-gm-rule'>
            <div className='firt-gm-rule'>
              <h2>{t('How to create a game')}</h2>
              <p>{t('In any game mode you can create a game by following the aforementioned steps')}</p>
              <div className='ul-li-for-rules'>
                <ul>
                  <li>{t("Add the bet amount")}</li>
                  <li>{t("Choose Game Duration from the following options:")}</li>
                  <ul className="inside">
                    <li>{t("05:00, 10:00, 15:00, 20:00, 25:00 and 30:00")}</li>
                  </ul>
                  <li>{t("Choose Guess Number Range from the following ranges:")}</li>
                  <ul className="inside">
                    <li>{t("0-100, 0-1000, 0-10000, 0-100000 and 0-1000000")}</li>
                  </ul>
                  <li>{t("Secret number, input your secret number which your opponent will have to guess")}</li>
                </ul>
              </div>
            </div>
            <div className='section-rules'>
              <h2>{t("How to play the game :")}</h2>
              <img src={SS1} alt="" />
              <div className='pera-b-p-text'>
                <b>{t("Your Guess")}</b>
                <p>{t("Input any number within the range, and try to find the opponents secret number in least attempts")}</p>

                <b>{t("Attempts")}</b>
                <p>{t("Your number of attempts, can be viewed here")}</p>

                <b>{t("Last Guessed Number")}</b>
                <p>{t("It displays the last number the player has guessed")}</p>

                <b>{t("Range")}</b>
                <p>{t("Number range of the game can be viewed over here")}</p>

                <b>{t("time")}</b>
                <p>{t("Remaining time will be displayed here Abort You can Abort the game by pressing this")}</p>
              </div>
              <img src={SS2} alt="" />
              <div className='pera-b-p-text ended'>
                <b>{t("Result")}</b>
                <p>{t("It will display the winner/looser and win/lose amount details")}</p>
              </div>
            </div>
            <div className='end-sec-rules'>
              <h2>{t("Color hints")}</h2>
              <p>{t("Color hints are to help you out to understand how far or close is your current guessed number from the secret number of the opponent")}</p>
              <img src={SS3} alt="" />
              <div className='pera-b-p-text'>
                <p><b>{t("RED")}</b> - {t("When the guessed number is extremely far from your opponents chosen number")}</p>
                <p><b>{t("ORANGE")}</b> - {t("When the guessed number is moderately far from your opponents chosen number")}</p>
                <p><b>{t("GREEN")} </b> - {t("When the guessed number is very close to your opponents chosen number")}</p>
                <div className="inside-last-sec-pera">
                  <h2>{t("Time Over")} :</h2>
                  <p>{t("If the time gets over and both the players have not guessed the correct number, 10% from both of the users will be deducted as commission.")}</p>

                  <h2>{t("The 10% Commission")}</h2>
                  <p>- {t("In direct mode and random mode 10% of commission of the bet amount is levied extra on the player who has lost the game.")} </p>
                  <p>- {t('In case both the players are unable to guess the correct number in the time limit then both the players will be levied 10% of commission of the bet amount.')}</p>

                  <h2>{t('Winning or Losing the Bet')}</h2>
                  <p>- {t('A player will win the game if he/she guesses the secret number of the opponent in least attempts. And will be rewarded the total bet amount.')}</p>

                  <p>- {t('No commission is levied on the winner - Loosing')}</p>

                  <p>- {t('If you lose the game the bet amount plus the 10% commission will be deducted from your account.')}</p>

                  <h2>{t("Bet Amount")}</h2>
                  <p>- {t("Minimum bet amount to play a game is 100XOF.")}</p>
                  <p>- {t("Additional 10% of the bet amount should be available in the account balance.")}</p>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default GameRules