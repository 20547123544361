import React, { useEffect, useState } from 'react'
import Goback from '../../Assets/Screen Icons/Screen Icons/Back arrow.png'
import { NavLink, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import FranchPrivacy from './FranchPrivacy';
import EnglisPrivacy from './EnglisPrivacy';

function PrivacyPolicy() {
    const navigate = useNavigate()
    const { t } = useTranslation();

    const [lang, setLang] = useState('')

    useEffect(() => {
        setLang(localStorage.getItem("selectedLang"))
    }, [])

    return (
        <div>
            <div className='help-element-banner'>
                <div className='go-back-to-main-screen'>
                    <NavLink className="play-sound" onClick={() => navigate(-1)}>
                        <img src={Goback} alt="" />
                    </NavLink>
                </div>
                <div className='banner-title'>
                    <h1>{t("Privacy Policy")}</h1>
                </div>
            </div>
            {
                lang === "en" || lang === "ru" || lang === "zh" || lang === "" ? <EnglisPrivacy /> : <FranchPrivacy />
            }
        </div>
    )
}

export default PrivacyPolicy