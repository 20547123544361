import { WALLET_HISTORY } from "../Type"

const initialState = {
    isAuthenticated: false,
    docs: [],
    totalDocs: 0,
    limit: 0,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: true,
    prevPage: null,
    nextPage: 0
};

const walletReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case WALLET_HISTORY:
            return {
                ...state,
                walletHistory: payload
            };
        default:
            return state;
    }
};

export default walletReducer;