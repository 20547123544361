import axios from "axios";
import { WalletHistory } from "../API";
import { WALLET_HISTORY } from '../Type/index'

export const GetWalletHistory = (page, limit, bunch_type) => async (dispatch) => {
    try {
        const HistoryData = await axios.get(`${WalletHistory}?page=${page}&limit=${limit}&bunch_type=${bunch_type}`);
        if (HistoryData) {
            dispatch({
                type: WALLET_HISTORY,
                payload: HistoryData.data.result
            })
        }
        return HistoryData.data
    } catch (error) {
        throw error;
    }
}