import React, { useEffect, useState } from 'react'
import Notification from '../../Assets/Screen Icons/Screen Icons/Notification.png'
import '../HomeScreen/Home.css'
import { NavLink } from "react-router-dom";
import User from '../../Assets/Screen Icons/User.png'
import mainLogo from '../../Assets/Screen Icons/logo.png';
import { useTranslation } from 'react-i18next';

function BeforePlay({handleMusicPlay}) {
    const { t } = useTranslation();
    const [isLogin, setIsLogin] = useState(false)
    useEffect(() => {
        if (localStorage.getItem("UserToken") !== null || sessionStorage.getItem("UserToken") !== null) {
            setIsLogin(true)
        } else {
            setIsLogin(false)
        }
    }, [])
    
    return (
        <div className='home-element-bg'>
            <div className='HomeScreen-wrapper'>
                <div className='main-rwo-section'>
                    <div className='top-banner-section'>
                        <div className='title-h1'></div>
                    </div>
                    <div className='middle-section'>
                        <img width={200} src={mainLogo} style={{ textAlign: "center", margin: "0 auto 20px" }} />
                        <div className='match-mking-btn before-play'>
                            <NavLink className="play-sound beforeplay" to={isLogin ? "/home" : "/login"} onClick={() => handleMusicPlay()}>
                                <button className='match-btn beforeplay'>{t('play')}</button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BeforePlay