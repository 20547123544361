import axios from "axios";
import { SearchUser } from "../API";


export const searchPlayer = (playerData) => async (dispatch) => {
    try {
        const searchUser = await axios.post(SearchUser, playerData);
        return searchUser.data;
    } catch (error) {
        throw error;
    }
}