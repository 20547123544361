import React, { useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import loader from '../../Assets/Screen Icons/loading.gif';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../Action/GameActions';
import Token from '../../Helper/Token';
import { deletedRandomMatch, gameAccept, gameDecline, gameInvitation } from '../../API';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGameSocket } from '../../Helper/useGameSocket';
import { useTranslation } from 'react-i18next';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const toastStyle = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
};

function WaitingScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { socket } = useGameSocket();
    const gameId = useParams();
    const { t } = useTranslation();

    const { AcceptDeclineGame } = bindActionCreators(actions, dispatch);
    const [betDetails, setBetDetails] = useState([]);

    // Get Game Details
    useEffect(() => {
        if (localStorage.getItem("newGameData")) {
            setBetDetails(JSON.parse(localStorage.getItem('newGameData')));
        } else {
            setTimeout(() => {
                navigate('/home');
            }, 2000);
        }
    }, []);

    // Socket Listen
    useEffect(() => {
        const handleGameAccept = (data) => {
            if (data.game_id === betDetails?._id) {
                if (data?.title !== undefined && data?.title !== "") {
                    toast.success(data.title, toastStyle);
                    localStorage.removeItem('newGameData');
                    setTimeout(() => navigate("/play"), 2000);
                }
            }
        };

        const handleGameInvitation = (data) => {
            if (data.game_id === betDetails?._id) {
                if (data?.title !== undefined && data?.title !== "") {
                    toast.success(data.title, toastStyle);
                    setTimeout(() => navigate(`/invite-offer/${gameId.id}`), 1000);
                }
            }
        };

        const handleGameDecline = (data) => {
            if (data.game_id === betDetails?._id) {
                if (data?.title !== undefined && data?.title !== "") {
                    toast.success(data.title, toastStyle);
                    setTimeout(() => navigate(`/home`), 500);
                }
            }
        };

        const handleDeletedRandomMatch = (data) => {
            if (data.game_id === betDetails?._id) {
                if (data?.result === gameId.id) {
                    toast.success(`${t("Game Time out")}`, toastStyle);
                    localStorage.removeItem('newGameData');
                    setTimeout(() => navigate(`/home`), 2000);
                }
            }
        };

        if (socket) {
            socket.on(deletedRandomMatch, handleDeletedRandomMatch);
            socket.on(gameAccept, handleGameAccept);
            socket.on(gameInvitation, handleGameInvitation);
            socket.on(gameDecline, handleGameDecline);

            return () => {
                socket.off(gameAccept, handleGameAccept);
                socket.off(gameInvitation, handleGameInvitation);
                socket.off(gameDecline, handleGameDecline);
                socket.off(deletedRandomMatch, handleDeletedRandomMatch);
            };
        }
    }, [socket, gameId, navigate, toastStyle, betDetails]);


    // Loading 
    const [isLoading, setIsLoading] = useState(false)

    // Cancel Game
    const handleAbort = (e) => {
        const declineData = {
            flag: 2, // 1 = accept, 2 = decline, 3 = counter offer
            number: "",
            amount: betDetails.amount,
            time: betDetails.time,
            number_range: betDetails.number_range
        };

        Token();
        setIsLoading(true)
        AcceptDeclineGame(declineData, gameId.id)
            .then((res) => {
                toast.success(res.message, toastStyle);
                localStorage.removeItem('newGameData');
                setTimeout(() => {
                    navigate('/home');
                    setIsLoading(false)
                }, 500);
            })
            .catch((err) => {
                toast.error(t(err.response.data.message), toastStyle);
                localStorage.removeItem('newGameData');
                setTimeout(() => {
                    navigate('/home');
                    setIsLoading(false)
                }, 500);
            });
    };

    return (
        <div className='WaitingScreen'>
            <div className="popupbodyel qavailable-bet-popup">
                <div className='abort popup '>
                    <Modal open={true} aria-labelledby="modal-modal-title" className='model-avaolable-bet-ok' aria-describedby="modal-modal-description" >
                        <Box className="abort data-popup accept-avilable-offer" sx={style}>

                            <div className='popup-body-height-define-bet x-waiting-screen'>
                                <div className='popup-title-available-bet x-waiting-screen'>
                                    <h1><span>{t('betamount_pop')}</span> {betDetails?.amount} XOF</h1>
                                    <h1><span>{t('Number Range')}</span> {betDetails?.number_range?.replace(',', ` ${t("To")} `)}</h1>
                                    <h1><span>{t('time _pop')}</span> {betDetails?.time}</h1>
                                </div>

                                <div className='popup-body input-filed'>
                                    <div className='loader x-waiting-screen'>
                                        <img src={loader} alt="" />
                                    </div>
                                </div>
                            </div>

                            <div className='btn-green-submit-available-bet'>
                                <button className='letsplay-freegm-btn-blnssht green' onClick={(e) => handleAbort(e)} disabled={isLoading}>{t("Cancel")}</button>
                            </div>

                        </Box>
                    </Modal>
                </div>
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
}

export default WaitingScreen;