import React, { useEffect, useState } from 'react'
import '../../Appbar/MatchWithBot/MatchWithBot.css'
import { NavLink } from 'react-bootstrap'
import player1 from '../../Assets/Screen Icons/-xhdpi/profile.png'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useNavigate } from 'react-router-dom';
import HomeBtn from '../../Assets/Screen Icons/Screen Icons/Home.png'
import NextBtn from '../../Assets/Screen Icons/Screen Icons/Next box.png'
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../Action/GameActions'
import * as userActions from '../../Action/UserActions'
import Token from '../../Helper/Token';
import { io } from 'socket.io-client';
import { ApiBaseURL, gameAborted, gamePlayTurn, gameStatus, gameTimeOver, returnGameStatus, returnPlayData } from '../../API';
import CountdownTimer from '../../Helper/GameHelper';
import { useGameSocket } from '../../Helper/useGameSocket';
import UpIcon from '../../Assets/Screen Icons/UpArrow.png'
import loader from '../../Assets/Screen Icons/loading.gif'
import defaultProfile from '../../Assets/Screen Icons/-xhdpi/profile.png'
import { useTranslation } from 'react-i18next';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

function GameScreen() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { socket } = useGameSocket();

    // Redux actions
    const { GetCurrentRunningGame, PatchGameDetails, CurrentGameResult, CurrentGameAbort } = bindActionCreators(actions, dispatch);
    const { getUserDetails } = bindActionCreators(userActions, dispatch);
    const [userDetails, setUserDetails] = useState({});

    // State management
    const [isLoading, setIsLoading] = useState(true);
    const [isAborted1, setIsAborted1] = useState(false);
    const [isAborted2, setIsAborted2] = useState(false);

    // Abort Popup
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Abort Popup
    const [openGameOver, setOpenGameOver] = React.useState(false);
    const handleOpenGameOver = () => setOpenGameOver(true);
    const handleCloseGameOver = () => setOpenGameOver(false);

    // Result Popup
    const [openResult, setOpenResult] = React.useState(false);

    // Waiting popup
    const [waitopen, Setwaitopen] = useState(false);

    // No Game Found
    const [openNoGameFound, setOpenNoGameFound] = useState(false);
    const handleCloseNoGameFound = () => setOpenNoGameFound(false);

    // Currunt Game Dertails
    const [currentGame, setCurrentGame] = useState({});
    useEffect(() => {
        Token()
        GetCurrentRunningGame()
            .then((res) => {
                PatchGameDetails(res.result._id)
                    .then((resp) => {
                        setTimeout(() => {
                            setIsLoading(false)
                        }, 1000)
                        setCurrentGame(resp.result);
                        if (userDetails?._id === resp?.result?.first_player_data?._id) {
                            if (resp?.result?.first_player_number_flag === 1) {
                                Setwaitopen(true);
                            }
                        } else if (userDetails?._id === resp?.result?.second_player_data?._id) {
                            if (resp?.result?.second_player_number_flag === 1) {
                                Setwaitopen(true);
                            }
                        }
                    })
            })
            .catch((err) => {
                setIsLoading(false)
                setOpenNoGameFound(true)
                setTimeout(() => {
                    navigate('/home')
                }, 2000)
            })
    }, [userDetails])

    const [backgroundColor, setBackgroundColor] = useState('blue');

    // Get User Details
    useEffect(() => {
        Token()
        const deviceId = {
            device_id: localStorage.getItem('deviceId')
        }
        getUserDetails(deviceId)
            .then((res) => {
                setUserDetails(res.data.result);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    localStorage.clear();
                    sessionStorage.clear();
                    navigate("/login");
                }
            });

    }, [])

    // Guess Number
    const [snumber, setSnumber] = useState({
        game_id: '',
        player_id: '',
        selected_number: '',
    })

    useEffect(() => {
        setSnumber({
            ...snumber,
            game_id: currentGame._id,
            player_id: userDetails._id
        })
    }, [currentGame, userDetails])

    const handleSecreatNumber = (e) => {
        const { name, value } = e.target;
        setSnumber({
            ...snumber,
            [name]: value
        })
    }

    // Error
    const [errMessage, setErrMessage] = useState('')

    // Play turn
    const handleCheckNumber = (e) => {
        e.preventDefault();
        const [min, max] = currentGame.number_range.split(',');
        if (isNaN(snumber.selected_number) || snumber.selected_number === "") {
            setErrMessage(`${t("Please enter a valid number")}`);
            setMessage("");
            setBackgroundColor('red');
        } else if (snumber.selected_number < Number(min) || snumber.selected_number > Number(max)) {
            setErrMessage(`${t("Please enter a number between")} ${min} ${t("and")} ${max}`);
            setMessage('');
            setBackgroundColor('red');
        } else if (snumber.selected_number < 0) {
            setErrMessage(`${t("You Can Not Enter Negative Number")}`);
        } else {
            const selectedData = {
                result: snumber,
            };
            socket.emit(gamePlayTurn, selectedData);
            setSnumber({
                ...snumber,
                selected_number: ""
            })
            setErrMessage('');
        }
    };

    // Listen on socket
    const [playTurnData, setPlayTurnData] = useState({});
    const [message, setMessage] = useState('');
    const [gameResult, setGameResult] = useState({})

    useEffect(() => {
        const handlePlayData = (data) => {
            if (data.player_id === userDetails._id) {
                setPlayTurnData(data);

                if (data.arrow_flag === 0) {
                    Setwaitopen(true);
                    const gameData = {
                        result: {
                            game_id: currentGame._id,
                            player_id: userDetails._id,
                        },
                    };
                    socket.emit(gameStatus, gameData);
                }

                if (data.arrow_flag === 1) {
                    setMessage(`${t("Guess Higher")}`);
                } else if (data.arrow_flag === 2) {
                    setMessage(`${t("Guess Lower")}`);
                }

                if (data.pecentage < 5) {
                    setBackgroundColor('green');
                } else if (data.pecentage > 5 && data.pecentage < 25) {
                    setBackgroundColor('orange');
                } else if (data.pecentage > 25) {
                    setBackgroundColor('red');
                }
            }
        };

        const handleGameStatus = (data) => {
            if (data.game_id === currentGame._id) {
                if (data.game_over === 1) {
                    CurrentGameResult(data.game_id)
                        .then((res) => {
                            Setwaitopen(false);
                            setOpenGameOver(true);
                            setGameResult(res);
                            setTimeout(() => {
                                setOpenGameOver(false);
                                setOpenResult(true);
                            }, 1000);
                        });
                }
            }
        };

        const handleGameTimeOver = (data) => {
            if (data.result === currentGame._id) {
                CurrentGameResult(data.result)
                    .then((res) => {
                        Setwaitopen(false);
                        setOpenGameOver(true);
                        setGameResult(res);
                        setTimeout(() => {
                            setOpenGameOver(false);
                            setOpenResult(true);
                        }, 1000);
                    });
            }
        };

        const handleGameAborted = (data) => {
            if (data?.result?._id === currentGame._id) {
                CurrentGameResult(data?.result?._id)
                    .then((res) => {
                        if (res.result.first_player_data._id === data.win_id) {
                            setIsAborted2(true);
                        }
                        if (res.result.second_player_data._id === data.win_id) {
                            setIsAborted1(true);
                        }
                        Setwaitopen(false);
                        setOpenGameOver(true);
                        setGameResult(res);
                        setTimeout(() => {
                            setOpenGameOver(false);
                            setOpenResult(true);
                        }, 1000);
                    });
            }
        };

        if (socket) {
            socket.on(returnPlayData, handlePlayData);
            socket.on(returnGameStatus, handleGameStatus);
            socket.on(gameTimeOver, handleGameTimeOver);
            socket.on(gameAborted, handleGameAborted);

            return () => {
                socket.off(returnPlayData, handlePlayData);
                socket.off(returnGameStatus, handleGameStatus);
                socket.off(gameTimeOver, handleGameTimeOver);
                socket.off(gameAborted, handleGameAborted);
            };
        }
    }, [socket, userDetails, currentGame]);
    
    // Abort Game
    const [abortLoading, setAbortLoading] = useState(false)
    const handleAbort = (e) => {
        if (currentGame._id !== undefined || currentGame._id !== null || currentGame._id !== "") {
            const gameId = {
                game_id: currentGame._id,
            };

            Token();
            setAbortLoading(true)
            CurrentGameAbort(gameId)
                .then((res) => {
                    CurrentGameResult(currentGame._id)
                        .then((resp) => {
                            if (resp.result.first_player_data._id === userDetails._id) {
                                setIsAborted1(true);
                            }
                            if (resp.result.second_player_data._id === userDetails._id) {
                                setIsAborted2(true);
                            }
                            setOpen(false);
                            Setwaitopen(false);
                            setOpenGameOver(true);
                            setGameResult(resp);
                            setTimeout(() => {
                                setOpenGameOver(false);
                                setOpenResult(true);
                                setAbortLoading(false)
                            }, 1000);
                        })
                        .catch((err) => {
                            setAbortLoading(false)
                        })
                });
        }
    };

    return (
        <>
            <div className={`game-element-bg ${backgroundColor}`}>
                <div className='free-match-with-bot'>
                    <div className='top-bar'>
                        <h2>{t("Range")}: {currentGame?.number_range?.replace(',', ` ${t("To")} `)}</h2>
                        <NavLink className="play-sound" to="#" onClick={(e) => handleOpen(e)} >{t('abort')}</NavLink>
                    </div>
                    <div className='game-block'>
                        <div className='player player-top'>
                            <img
                                src={
                                    currentGame
                                        ? currentGame?.second_player_data?._id !== userDetails?._id
                                            ? currentGame?.second_player_data?.profilePic || defaultProfile
                                            : currentGame?.first_player_data?.profilePic || defaultProfile
                                        : defaultProfile
                                }
                                alt=""
                            />
                            <h2>
                                {currentGame ? (
                                    currentGame?.second_player_data?._id !== userDetails?._id
                                        ? currentGame?.second_player_data?.user_key
                                        : currentGame?.first_player_data?.user_key
                                ) : (
                                    'Player 2'
                                )}
                            </h2>
                        </div>
                        <div className='timer'>
                            <div className='countdown'>
                                <CountdownTimer endTimeISO={currentGame.game_ending_time} />
                            </div>
                        </div>
                        {playTurnData.previous_selected_number && <p className='PreviousGuess-Text'>{playTurnData.previous_selected_number}</p>}
                        <div className='player player-bottom'>
                            {
                                message !== "" ?
                                    <p className='ResultP'><span className='error-x-game'>{message}</span>
                                        {(playTurnData.arrow_flag !== 1 && playTurnData.arrow_flag !== 2) ? "" : <img className={playTurnData.arrow_flag === 1 ? "ResultArror UpIcon" : playTurnData.arrow_flag === 2 ? "ResultArror DownIcon" : ""} src={UpIcon} alt="" />}
                                    </p> : ""
                            }
                            {
                                errMessage !== "" ? <p className='ResultP'>{errMessage}</p> : ''
                            }
                            <form onSubmit={(e) => handleCheckNumber(e)}>
                                <div className='yout-guess'>
                                    <input type="number" name="selected_number" value={snumber?.selected_number} id="" placeholder={t('Enter Number')} onChange={(e) => handleSecreatNumber(e)} />
                                    <div className='lets-play-free'>
                                        <button type='submit' className='letsplay-freegm-btn-blnssht' >{t('Ok')}</button>
                                    </div>
                                </div>
                            </form>
                            <h2>
                                {currentGame ? (
                                    currentGame?.first_player_data?._id === userDetails?._id
                                        ? currentGame?.first_player_data?.user_key
                                        : currentGame?.second_player_data?.user_key
                                ) : (
                                    'Player 1'
                                )}
                            </h2>
                            <img
                                src={
                                    currentGame
                                        ? currentGame?.first_player_data?._id === userDetails?._id
                                            ? currentGame?.first_player_data?.profilePic || defaultProfile
                                            : currentGame?.second_player_data?.profilePic || defaultProfile
                                        : defaultProfile
                                }
                                alt=""
                            />
                        </div>
                    </div>
                    <div className='bottom-bar'>
                        <h2>{t('Attempts')}: {playTurnData?.attempt !== undefined || playTurnData?.attempt !== "" ? playTurnData?.attempt : 0}</h2>
                    </div>
                </div>
            </div>

            {/* Result Popup */}
            <div className="result popup">
                <Modal open={openResult} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
                    <Box className="result data-popup" sx={style}>
                        <div className='playes'>
                            <div className={
                                isAborted1 ? 'player aborted' :
                                    gameResult?.result?.winner === 1 ? 'player winner' :
                                        gameResult?.result?.winner === 2 ? 'player loser' :
                                            'player draw'
                            }
                                dataResult={
                                    isAborted1 ? `${t('aborted')}` :
                                        gameResult?.result?.winner === 1 ? `${t('winner')}` :
                                            gameResult?.result?.winner === 2 ? `${t('loser')}` :
                                                `${t('draw')}`
                                }>
                                <img src={gameResult?.result?.first_player_data?.profilePic} alt="" />
                                <h2>{gameResult?.result?.first_player_data?.name}</h2>
                                <h4>{gameResult?.result?.first_player_data?.user_key}</h4>
                            </div>
                            <div className='vs-text'>VS</div>
                            <div className={
                                isAborted2 ? 'player aborted' :
                                    gameResult?.result?.winner === 2 ? 'player winner' :
                                        gameResult?.result?.winner === 1 ? 'player loser' :
                                            'player draw'
                            }
                                dataResult={
                                    isAborted2 ? `${t('aborted')}` :
                                        gameResult?.result?.winner === 2 ? `${t('winner')}` :
                                            gameResult?.result?.winner === 1 ? `${t('loser')}` :
                                                `${t('draw')}`
                                }>
                                <img src={gameResult?.result?.second_player_data?.profilePic} alt="" />
                                <h2>{gameResult?.result?.second_player_data?.name}</h2>
                                <h4>{gameResult?.result?.second_player_data?.user_key}</h4>
                            </div>
                        </div>
                        <div className='game-result'>
                            <div>
                                <p>{gameResult?.result?.first_number}</p>
                                <p>{gameResult?.result?.first_player_attempt}</p>
                                <p>{gameResult?.result?.first_player_time}</p>
                            </div>
                            <div>
                                <p>{t('Secret Number')}</p>
                                <p>{t('Attempts')}</p>
                                <p>{t('Duration')}</p>
                            </div>
                            <div>
                                <p>{gameResult?.result?.second_number}</p>
                                <p>{gameResult?.result?.second_player_attempt}</p>
                                <p>{gameResult?.result?.second_player_time}</p>
                            </div>
                        </div>
                        <div className='game-result check-balance'>
                            <div className='balance before'>
                                <h2>XOF {Number(gameResult?.last_amount).toFixed(2)}</h2>
                                <p>{t('Balance Before')}</p>
                            </div>
                            <div className='sign'>
                                <p>{gameResult?.last_amount > gameResult.updated_amount ? "-" : "+"}</p>
                            </div>
                            <div className='balance win-loose'>
                                <h2>XOF {Number(gameResult?.win_lose_amount)}</h2>
                                <p>{t('win/Loose')}</p>
                            </div>
                            <div className='sign'>
                                <p>=</p>
                            </div>
                            <div className='balance after'>
                                <h2>XOF {Number(gameResult?.updated_amount).toFixed(2)}</h2>
                                <p>{t('New Balance')}</p>
                            </div>
                        </div>
                        <div className='actin-btns'>
                            <div className='btn-block'>
                                <NavLink onClick={() => navigate("/home")} className='play-sound btn'><img src={HomeBtn} alt="" /></NavLink>
                                <p>{t('home')}</p>
                            </div>
                            <div className='btn-block'>
                                <button className='btn' onClick={() => navigate(gameResult?.result?.game_type === 2 ? `/invite/${gameResult?.result?.first_player_data?._id === userDetails?._id ? gameResult?.result?.second_player_data?._id : gameResult?.result?.first_player_data?._id}` : "/random-match")}><img src={NextBtn} alt="" /></button>
                                <p>{t('Replay')}</p>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>

            {/* Abort Popup */}
            <div className="abort popup">
                <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
                    <Box className="abort data-popup" sx={style}>
                        <div className='free-match-banner-section free-match-bg-avb-desh'>
                            <h2>{t('exit text')}</h2>
                        </div>
                        <Typography className="model-text" id="modal-modal-description" sx={{ mt: 2 }} >
                            <h2>{t('Are you sure want to Abort?')}</h2>
                            <h2>{t('If you abort this game you will lose 10% of bat amout.')}</h2>
                        </Typography>
                        <Typography className="model-button">
                            <button className='letsplay-freegm-btn-blnssht green' onClick={handleClose} >{t('cancel')}</button>
                            <button className='letsplay-freegm-btn-blnssht red' onClick={(e) => handleAbort(e)} disabled={abortLoading}> {t('abort')}</button>
                        </Typography>
                    </Box>
                </Modal>
            </div>

            {/* Game Over */}
            <div className="abort popup">
                <Modal open={openGameOver} onClose={handleCloseGameOver} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
                    <Box className="abort data-popup" sx={style}>
                        <Typography className="model-text" id="modal-modal-description">
                            <h1>{t('Game Over')}</h1>
                        </Typography>
                    </Box>
                </Modal>
            </div>

            {/* No Game Found */}
            <div className="abort popup">
                <Modal open={openNoGameFound} onClose={handleCloseNoGameFound} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
                    <Box className="abort data-popup" sx={style}>
                        <Typography className="model-text" id="modal-modal-description">
                            <h1>{t('No Game Found')}</h1>
                        </Typography>
                    </Box>
                </Modal>
            </div>

            {/* Waiting Screen */}
            <div className='WaitingScreen'>
                <div className="popupbodyel qavailable-bet-popup">
                    <div className='abort popup '>
                        <Modal open={waitopen} aria-labelledby="modal-modal-title" className='model-avaolable-bet-ok' aria-describedby="modal-modal-description" >
                            <Box className="abort data-popup accept-avilable-offer x-game-screen-wait" sx={style}>

                                <div className='popup-body-height-define-bet x-waiting-screen'>
                                    <div className='popup-title-available-bet x-waiting-screen'>
                                        <h3>{t("Sit back and relax!")}<br /> {t("You have guessed player2's secret number.")}</h3>
                                        <h3>{(t("We will notify you when player2 completes his/her match."))}</h3>
                                        <h1><span>{t("Do not close the game.")}</span></h1>
                                    </div>

                                    <div className='popup-body input-filed'>
                                        <div className='loader x-waiting-screen'>
                                            <img src={loader} alt="loader" />
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Modal>
                    </div>
                </div>
            </div>
            {
                isLoading ?
                    <div className='api-loader'>
                        <img src={loader} alt="loader" />
                    </div> : ""
            }
        </>
    )
}

export default GameScreen