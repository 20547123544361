import React, { useState, useEffect } from 'react'
import './Invite.css'
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Goback from '../../Assets/Screen Icons/Screen Icons/Back arrow.png'
import TimeSelect from '../../Components/TimeSelect';
import NumberRange from '../../Components/NumberRange';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import Token from '../../Helper/Token';
import * as GameAction from '../../Action/GameActions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as UserAction from '../../Action/UserActions'
import { gameDataValidation, handleStopFractionalNumber, validateFractionalNumber } from '../../Helper/Validation';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';

const toastStyle = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
};

function Invite() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const player = useParams()

  // Create Bet Api
  const dispatch = useDispatch()
  const { CreateNewGame } = bindActionCreators(GameAction, dispatch)
  const { getUserDetails } = bindActionCreators(UserAction, dispatch)
  const { userdetails } = useSelector((state) => state.user)
  const [errorShow, setErrorShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("")
  // Getting UserDetails
  useEffect(() => {
    Token();
    const deviceId = {
      device_id: localStorage.getItem('deviceId')
    }
    getUserDetails(deviceId)
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/login");
        }
      });
  }, []);

  // Setting data in this hook for game
  const [gamedata, setGameData] = useState({
    game_type: 2,
    amount: "",
    time: "",
    number_range: "",
    first_player_id: "",
    second_player_id: "",
    first_number: "",
    second_number: "",
  })

  useEffect(() => {
    setGameData({
      ...gamedata,
      first_player_id: userdetails?.result._id,
      second_player_id: player.id
    })
  }, [userdetails])


  // Api Calling For Create Game
  const handleChange = (e, data) => {
    // e.preventDefault();
    if (data === "number") {
      setGameData((prevGameData) => ({
        ...prevGameData,
        [e.target.name]: parseFloat(validateFractionalNumber(e?.target.value)),
      }));
    }
    if (data === "range") {
      setGameData((prevGameData) => ({
        ...prevGameData,
        number_range: e?.value,
      }));
    }
    if (data === "Time") {
      setGameData((prevGameData) => ({
        ...prevGameData,
        time: e?.label,
      }));
    }
  };

  useEffect(() => {
    setErrorMsg(gameDataValidation(gamedata, userdetails?.rest_amount, t))
  }, [gamedata, userdetails])

  // Loading
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorShow(true)
    if (errorMsg === "") {
      setIsLoading(true)
      CreateNewGame(gamedata)
        .then((res) => {
          toast.success(res.message, toastStyle);
          setTimeout(() => {
            navigate(`/find-match/${res.result._id}`);
            const newGame = {
              ...gamedata,
              _id: res.result._id
            }
            setIsLoading(false)
            localStorage.setItem("newGameData", JSON.stringify(newGame))
          }, 1000)
        })
        .catch((err) => {
          toast.error(t(err.response.data.message), toastStyle);
          toast.error(err.response.data?.amount.message, toastStyle);
          setIsLoading(false)
        });
    }
  };

  return (
    <div className='Freematch-screen create-bat'>
      <div className='Freematch-element-row'>

        <div className='help-element-banner'>
          <div className='go-back-to-main-screen'>
            <NavLink className="play-sound" onClick={() => navigate(-1)}>
              <img src={Goback} alt="" />
            </NavLink>
          </div>
          <div className='banner-title'>
            <h1>{t('mo_makeoffer')}</h1>
          </div>
        </div>

        <div className='Freematch-inside-cnt-bd'>
          <div className='profile-update-col Freematch-main-body'>
            <div className='wrapper-first-col'>

              <div className='free-match-banner-section free-match-bg-avb-desh'>
                <h2>{t('betamount_pop')}</h2>
              </div>
              <div className='Select-Time'>
                <div className='slect-secret-number'>
                  <input type="number" name="amount" value={gamedata.amount} onChange={(e) => handleChange(e, "number")} id="" placeholder={t('betamount_pop')} />
                </div>
                <p>{t('available balance')} : XOF {userdetails?.rest_amount}</p>
                <p>{t('You will get double this amount if you win the game, or lose it plus a 10% charge.')}</p>
              </div>
            </div>
            <div className='wrapper-second-col'>
              <div className='free-match-banner-section free-match-bg-avb-desh'>
                <h2>{t('Secret Number Range')}</h2>
              </div>
              <div className='Select-Time'>
                <NumberRange handleChange={handleChange} />
              </div>
            </div>
            <div className='wrapper-second-col'>
              <div className='free-match-banner-section free-match-bg-avb-desh'>
                <h2>{t('Choose The Duration')}</h2>
              </div>
              <div className='Select-Time'>
                <TimeSelect handleChange={handleChange} />
              </div>
            </div>
            <div className='wrapper-second-col'>
              <div className='free-match-banner-section free-match-bg-avb-desh'>
                <h2>{t('Choose your secret number')}</h2>
              </div>
              <div className='Select-Time'>
                <div className='slect-secret-number'>
                  <input type="number" name="second_number" onChange={(e) => handleChange(e, "number")} id="" placeholder={t('Enter Number')} onKeyPress={handleStopFractionalNumber} />
                </div>
              </div>
            </div>
            <div className='wrapper-thierd-col'>
              <div className='lets-play-free'>
                <Button className='play-sound letsplay-freegm-btn-blnssht' onClick={(e) => handleSubmit(e)} disabled={isLoading}>{t('create bet')}</Button>
              </div>
            </div>
          </div>
          {errorShow && errorMsg !== "" ? <p className='Trow-error-stil-missing-fd'>{errorMsg}</p> : ""}
        </div>

      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  )
}

export default Invite