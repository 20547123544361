import axios from "axios";
import { CreateGame, AcceptDecline, GetGameDetails, GetCurrentGame, GameAbort, GameResult } from "../API";
import { BET_DETAILS, CURRENT_GAME } from '../Type/index'

// Create Game Action
export const CreateNewGame = (gameData) => async (dispatch) => {
    try {
        const createGame = await axios.post(CreateGame, gameData);
        if (createGame) {
            dispatch({
                type: BET_DETAILS,
                payload: createGame.data.result
            })
        }
        return createGame.data
    } catch (error) {
        throw error;
    }
}

export const AcceptDeclineGame = (gameData, gameId) => async (dispatch) => {
    try {
        const acceptdecline = await axios.put(`${AcceptDecline}${gameId}`, gameData);
        return acceptdecline.data
    } catch (error) {
        throw error;
    }
}

export const PatchGameDetails = (gameId) => async (dispatch) => {
    try {
        const gameDetails = await axios.patch(`${GetGameDetails}/${gameId}`);
        dispatch({
            type: BET_DETAILS,
            payload: gameDetails.data.result
        })
        return gameDetails.data
    } catch (error) {
        throw error;
    }
}

export const GetCurrentRunningGame = () => async (dispatch) => {
    try {
        const CurrentGame = await axios.get(`${GetCurrentGame}`);
        dispatch({
            type: CURRENT_GAME,
            payload: CurrentGame.data.result
        })
        return CurrentGame.data
    } catch (error) {
        throw error;
    }
}

export const CurrentGameAbort = (gameData) => async (dispatch) => {
    try {
        const CurrentGame = await axios.post(`${GameAbort}`, gameData);
        return CurrentGame.data
    } catch (error) {
        throw error;
    }
}

export const CurrentGameResult = (gameId) => async (dispatch) => {
    try {
        const CurrentGame = await axios.patch(`${GameResult}/${gameId}`);
        return CurrentGame.data
    } catch (error) {
        throw error;
    }
}