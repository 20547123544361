import React, { useEffect, useState } from 'react'
import './Notifications.css'
import { NavLink, useNavigate } from 'react-router-dom'
import Goback from '../../Assets/Screen Icons/Screen Icons/Back arrow.png'
import { ApiBaseURL, UserNotification, liveGames } from '../../API';
import { bindActionCreators } from 'redux';
import * as UserAction from '../../Action/UserActions'
import Token from '../../Helper/Token';
import { useDispatch } from 'react-redux';
import { useGameSocket } from '../../Helper/useGameSocket';
import { useTranslation } from 'react-i18next';
import loader from '../../Assets/Screen Icons/loading.gif';
import PendingGame from '../../Components/PendingGame';
import axios from 'axios';

function Notifications() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { socket } = useGameSocket();
    const { t } = useTranslation();

    const { getUserDetails } = bindActionCreators(UserAction, dispatch);
    const [userDetails, setUserDetails] = useState({});
    const [curruntGames, setCurruntGames] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        Token()
        const deviceId = {
            device_id: localStorage.getItem('deviceId')
        }
        getUserDetails(deviceId)
            .then((res) => {
                setUserDetails(res.data.result);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    localStorage.clear();
                    sessionStorage.clear();
                    navigate("/login");
                }
            });
    }, []);

    // Notification Count
    const fetchNotification = () => {
        Token();
        axios.get(UserNotification)
            .then((res) => {
                const temp = [];
                res.data.result.docs.map((ele) => {
                    if (ele?.gameData?.play_status === 0) {
                        temp.push(ele)
                    }
                })
                setCurruntGames(temp);
                setIsLoading(false);
            })
    }
    useEffect(() => {
        fetchNotification()
    }, [])


    useEffect(() => {
        if (socket) {
            socket.on(liveGames, (data) => {
                fetchNotification()
            });

            return () => {
                socket.off(liveGames);
            }
        }
    }, [socket]);


    return (
        <div className='Freematch-screen secret-number invite-offer'>
            <div className='Freematch-element-row'>
                <div className='help-element-banner'>
                    <div className='go-back-to-main-screen'>
                        <NavLink className="play-sound" to='/home'>
                            <img src={Goback} alt="" />
                        </NavLink>
                    </div>
                    <div className='banner-title'>
                        <h1>{t('Notifications')}</h1>
                    </div>
                </div>

                <PendingGame />

                <div className='notification-block'>
                    {
                        curruntGames.map((game) => {
                            return (
                                <div className='notification-item' key={game?._id}>
                                    <h2><span>{game?.fromUserData?.name}</span> {t('wants to play with you')}</h2>
                                    <NavLink className="play-sound" to={`/invite-offer/${game?.gameData?._id}`}>{t('view offer')}</NavLink>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            {
                isLoading ?
                    <div className='api-loader'>
                        <img src={loader} alt="loader" />
                    </div> : ""
            }
        </div>
    )
}

export default Notifications