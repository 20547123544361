import React, { useEffect, useState } from 'react'
import './Signup.css'
import Logo from '../../Assets/Screen Icons/logo.png'
import { Button, FormGroup } from 'react-bootstrap';
import BackBtn from "../../Assets/Screen Icons/Screen Icons/Back arrow.png"
import { NavLink, useNavigate } from 'react-router-dom';
import * as UserActions from '../../Action/UserActions'
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { getGoogleLogin } from '../../API';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { validateRequiredFields } from '../../Helper/Validation';

const toastStyle = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
};

function Signup() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [adduser, setAdduser] = useState({
        email: "",
        name: "",
        password: "",
        gender: ""
    })
    const [showerr, setShowerr] = useState({})
    const [deviceId, setDeviceId] = useState('')
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(false);

    const handlePassword = (e) => {
        const { name, value } = e.target;
        name === 'password' ? setPassword(value) : setConfirmPassword(value);
        if (name === 'password' && confirmPassword && value !== confirmPassword) {
            setError(true);
        } else if (name === 'confirmPassword' && password && value !== password) {
            setError(true);
        } else {
            setError(false);
            setAdduser({
                ...adduser,
                password: value,
            })
        }
    }

    useEffect(() => {
        if (deviceId === "") {
            setDeviceId(uuidv4())
        }
    }, []);

    const handleChange = (e, i) => {
        if (e.target.name === "gender") {
            setAdduser({
                ...adduser,
                gender: i,
            });
        } else {
            setAdduser({
                ...adduser,
                [e.target.name]: e.target.value,
            });
        }
    };

    const dispatch = useDispatch()
    const { addNewUser, userCheckVariftEmailAction } = bindActionCreators(UserActions, dispatch)

    const [emailError, setEmailError] = useState({
        error: "",
        type: ""
    })
    const [showEmailError, setShowEmailError] = useState(false)
    const handleCheckEmail = (e) => {
        e.preventDefault()
        setShowEmailError(true)
        const obj = {
            email: e.target.value
        }
        if (e.target.value !== "") {
            userCheckVariftEmailAction(obj)
                .then((res) => {
                    setEmailError({
                        error: res.message,
                        type: "sucess"
                    })
                })
                .catch((err) => {
                    setEmailError({
                        error: err.response.data.message,
                        type: "error"
                    })
                })
        }
    }

    // Validations
    const [errors, setErrors] = useState({});
    const [showErrors, setShowErrors] = useState(false);

    useEffect(() => {
        const allErrors = validateRequiredFields(adduser, ["name", "email", "password"], t)
        setErrors(allErrors)
    }, [adduser])

    // Loading
    const [isLoading, setIsLoading] = useState(false)

    const handleSingup = async (e) => {
        e.preventDefault()
        setShowErrors(true)
        if (Object.keys(errors).length === 0) {
            setIsLoading(true)
            addNewUser(adduser)
                .then((res) => {
                    toast.success(res.message, toastStyle);
                    navigate("/help")
                    localStorage.setItem('UserToken', res.token);
                    setIsLoading(false)
                })
                .catch((err) => {
                    toast.error(err?.response?.data.message, toastStyle);
                    setShowerr(err?.response?.data)
                    setIsLoading(false)
                })
        }
    }



    return (
        <div className='home-element-bg login signup'>
            <div className='main'>
                <div className='back-btn'>
                    <NavLink className="play-sound" to="/login">
                        <img src={BackBtn} alt="" />
                    </NavLink>
                </div>
                <div className='logo'>
                    <img src={Logo} alt="" />
                </div>
                <div className='login-block'>
                    <h2>{t('sign up')}</h2>
                    <form className="login-form">
                        <div className='From-groups'>
                            <label htmlFor="name">{t('name')}</label>
                            <div>
                                <input type="text" name="name" onChange={(e) => handleChange(e)} placeholder='Name' className='From-inputs' id='userid' />
                                {showerr ? <p className='error-msg'>{showerr.name?.message}</p> : ""}
                                {showErrors ? <p className='error-msg'>{errors.name?.message}</p> : ""}
                            </div>
                        </div>

                        <div className='From-groups'>
                            <label htmlFor="email">{t('email')}</label>
                            <div>
                                <input type="email" name="email" onChange={(e) => handleChange(e)} onBlur={(e) => handleCheckEmail(e)} placeholder={t('email address')} className='From-inputs' id='Email' />
                                {showEmailError ? <p className='error-msg'>{emailError.type === "error" ? emailError.error : ""}</p> : ""}
                                {showerr ? <p className='error-msg'>{showerr.email?.message}</p> : ""}
                                {showErrors ? <p className='error-msg'>{errors.email?.message}</p> : ""}
                            </div>
                        </div>

                        <div className='From-groups'>
                            <label htmlFor="password">{t('password')}</label>
                            <div>
                                <input type="password" name="password" onChange={(e) => handlePassword(e)} placeholder={t('password')} className='From-inputs' id="password" />
                                {showerr ? <p className='error-msg'>{showerr.password?.message}</p> : ""}
                                {showErrors ? <p className='error-msg'>{errors.password?.message}</p> : ""}
                            </div>
                        </div>

                        <div className='From-groups'>
                            <label htmlFor="Email"></label>
                            <div>
                                <input type="password" name="confirmPassword" onChange={(e) => handlePassword(e)} placeholder={t('re enter password')} className='From-inputs' />
                                {error ? <p className='error-msg'>{t('Password Does Not Match')}</p> : ""}
                            </div>
                        </div>

                        <div className='From-groups'>
                            <label htmlFor="password">{t('gender')}</label>
                            <div className='radio'>
                                <input type="radio" name="gender" onChange={(e) => handleChange(e, 1)} className='From-inputs' id="male" checked={adduser.gender == 1 ? true : false} />
                                <label htmlFor="male">{t('male')}</label>
                                <input type="radio" name="gender" onChange={(e) => handleChange(e, 2)} className='From-inputs' id="female" checked={adduser.gender == 2 ? true : false} />
                                <label htmlFor="female">{t('female')}</label>
                            </div>
                        </div>

                        <div className='Form-submit-btn'>
                            <Button type='submit' className='update-pfl-btn' onClick={(e) => handleSingup(e)} disabled={isLoading}>{t('sign up')}</Button>
                        </div>

                        <div className='login-with'>
                            <GoogleOAuthProvider clientId="54201937071-sn67ng0fvra41t6upn41pnkp06bd6k5q.apps.googleusercontent.com">
                                <GoogleLogin
                                    onSuccess={(credentialResponse) => {
                                        axios.post(getGoogleLogin, { sSocialToken: credentialResponse.credential, type: "R", device: deviceId })
                                            .then((response) => {
                                                navigate("/home");
                                                localStorage.setItem('deviceId', deviceId);
                                                localStorage.setItem("UserToken", response.data.token);
                                                toast.success(response.data.message, toastStyle);
                                                setIsLoading(false);
                                            })
                                            .catch((err) => {
                                                toast.warn(err.response.data?.error, toastStyle);
                                            });
                                    }}
                                    onError={() => {
                                        toast.warn(err.data?.error, toastStyle);
                                    }}
                                />
                            </GoogleOAuthProvider>
                        </div>

                    </form>
                </div>
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>

    )
}

export default Signup