import React, { useState, useEffect } from 'react'
import './GlobleCss/Globle.css'
import { GrDown } from 'react-icons/gr'
import { FaCheck } from 'react-icons/fa'
import { useTranslation } from 'react-i18next';


function NumberRange({ handleChange }) {
    
  const { t } = useTranslation();
    const data = [
        {
            id: 0, label: `0 ${t("To")} 100`, value: "0,100"
        },
        {
            id: 1, label: `0 ${t("To")} 1000`, value: "0,1000"
        },
        {
            id: 3, label: `0 ${t("To")} 10000`, value: "0,10000"
        },
        {
            id: 4, label: `0 ${t("To")} 100000`, value: "0,100000"
        },
        {
            id: 5, label: `0 ${t("To")} 1000000`, value: "0,1000000"
        },
    ];
    const [isOpenNum, setOpenNum] = useState(false);
    const [selectedItemNum, setSelectedItemNum] = useState(0);

    const toggleDropdownNum = () => setOpenNum(!isOpenNum);

    const handleItemClickNum = (id) => {
        selectedItemNum == id ? setSelectedItemNum(id) : setSelectedItemNum(id);
        setOpenNum(!isOpenNum)
    }

    useEffect(() => {
        const range = data.find((ele) => {
            return ele.id == selectedItemNum
        })
        setTimeout(() => {
            handleChange(range, "range") 
        },500)
    }, [selectedItemNum])

    return (
        <div className='dropdown'>
            <div className='dropdown-header' onClick={toggleDropdownNum}>
                {selectedItemNum !== null ? data.find(item => item.id == selectedItemNum).label : "Select Time"}
                <i className={`fa fa-chevron-right icon ${isOpenNum && "open"}`}></i><GrDown />
            </div>
            <div className={`dropdown-body ${isOpenNum && 'open'}`}>
                {data.map(item => (
                    <div className="dropdown-item" onClick={e => handleItemClickNum(e.target.id)} id={item.id} key={item.id}>
                        <span className={`dropdown-item-dot ${item.id == selectedItemNum && 'selected'}`}><FaCheck /></span>
                        <p>{item.label}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default NumberRange