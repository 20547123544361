import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
const root = ReactDOM.createRoot(document.getElementById('root'));
import { Provider } from 'react-redux';
import store from './store';
import './i18n';
// import { SocketProvider } from './Helper/useGameSocket';


root.render(
  <Provider store={store}>
    {/* <SocketProvider> */}
      <App />
    {/* </SocketProvider> */}
  </Provider>

);
