import React, { useEffect, useState } from 'react'
import Notification from '../../Assets/Screen Icons/Screen Icons/Notification.png'
import './Home.css'
import { NavLink, useNavigate } from "react-router-dom";
import User from '../../Assets/Screen Icons/User.png'
import HelpIcon from '../../Assets/Screen Icons/Screen Icons/Question-mark (1).png'
import SettingIcon from '../../Assets/Screen Icons/Screen Icons/Setting.png'
import MoreIcon from '../../Assets/Screen Icons/More.png'
import * as UserAction from '../../Action/UserActions'
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import Token from '../../Helper/Token';
import { ToastContainer, toast } from 'react-toastify';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from 'react-bootstrap/Button';
import { useGameSocket } from '../../Helper/useGameSocket';
import { UserNotification, liveGames } from '../../API';
import { useTranslation } from 'react-i18next';
import loader from '../../Assets/Screen Icons/loading.gif';
import axios from 'axios';
import PendingGame from '../../Components/PendingGame';


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const toastStyle = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
};

function Home() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { socket } = useGameSocket();

    const { getUserDetails, userResendVariftEmailAction } = bindActionCreators(UserAction, dispatch)
    const [emailVerified, setEmailVerified] = useState("");
    const [open, setOpen] = React.useState(false);
    const [userDetails, setUserDetails] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingNoti, setIsLoadingNoti] = useState(true)


    // Notification Count
    const [notifications, setNotifications] = useState([])
    const fetchNotification = () => {
        Token();
        axios.get(UserNotification)
            .then((res) => {
                const temp = [];
                res.data.result.docs.map((ele) => {
                    if (ele?.gameData?.play_status === 0) {
                        temp.push(ele)
                    }
                })
                setNotifications(temp);
                setIsLoadingNoti(false);
            })
    }
    useEffect(() => {
        fetchNotification()
    }, [])

    // Notofication Socket
    useEffect(() => {
        if (socket) {
            socket.on(liveGames, (data) => {
                fetchNotification();
            });

            socket.on(emailVerified, (data) => {
                if (data.result._id === userDetails._id) {
                    setEmailVerified(data.result.email_verified)
                }
            });

            return () => {
                socket.off(liveGames);
                socket.off(emailVerified);
            }
        }
    }, [socket, userDetails]);

    // Get User Details
    const fetchUserDetails = () => {
        Token();
        const deviceId = {
            device_id: localStorage.getItem('deviceId')
        };

        getUserDetails(deviceId)
            .then((res) => {
                setIsLoading(false);

                if (
                    res?.data?.result?.email_verified !== undefined ||
                    res?.data?.result?.email_verified !== "" ||
                    res?.data?.result?.email_verified !== null ||
                    typeof res?.data?.result?.email_verified === 'number'
                ) {
                    setEmailVerified(res?.data?.result?.email_verified);
                    setUserDetails(res?.data?.result);
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    localStorage.clear();
                    sessionStorage.clear();
                    navigate("/login");
                }
            });
    };

    useEffect(() => {
        fetchUserDetails();
    }, []);


    // Handle Popup open Close
    const handleClose = () => {
        setOpen(false);
    }
    const handleOpenPopup = () => {
        if (emailVerified !== 1 && typeof emailVerified === "number") {
            setOpen(true);
        }
    }

    // Resend varification Email
    const handleResendEmail = (e) => {
        e.preventDefault()
        Token();
        userResendVariftEmailAction()
            .then((res) => {
                toast.success(res.message, toastStyle);
                getUserDetails(deviceId)
            })
            .catch((error) => {
                toast.error(error.response?.data.message, toastStyle);
                if (error.response && error.response.status === 401) {
                    localStorage.clear();
                    sessionStorage.clear();
                    navigate("/login");
                }
            });
        setOpen(false);
    }

    const handleButtonClick = (data) => {
        if (emailVerified === 0 && typeof emailVerified === "number") {
            setOpen(true);
        } else (
            navigate(data)
        )
    };

    return (
        <div className='HomeScreen-wrapper'>
            <div className='main-rwo-section'>
                <div className='top-banner-section'>
                    <div className='title-h1'>
                        <h1 className='p-0'>{t('game title')}</h1>
                    </div>
                    <div className='notification-banner'>
                        <NavLink className="play-sound" to='/notifications'>
                            {notifications.length !== 0 ? <span className='totalNotification'>{notifications.length}</span> : ""}
                            <img src={Notification} alt="" />
                        </NavLink>
                    </div>
                </div>
                <div className='middle-section'>
                    <div className='match-mking-btn'>
                        <NavLink className="play-sound" to="/free-match">
                            <button className='match-btn'>{t('FreeMatch_mp')}</button>
                        </NavLink>
                    </div>
                    <div className='match-mking-btn'>
                        <button className='match-btn' onClick={() => handleButtonClick("/random-match")}>{t('random match')}</button>
                    </div>
                    <div className='match-mking-btn'>
                        <button className='match-btn' onClick={() => handleButtonClick('/direct-match')}>{t('Direct Match')}</button>
                    </div>
                </div>
                <div className='bottom-section'>
                    <div className='bottom-btn-elements'>

                        <NavLink className="play-sound" to="/help" >
                            <img src={HelpIcon} alt="" />
                            <h4>{t('help')}</h4>
                        </NavLink>

                    </div>
                    <div className='bottom-btn-elements'>
                        <NavLink className="play-sound" to="/my-profile">
                            <img src={User} alt="" />
                            <h4>{t('me')}</h4>
                        </NavLink>
                    </div>
                    <div className='bottom-btn-elements'>
                        <NavLink className="play-sound" to="/setting" >
                            <img src={SettingIcon} alt="" />
                            <h4>{t('setting')}</h4>
                        </NavLink>
                    </div>
                    <div className='bottom-btn-elements'>
                        <NavLink className="play-sound" to="/more" >
                            <img src={MoreIcon} alt="" />
                            <h4 className='small'>{t('Useful Links')}</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className="popupbodyel qavailable-bet-popup">
                <div className='abort popup '>
                    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" className='model-avaolable-bet-ok' aria-describedby="modal-modal-description" >
                        <Box className="abort data-popup accept-avilable-offer verify-button" sx={style}>
                            <div className='popup-body-height-define-bet'>
                                <div className='popup-title-available-bet'>
                                    <h1>{t("A verification email has been sent to you, kindly verify it to access all the features of the game.")}</h1>
                                </div>
                            </div>
                            <div className='resend-email'>
                                <button className='letsplay-freegm-btn-blnssht green verify-email' onClick={(e) => handleResendEmail(e)}>{t('send email')}</button>
                            </div>
                        </Box>
                    </Modal>
                </div>
            </div>
            <PendingGame />
            {
                isLoading || isLoadingNoti ?
                    <div className='api-loader'>
                        <img src={loader} alt="loader" />
                    </div> : ""
            }
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export default Home