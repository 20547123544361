import React,{ useState, useEffect } from 'react'
import './GlobleCss/Globle.css'
import {GrDown} from 'react-icons/gr'
import {FaCheck} from 'react-icons/fa'

const data = [
    {
        id: 0, label: "CI"
    }, 
    {
        id: 1, label: "BF"
    }, 
    {
        id: 3, label: "US"
    }, 
    {
        id: 4, label: "CA"
    }, 
    {
        id: 5, label: "FR"
    },
];

function CountrySelect({ handleChange }) {
    

    const [isOpen, setOpen] = useState(false);
    const [items, setItem] = useState(data);
    const [selectedItem, setSelectedItem] = useState(null);

    const toggleDropdown = () => setOpen(!isOpen);

    const handleItemClick = (id) => {
        selectedItem === id ? setSelectedItem(id) : setSelectedItem(id);
        setOpen(false)
    }

    useEffect(() => {
        const country = data.find((ele) => {
            return ele.id == selectedItem
        })
        setTimeout(() => {
            handleChange(country, "country") 
        },500)
    },[selectedItem])

  return (
    <div className='dropdown time-select-x-prefix' style={{width:"100%"}}>
            <div className='dropdown-header' onClick={toggleDropdown}>
                {selectedItem ? items.find(item => item.id == selectedItem).label : "Choose Country"}
                <i className={`fa fa-chevron-right icon ${isOpen && "open"}`}></i><GrDown/>
            </div>
            <div className={`dropdown-body ${isOpen && 'open'}`}>
                {items.map(item => (
                    <div className="dropdown-item" onClick={e => handleItemClick(e.target.id)} id={item.id}>
                        <span className={`dropdown-item-dot ${item.id == selectedItem && 'selected'}`}><FaCheck/></span>
                        <p>{item.label}</p>
                    </div>
                ))}
            </div>
        </div>
  )
}

export default CountrySelect