import React from 'react'

function GameLayout({ page }) {
  return (
    <div className='game-element-bg'>
        {page}
    </div>
  )
}

export default GameLayout