import React from 'react'

function LoginLayout({ page }) {

    return (
        <>
            {page}
        </>
    )
}

export default LoginLayout