import React from 'react'
import './UserProfile.css'
import GameScreen from "../../Assets/Screen Icons/-xhdpi/gamescreen.png"
import { NavLink } from 'react-router-dom';
import Goback from '../../Assets/Screen Icons/Screen Icons/Back arrow.png'
import SS8 from '../../Assets/ScreenShots/SS8.png'
import SS9 from '../../Assets/ScreenShots/SS9.jpg'
import SS10 from '../../Assets/ScreenShots/SS10.jpg'
import SS11 from '../../Assets/ScreenShots/SS11.jpg'
import { useTranslation } from 'react-i18next';

function UserProfile() {
    const { t } = useTranslation();
    return (
        <div className='Game-User-Profile-screen'>
            <div className='Game-User-Profile-row'>
                <div className='help-element-banner'>
                    <div className='go-back-to-main-screen'>
                        <NavLink className="play-sound" to="/help">
                            <img src={Goback} alt="" />
                        </NavLink>
                    </div>
                    <div className='banner-title'>
                        <h1>{t("User Profile")}</h1>
                    </div>
                </div>

                <div className='game-mode-cnt-sect_wrapper user-profile-p-0'>

                    <div className='game-discriprion-mode-on' >
                        <div className='mode-title-bd'>
                            <h2>{t("User Details")}</h2>
                        </div>
                        <div className='mode-image-bd'>
                            <img src={SS8} alt="" />
                        </div>
                        <div className='mode-disc-bd'>
                            <div className="ul-li-for-rules">
                                <ul>
                                    <li>{t("You can view your user id on your profile page")}</li>
                                    <li>{t("You can view and edit your personal info from this page")}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className='game-discriprion-mode-on' >
                        <div className='mode-title-bd'>
                            <h2>{t("Withdraw Money")}</h2>
                        </div>
                        <div className='mode-image-bd'>
                            <img src={SS9} alt="" />
                        </div>
                        <div className='mode-disc-bd'>
                            <div className='mode-title-bd'>
                                <div className="ul-li-for-rules">
                                    <ul>
                                        <li>{t("Goto your profile and click on withdraw, and then input the details and complete the withdrawal process.")}</li>
                                        <li>{t("Minimum Withdraw amount:")} <b>500XOF</b></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='game-discriprion-mode-on' >
                        <div className='mode-title-bd'>
                            <h2>{t("Deposit")}</h2>
                        </div>
                        <div className='mode-image-bd'>
                            <img src={SS10} alt="" />
                        </div>
                        <div className='mode-disc-bd'>
                            <div className="ul-li-for-rules">
                                <ul>
                                    <li>{t("To deposit money in your account you can goto your profile and add money to your wallet.")}</li>
                                    <li>{t("Minimum diposit amount:")} <b>500XOF</b></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className='game-discriprion-mode-on' >
                        <div className='mode-title-bd'>
                            <h2>{t("History")}</h2>
                        </div>
                        <div className='mode-image-bd'>
                            <img src={SS11} alt="" />
                        </div>
                        <div className='mode-disc-bd'>
                            <div className="ul-li-for-rules">
                                <ul>
                                    <li>{t("You can view the history of your transactions ( games / Withdraw / deposit ) on this screen")}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default UserProfile