import React, { useEffect, useState } from 'react';
import './SecretNumber.css';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Goback from '../../Assets/Screen Icons/Screen Icons/Back arrow.png';
import * as actions from '../../Action/GameActions';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import Token from '../../Helper/Token';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { handleStopFractionalNumber } from '../../Helper/Validation';

const toastStyle = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
};

function SecretNumber() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id: gameId } = useParams();
    const { t } = useTranslation();
    const { PatchGameDetails, AcceptDeclineGame } = bindActionCreators(actions, dispatch);
    const { betDetails } = useSelector((state) => state.user);

    const [BatAcceptData, setBatAcceptData] = useState({
        flag: 1, // 1 = accept, 2 = decline, 3 = counter offer
        number: "",
        amount: "",
        time: "",
        number_range: ""
    });

    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        Token();
        PatchGameDetails(gameId);
    }, [gameId]);

    useEffect(() => {
        if (betDetails) {
            setBatAcceptData((prevData) => ({
                ...prevData,
                amount: betDetails.amount,
                time: betDetails.time,
                number_range: betDetails.number_range,
            }));
        }
    }, [betDetails]);

    useEffect(() => {
        if (!BatAcceptData.number || !BatAcceptData.number_range) return;

        const { number, number_range } = BatAcceptData;
        const [minRange, maxRange] = number_range.split(',').map(Number);
        const numberValue = Number(number);

        if (numberValue < minRange || numberValue > maxRange) {
            setErrors({
                message: 'Number Must Be In the Selected Range',
            });
        } else {
            setErrors({});
        }
    }, [BatAcceptData]);

    const handleChange = (e) => {
        const { value } = e.target;
        setBatAcceptData((prevData) => ({
            ...prevData,
            number: value,
        }));
    };

    const handleAccept = async () => {
        setIsLoading(true);
        setShowError(true);
        Token();

        if (Object.keys(errors).length === 0) {
            try {
                const res = await AcceptDeclineGame(BatAcceptData, gameId);
                toast.success(res?.message, toastStyle);
                setShowError(false);
                setTimeout(() => {
                    setIsLoading(false);
                    navigate("/play");
                }, 2000);
            } catch (err) {
                toast.error(t(err?.response?.data.message), toastStyle);
                setShowError(false);
                setIsLoading(false);
            }
        } else {
            setIsLoading(false);
        }
    };

    return (
        <div className='Freematch-screen secret-number'>
            <div className='Freematch-element-row'>
                <div className='help-element-banner'>
                    <div className='go-back-to-main-screen'>
                        <NavLink className="play-sound" onClick={() => navigate(-1)}>
                            <img src={Goback} alt="" />
                        </NavLink>
                    </div>
                    <div className='banner-title'>
                        <h1>{t('Secret Number')}</h1>
                    </div>
                </div>
                <div className='secret-number-block'>
                    <div className='find-match-box'>
                        <div className='match-details popup-title-available-bet x-waiting-screen'>
                            <h1>{t('Choose a Number for Other Player in Range')} : <span>{betDetails?.number_range?.replace(',', ` ${t("To")} `)}</span></h1>
                            <div className='slect-secret-number'>
                                <input type="number" name="number" id="" placeholder='Enter Number' onChange={(e) => handleChange(e)} onKeyPress={handleStopFractionalNumber} />
                            </div>
                            {showError ? <p className='error-msg'>{`${t(errors.message)}`}</p> : ""}
                        </div>
                        <div className='action'>
                            <button className='letsplay-freegm-btn-blnssht' onClick={(e) => handleAccept(e)} disabled={isLoading}>{t('Okay')}</button>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
}

export default SecretNumber;