import React, { useEffect, useState } from 'react'
import './InviteOffer.css'
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Goback from '../../Assets/Screen Icons/Screen Icons/Back arrow.png'
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../Action/GameActions'
import * as userActions from '../../Action/UserActions'
import { bindActionCreators } from 'redux';
import Token from '../../Helper/Token';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { TroubleshootTwoTone } from '@mui/icons-material';
import { Button } from 'react-bootstrap';

const toastStyle = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
};

function InviteOffer() {
    const navigate = useNavigate()
    const { t } = useTranslation();

    const dispatch = useDispatch()
    const gameId = useParams()
    const { PatchGameDetails, AcceptDeclineGame } = bindActionCreators(actions, dispatch)
    const { getUserDetails } = bindActionCreators(userActions, dispatch)
    const { betDetails } = useSelector((state) => state.user)
    const [userDetails, setUserDetails] = useState({});

    useEffect(() => {
        Token()
        PatchGameDetails(gameId.id)
    }, [])

    useEffect(() => {
        Token()
        const deviceId = {
            device_id: localStorage.getItem('deviceId')
        }
        getUserDetails(deviceId)
            .then((res) => {
                setUserDetails(res.data.result);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    localStorage.clear();
                    sessionStorage.clear();
                    navigate("/login");
                }
            });

    }, [])


    // Decline Offer 
    const BatDeclineData = {
        flag: 2, //1 = accpet 2 = decline 3 = counter offer
        number: "",
        amount: "",
        time: "",
        number_range: ""
    }
    useEffect(() => {
        BatDeclineData.amount = betDetails.amount;
        BatDeclineData.time = betDetails.time;
        BatDeclineData.number_range = betDetails.number_range;
    }, [betDetails])

    // Loading 
    const [isLoading, setIsLoading] = useState(false);

    const handleDecline = (e) => {
        Token()
        setIsLoading(true)
        AcceptDeclineGame(BatDeclineData, gameId.id)
            .then((res) => {
                toast.success(res.message, toastStyle);
                setTimeout(() => {
                    navigate('/notifications')
                    setIsLoading(false)
                }, 500)
            })
            .catch((err) => {
                toast.error(t(err.response.data.message), toastStyle);
                setTimeout(() => {
                    navigate('/notifications')
                    setIsLoading(false)
                }, 500)
            })
    }


    return (
        <div className='Freematch-screen secret-number invite-offer'>
            <div className='Freematch-element-row'>
                <div className='help-element-banner'>
                    <div className='go-back-to-main-screen'>
                        <NavLink className="play-sound" onClick={() => navigate(-1)}>
                            <img src={Goback} alt="" />
                        </NavLink>
                    </div>
                    <div className='banner-title'>
                        <h1>{t('invite offer')}</h1>
                    </div>
                </div>

                <div className='secret-number-block'>
                    <div className='find-match-box'>
                        <div className='match-details popup-title-available-bet x-waiting-screen '>
                            <h1><span>{t('betamount_pop')}</span> {betDetails?.amount} XOF</h1>
                            <h1><span>{t('Number Range')}</span> {betDetails?.number_range?.replace(',', ` ${t("To")} `)}</h1>
                            <h1><span>{t('time _pop')}</span> {betDetails?.time}</h1>
                        </div>
                        <div className='action border'>
                            <NavLink to={`/secret-number/${gameId.id}`} className='play-sound letsplay-freegm-btn-blnssht' >{t('accept')}</NavLink>
                            <NavLink to={`/counter-offer/${gameId.id}`} className='play-sound letsplay-freegm-btn-blnssht' >{t('co_counter offer')}</NavLink>
                        </div>
                        <div className='action'>
                            <Button to="#" className='play-sound letsplay-freegm-btn-blnssht' onClick={(e) => handleDecline(e)} disabled={isLoading}>{t('decline')}</Button>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export default InviteOffer