import React,{ useState, useEffect } from 'react'
import './GlobleCss/Globle.css'
import {GrDown} from 'react-icons/gr'
import {FaCheck} from 'react-icons/fa'

const data = [
    {
        id: 0, label: "(237) Cameroun", value:"237"
    }, 
    {
        id: 1, label: "(243) Congo", value: "243"
    }, 
    {
        id: 3, label: "(225) Cote d'loire", value: "225"
    }, 
    {
        id: 4, label: "(228) Togo", value: "228"
    }, 
    {
        id: 5, label: "(221) Senegal", value: "221"
    }, 
    {
        id: 6, label: "(223) MALI", value: "223"
    },
    {
        id: 7, label: "(226) Burkina Faso", value: "226"
    }
];

function PrefixSlect({ handleChange }) {

    const [isOpen, setOpen] = useState(false);
    const [items, setItem] = useState(data);
    const [selectedItem, setSelectedItem] = useState(null);

    const toggleDropdown = () => setOpen(!isOpen);

    const handleItemClick = (id) => {
        selectedItem === id ? setSelectedItem(id) : setSelectedItem(id);
        setOpen(false)
    }

    useEffect(() => {
        const prefix = data.find((ele) => {
            return ele.id == selectedItem
        })
        setTimeout(() => {
            handleChange(prefix, "prefix") 
        },1000)
    },[selectedItem])

  return (
    <div className='dropdown time-select-x-prefix' style={{width:"100%"}}>
            <div className='dropdown-header' onClick={toggleDropdown}>
                {selectedItem ? items.find(item => item.id == selectedItem).label : "Choose Country Prefix"}
                <i className={`fa fa-chevron-right icon ${isOpen && "open"}`}></i><GrDown/>
            </div>
            <div className={`dropdown-body ${isOpen && 'open'}`}>
                {items.map(item => (
                    <div className="dropdown-item" onClick={e => handleItemClick(e.target.id)} id={item.id}>
                        <span className={`dropdown-item-dot ${item.id == selectedItem && 'selected'}`}><FaCheck/></span>
                        <p>{item.label}</p>
                    </div>
                ))}
            </div>
        </div>
  )
}

export default PrefixSlect