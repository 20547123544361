import { CURRENT_GAME } from "../Type";

const initialState = {
    message: "success",
    result: {}
};

const gameReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case CURRENT_GAME:
            return {
                ...state,
                walletHistory: payload
            };
        default:
            return state;
    }
};

export default gameReducer