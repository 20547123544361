import React from 'react'
import Goback from '../../Assets/Screen Icons/Screen Icons/Back arrow.png'
import { NavLink, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

function TermsAndConditions() {
    const navigate = useNavigate()
    const { t } = useTranslation();
    return (
        <div>
            <div className='help-element-banner'>
                <div className='go-back-to-main-screen'>
                    <NavLink className="play-sound" onClick={() => navigate(-1)}>
                        <img src={Goback} alt="" />
                    </NavLink>
                </div>
                <div className='banner-title'>
                    <h2>{t("Terms and Conditions")}</h2>
                </div>
            </div>
            <div className='game-rule-inside-body-section'>
                <div className='first-section-gm-rule'>
                    <div className='firt-gm-rule'>
                        <p>The following terms and conditions ("Terms") are made between PeekNum (Service Provider) and the user ("Customer"). This Web  application consists of a world class number-guessing game played between two players, and can provide a healthy mental exercise.  These terms and conditions are the binding agreement that explains our obligations to you, and your obligations to us in relation to the services offered to you through the application [hereinafter " PeekNum "].</p>
                        <p>When you will enter in the application you will be asked to agree and accept the terms of use which you are required to accept by clicking on I Accept once you click on I accept it will be considered as the binding upon you once you accept and enter. User agrees to be bound by and accepts these Terms & Conditions outlined below by engaging PeekNum unless otherwise agreed in a separate written agreement.</p>
                        <h2>MODIFICATION OF THESE TERMS OF USE</h2>
                        <p>PeekNum holds full and exclusive right to change or discontinue any feature of the application from time to time. The Company holds all the right to make any changes, amendments or modification in the terms of the application. It is the responsibility of each user to continuously check the terms of the user on a time to time basis.</p>
                        <h2>PEEKNUM OFFERS:</h2>
                        <p>PeekNum is involved in the business of online single or multiplayer gaming. Therefore, You can play PeekNum against your phone, or against real players over the internet in the game’s Random Match and Direct Match modes. To play PeekNum, you simply sign up for an account whereby you will be providing the personal document details for the same as has been stated in the privacy policy. </p>
                        <p>Other services provide by PeekNum</p>
                        <div className='ul-li-for-rules'>
                            <ul>
                                <li>Play & Connect With Other Players</li>
                            </ul>
                        </div>
                        <p>PeekNum isn’t just a game! PeekNum players meet and interact in the game, and out of this a growing community of number geeks is budding into what we hope will be a great platform to build positive connections. The community is facilitated by our forum hosted on https://peeknum.com/forum where our members discuss game strategies, share number puzzles and drink numbers coffee.</p>
                        <div className='ul-li-for-rules'>
                            <ul>
                                <li>Bet & Win $$ In Every Match!</li>
                            </ul>
                        </div>
                        <p>Both Direct Match and Random Match games include a bet where you can win cash playing against friends and even random players on the platform. You bet against each other on whether you will win the game or lose.</p>
                        <h2>GENERAL CONDITIONS</h2>
                        <p>Acceptance of the Terms And Conditions: PeekNum can only be accessed upon the condition that you agree and accept the terms of the website whereby you are required to get yourself updated on a daily basis about any amendments and modifications.</p>
                        <h2>PAYMENT</h2>
                        <div className='ul-li-for-rules'>
                            <ul>
                                <li>While initiating a Transaction, You, as User, are entering into a legally binding and enforceable contract PeekNum to avail gaming services offered by PeekNum and You shall pay the Transaction Price through Your issuing bank to the PeekNum using Payment Facility.</li>
                                <li>●In case of participating in the bet you will be charged 10% commission for each game if you win then the said commission which will be refunded with reward. You have to keep minimum 100 XOF to bet and play plus 10% commission as well.</li>
                                <li>In case if the bet is been draw i.e. no player win the bet when; Both players fail to guess the other’s number before the timer runs out or although this is rare, if both players have made an equal number of attempts and also took the same amount of time then in that case both the parties will lose their commission amount whereas the bet amount will be refunded back</li>
                                <li>Any loss or damage arising directly or indirectly while using the Services of PeekNum due to Lack of authorization for any transaction(s), or Exceeding the preset limit mutually agreed by You and between "Bank(s)", or Any payment issues arising out of the transaction, or Decline of transaction for any other reason(s) will not be the responsibility of PeekNum and no such liability will fall upon PeekNum.</li>
                                <li>PeekNum team will never call you or ask for any of the sensitive data like Card number, OTP, Cvv and thereby PeekNum will hold no liability for any kind of online fraud.</li>
                            </ul>
                        </div>
                        <h2>WITHDRAWAL</h2>
                        <div className='ul-li-for-rules'>
                            <ul>
                                <li>In case you need to withdraw the amount from the PeekNum wallet then the minimum amount for withdrawal is 500 XOF which will be processed within 24 hours once the withdrawal request is submitted.</li>
                            </ul>
                        </div>
                        <h2>LIMITATION OF LIABILITY:</h2>
                        <p>PeekNum assumes no liability whatsoever with respect to (a) the acts or omissions of any auxiliary personnel or (b) damage caused to or from any Services provided by PeekNum to the Customer for the purpose of the performance of its Services. Except as expressly provided and except to the extent that liability may not be lawfully excluded under any statute, PeekNum shall not be liable in contract or otherwise for any direct, indirect, incidental, special, exemplary or consequential loss or damage sustained by Customer in relation to performance of its obligations, whether or not Customer has been advised of the possibility of such damage. Each party’s maximum liability to the other party in respect of claims shall be limited to all charges paid by a party to the other party to the event of liability.</p>
                        <h2>DISCRETION:</h2>
                        <p>Any and all discretions to be made in respect of these Terms and Condition must be made by such parties acting reasonably in view of the prevailing circumstances at the time.</p>
                        <h2>TERMINATION:</h2>
                        <p>I. PeekNum may terminate the Agreement immediately if any of the following events occur:</p>
                        <p>a. If the Customer commits a material breach of any of these terms or conditions and fails to cure such breach within thirty (30) days of written notice specifying the breach;</p>
                        <p>b. the direct or indirect ownership or control of the Customer changes, which change shall be communicated by the Customer to the PeekNum immediately; This Agreement constitutes the entire agreement of the Parties on the subject thereof and supersedes all prior understandings on such subject. This Agreement may not be modified other than by a written instrument executed by the Parties.</p>
                        <h2>INTELLECTUAL PROPERTY</h2>
                        <h2>REGARDING THE SITE, THE PLATFORM AND PeekNum TRADEMARKS</h2>
                        <p>As a User, a Partner, You acknowledge and agree that the Site, the Platform and the Services may contain elements, materials and content (the "Content") copyrighted and/or protected by patent and/or intellectual property laws.</p>
                        <p>You are not authorized, without PeekNum’s prior consent, to modify, copy, rent, share, sell, distribute or create any derivative work from the Content of the Site and/or of the Platform, in whole or in part, nor to proceed to nor use the data extraction systems and/or data collection systems.</p>
                        <p>The name and logos of PeekNum are registered trademarks owned by PeekNum . The other names and logos of PeekNum products and services used in the scope of the Services are trademarks owned by their respective owners that may or may not be affiliated to PeekNum.</p>
                        <p>Nothing in the term of use nor any content of the Services should be construed as granting, explicitly or not, any license or right to use PeekNum Trademarks, without PeekNum written authorization in such cases.</p>
                        <h2>DISCLAIMERS</h2>
                        <p>Whilst PeekNum endeavors to take all reasonable steps to ensure that the PeekNum Platform operates as expected, the PeekNum Platform and its entire contents are provided on an “as is” and “as available” basis without any warranties of any kind, either expressed or implied, to the fullest extent permissible pursuant to applicable law, including, without limitation, any implied warranties of merchantability, satisfactory quality, non-infringement or fitness for a particular purpose. PeekNum does not make any guarantees and does not provide any undertaking that the PeekNum Platform will be available at all times or that it will be error free, reliable or secure or free from viruses or other harmful components or that any defects will be corrected. You agree that you use the PeekNum Platform at your own risk and that PeekNum disclaims any and all responsibility for any damage or loss that you may suffer or incur as a result of using the PeekNum Platform, whether they are direct, indirect, punitive or consequential (including but not limited to loss of profits, loss of business revenue or loss of goodwill). This includes any loss that results from any interference, malfunction, delays, failures or damage that occurs to your device or that results from accessing, installing, updating or using the PeekNum Platform. You assume total responsibility for your use of the PeekNum Platform, including compliance with all applicable road rules and regulations. Subject to the Consumer Guarantees, your sole remedy against PeekNum for dissatisfaction with the PeekNum Platform or any content is to stop using the PeekNum Platform or such Content. This limitation of relief is a part of the bargain between the parties.</p>
                        <h2>PRIVACY POLICY</h2>
                        <p>Our collection use and retention of your personal information is subject to the privacy policy which is available at this link.</p>
                        <h2>INDEMNIFICATION</h2>
                        <p>The User (in its capacity as “Indemnitor”) agrees to indemnify, defend and hold harmless affiliates and employees (in its capacity as "Indemnitee”) from and against any and all third-party claims, liabilities, losses and expenses (including damage awards, settlement amounts and reasonable attorneys’ fees) arising out of or relating to User’s  respective violation of these T&C’s, except to the extent such losses and expenses arise from the negligence or willful misconduct of Indemnitee.</p>
                        <h2>JURISDICTION AND DISPUTE RESOLUTION:</h2>
                        <p>Any question or difference which may arise concerning the construction meaning or effect of this Agreement or concerning the rights and liabilities of the parties hereunder or any other matter arising out of or in connection with this Agreement shall be referred to a single arbitrator who shall conduct proceeding in the court of Tribunal de 1ère Instance ABIDJAN-plateau which shall be appointed by the us within 30 days from the written request from the Member. The decision of such arbitrator shall be final and binding upon the parties. The cost of proceedings shall be borne equally by the parties. Any reference under this clause shall be deemed to be a reference to arbitration within the meaning of the Arbitration Act. The courts at Abidjan shall have exclusive jurisdiction in all the matters arising out of Agreement.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsAndConditions